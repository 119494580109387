/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { StreamType } from './streamType';
import { DisplayListDto } from './displayListDto';
import { ExhibitorContactDto } from './exhibitorContactDto';
import { ExhibitorContactMode } from './exhibitorContactMode';
import { ExpoDesignDto } from './expoDesignDto';
import { InfoSlideDto } from './infoSlideDto';
import { BoothConfigurationDto } from './boothConfigurationDto';
import { LinkListDto } from './linkListDto';
import { ExhibitorInfoVisibility } from './exhibitorInfoVisibility';


export interface BoothListItemDto { 
    sceneId?: number;
    id?: string;
    shortKey?: string;
    name: string;
    company?: string;
    phoneNumber?: string;
    address?: string;
    email?: string;
    description?: string;
    descriptionLong?: string;
    categoryId?: number;
    label?: string;
    website?: string;
    logo?: string;
    logoSquare?: string;
    logoWide?: string;
    backgroundImage?: string;
    posterA?: string;
    posterB?: string;
    hideAvatar?: boolean;
    groupChatEnabled?: boolean;
    singleChatEnabled?: boolean;
    chatWelcomeMessage?: string;
    singleChatWelcomeMessage?: string;
    boothIcon?: string;
    avatar?: string;
    panels?: string;
    displays?: Array<DisplayListDto>;
    links?: Array<LinkListDto>;
    infoSlides?: Array<InfoSlideDto>;
    showContact?: boolean;
    contacts?: Array<ExhibitorContactDto>;
    sequence?: number;
    schedulingService?: number;
    schedulingKey?: string;
    schedulingActive?: boolean;
    googleAnalyticsId?: string;
    matomoId?: string;
    externalMatomoServer?: string;
    externalMatomoId?: string;
    chatEnabled?: boolean;
    boothConfiguration?: BoothConfigurationDto;
    socialProfiles?: { [key: string]: string; };
    shortLabel?: string;
    isOwnerExpo?: boolean;
    isOwnerExhibitor?: boolean;
    isManagerExhibitor?: boolean;
    internalContactMail?: string;
    publicContactMail?: string;
    contactMode?: ExhibitorContactMode;
    exhibitorInfoVisibility?: ExhibitorInfoVisibility;
    liveStreamActive?: boolean;
    liveStreamType?: StreamType;
    liveStreamId?: string;
    tags?: Array<string>;
    rowVersion?: number;
    expoDesign?: ExpoDesignDto;
}

/**
 * Namespace for property- and property-value-enumerations of BoothListItemDto.
 */
export namespace BoothListItemDto {
    /**
     * All properties of BoothListItemDto.
     */
    export enum Properties {
        sceneId = 'sceneId',
        id = 'id',
        shortKey = 'shortKey',
        name = 'name',
        company = 'company',
        phoneNumber = 'phoneNumber',
        address = 'address',
        email = 'email',
        description = 'description',
        descriptionLong = 'descriptionLong',
        categoryId = 'categoryId',
        label = 'label',
        website = 'website',
        logo = 'logo',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        backgroundImage = 'backgroundImage',
        posterA = 'posterA',
        posterB = 'posterB',
        hideAvatar = 'hideAvatar',
        groupChatEnabled = 'groupChatEnabled',
        singleChatEnabled = 'singleChatEnabled',
        chatWelcomeMessage = 'chatWelcomeMessage',
        singleChatWelcomeMessage = 'singleChatWelcomeMessage',
        boothIcon = 'boothIcon',
        avatar = 'avatar',
        panels = 'panels',
        displays = 'displays',
        links = 'links',
        infoSlides = 'infoSlides',
        showContact = 'showContact',
        contacts = 'contacts',
        sequence = 'sequence',
        schedulingService = 'schedulingService',
        schedulingKey = 'schedulingKey',
        schedulingActive = 'schedulingActive',
        googleAnalyticsId = 'googleAnalyticsId',
        matomoId = 'matomoId',
        externalMatomoServer = 'externalMatomoServer',
        externalMatomoId = 'externalMatomoId',
        chatEnabled = 'chatEnabled',
        boothConfiguration = 'boothConfiguration',
        socialProfiles = 'socialProfiles',
        shortLabel = 'shortLabel',
        isOwnerExpo = 'isOwnerExpo',
        isOwnerExhibitor = 'isOwnerExhibitor',
        isManagerExhibitor = 'isManagerExhibitor',
        internalContactMail = 'internalContactMail',
        publicContactMail = 'publicContactMail',
        contactMode = 'contactMode',
        exhibitorInfoVisibility = 'exhibitorInfoVisibility',
        liveStreamActive = 'liveStreamActive',
        liveStreamType = 'liveStreamType',
        liveStreamId = 'liveStreamId',
        tags = 'tags',
        rowVersion = 'rowVersion',
        expoDesign = 'expoDesign'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of BoothListItemDto.
    */
    export const ModelValidators: {[K in keyof BoothListItemDto]: [string, ValidatorFn][]} = {
        sceneId: [
        ],
        id: [
        ],
        shortKey: [
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        company: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        phoneNumber: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        address: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(500)],
        ],
        email: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        description: [
        ],
        descriptionLong: [
        ],
        categoryId: [
        ],
        label: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        website: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        logo: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        backgroundImage: [
        ],
        posterA: [
        ],
        posterB: [
        ],
        hideAvatar: [
        ],
        groupChatEnabled: [
        ],
        singleChatEnabled: [
        ],
        chatWelcomeMessage: [
        ],
        singleChatWelcomeMessage: [
        ],
        boothIcon: [
        ],
        avatar: [
        ],
        panels: [
        ],
        displays: [
        ],
        links: [
        ],
        infoSlides: [
        ],
        showContact: [
        ],
        contacts: [
        ],
        sequence: [
        ],
        schedulingService: [
        ],
        schedulingKey: [
        ],
        schedulingActive: [
        ],
        googleAnalyticsId: [
        ],
        matomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        externalMatomoServer: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        externalMatomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        chatEnabled: [
        ],
        boothConfiguration: [
        ],
        socialProfiles: [
        ],
        shortLabel: [
        ],
        isOwnerExpo: [
        ],
        isOwnerExhibitor: [
        ],
        isManagerExhibitor: [
        ],
        internalContactMail: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        publicContactMail: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        contactMode: [
        ],
        exhibitorInfoVisibility: [
        ],
        liveStreamActive: [
        ],
        liveStreamType: [
        ],
        liveStreamId: [
        ],
        tags: [
        ],
        rowVersion: [
        ],
        expoDesign: [
        ],
    };

    /**
    * The FormControlFactory for BoothListItemDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<BoothListItemDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for BoothListItemDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: BoothListItemDto = {
            sceneId: null,
            id: null,
            shortKey: null,
            name: null,
            company: null,
            phoneNumber: null,
            address: null,
            email: null,
            description: null,
            descriptionLong: null,
            categoryId: null,
            label: null,
            website: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            backgroundImage: null,
            posterA: null,
            posterB: null,
            hideAvatar: null,
            groupChatEnabled: null,
            singleChatEnabled: null,
            chatWelcomeMessage: null,
            singleChatWelcomeMessage: null,
            boothIcon: null,
            avatar: null,
            panels: null,
            displays: null,
            links: null,
            infoSlides: null,
            showContact: null,
            contacts: null,
            sequence: null,
            schedulingService: null,
            schedulingKey: null,
            schedulingActive: null,
            googleAnalyticsId: null,
            matomoId: null,
            externalMatomoServer: null,
            externalMatomoId: null,
            chatEnabled: null,
            boothConfiguration: null,
            socialProfiles: null,
            shortLabel: null,
            isOwnerExpo: null,
            isOwnerExhibitor: null,
            isManagerExhibitor: null,
            internalContactMail: null,
            publicContactMail: null,
            contactMode: null,
            exhibitorInfoVisibility: null,
            liveStreamActive: null,
            liveStreamType: null,
            liveStreamId: null,
            tags: null,
            rowVersion: null,
            expoDesign: null,
          }
        ) {
            super(model, BoothListItemDto.ModelValidators);
        }
    }

}


