import {ExpoDesignDto} from '../virtual-expo-api';

export class DesignDefault implements ExpoDesignDto {
  font_color = '#2d2d2d';
  title_color = '#2d2d2d';
  background_color = '#f0f0f0';
  modal_background_color = '#e0e0e0';
  box_background_color = '#ffffff';
  box_font_color = '#000000';
  button_background_color = '#ffffff';
  button_font_color = '#000000';
  button_size_top = 'action-button-md';
  button_size_bottom = 'action-button-md';

  expo_intro_mode = 0;
  expo_intro_image = '';
  expo_intro_padding_small = true;
  expo_intro_text_wide = true;
  expo_intro_text_frame = false;

  expo_desc_mode = 1;
  expo_desc_image = '';
  expo_desc_padding_small = true;
  expo_desc_text_wide = true;
  expo_desc_text_frame = true;

  landingpage = '';
}
