/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



/**
 * Class ChatMessageDto.
 */
export interface ChatMessageDto { 
    /**
     * Gets or sets the chat identifier.
     */
    chatId?: string;
    /**
     * Gets or sets the sender.
     */
    sender?: string;
    /**
     * Gets or sets the message.
     */
    message?: string;
    /**
     * Gets or sets a value indicating whether the sender is a team member.
     */
    senderIsTeam?: boolean;
    /**
     * Gets or sets the timestamp.
     */
    timestamp?: Date;
}

/**
 * Namespace for property- and property-value-enumerations of ChatMessageDto.
 */
export namespace ChatMessageDto {
    /**
     * All properties of ChatMessageDto.
     */
    export enum Properties {
        /**
         * Gets or sets the chat identifier.
         */
        chatId = 'chatId',
        /**
         * Gets or sets the sender.
         */
        sender = 'sender',
        /**
         * Gets or sets the message.
         */
        message = 'message',
        /**
         * Gets or sets a value indicating whether the sender is a team member.
         */
        senderIsTeam = 'senderIsTeam',
        /**
         * Gets or sets the timestamp.
         */
        timestamp = 'timestamp'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ChatMessageDto.
    */
    export const ModelValidators: {[K in keyof ChatMessageDto]: [string, ValidatorFn][]} = {
        chatId: [
        ],
        sender: [
        ],
        message: [
        ],
        senderIsTeam: [
        ],
        timestamp: [
        ],
    };

    /**
    * The FormControlFactory for ChatMessageDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ChatMessageDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ChatMessageDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ChatMessageDto = {
            chatId: null,
            sender: null,
            message: null,
            senderIsTeam: null,
            timestamp: null,
          }
        ) {
            super(model, ChatMessageDto.ModelValidators);
        }
    }

}


