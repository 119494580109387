import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {DefaultLayoutComponent} from './components/default-layout/default-layout.component';
import {PasswordLessLoginComponent} from './components/components/password-less-login/password-less-login.component';
import {PasswordLessConfirmComponent} from './components/components/password-less-confirm/password-less-confirm.component';
import {ProfileComponent} from './components/components/profile/profile.component';
import {AccountLayoutComponent} from './components/components/account-layout/account-layout.component';
import {EmptyFrameComponent} from './components/empty-frame/empty-frame.component';
import {ExhibitorLayoutComponent} from './components/exhibitor-layout/exhibitor-layout.component';
import {environment} from '../environments/environment';
import {HomeComponent} from './components/home/home.component';
import {ExpoStageRedirectComponent} from './components/expo-stage/expo-stage-redirect/expo-stage-redirect.component';

const routes: Routes = [
    {
      path: '',
      component: DefaultLayoutComponent,
      children: [
        {path: '', component: HomeComponent},
        {path: 'home', redirectTo: '/'},
        // {path: 'contact', component: ContactComponent},
        {path: 'expo',
          loadChildren: () => import('./modules/expo-gateway/expo-gateway.module')
            .then(m => m.ExpoGatewayModule)
        },
        // {path: 'exhibitions', component: HomeComponent},
        {path: 'exhibitions',
          loadChildren: () => import('./modules/home/home.module')
            .then(m => m.HomeModule)
        },
        // {path: 'exhibition/:id', component: ExpoOverviewComponent},
        {path: 'exhibition',
          loadChildren: () => import('./modules/expo/expo.module')
            .then(m => m.ExpoModule)
        },
        // {path: 'privacy', component: PrivacyComponent},
        // {path: 'tos', component: TosB2bComponent},
        // {path: 'tos-b2b', component: TosB2bComponent},
        // {path: 'aboutus', component: AboutUsComponent},
        // {path: 'rfq', component: RfqComponent},
        // {path: 'rfq/:level', component: RfqComponent},
        // {path: 'tos-b2b', component: TosB2bComponent},
        // {path: 'chat', component: ChatComponent},
      ]
    },
    {
      path: '',
      component: AccountLayoutComponent,
      children: [
        {path: 'login', component: PasswordLessLoginComponent},
        {path: 'callback/:email/:token', component: PasswordLessConfirmComponent},
        {path: 'profile', component: ProfileComponent},
      ]
    },
    {
      path: '',
      component: EmptyFrameComponent,
      children: [
        {
          path: 'stage/:expoId/:stageId/:id',
          component: ExpoStageRedirectComponent
        },
        {
          path: 'stage/:expoId/:stageId',
          component: ExpoStageRedirectComponent
        },
        {
          path: 'exhibition',
          loadChildren: () => import('./components/expo-stage/expo-stage.module')
            .then(m => m.ExpoStageModule)
        },
        {
          path: 'webmeet',
          loadChildren: () => import('./components/web-meeting/web-meeting.module')
            .then(m => m.WebMeetingModule)
        },
        {
          path: 'sessions',
          loadChildren: () => import('./components/expo-session/expo-session.module')
            .then(m => m.ExpoSessionModule)
        }
      ]
    },
    {
      path: '',
      component: ExhibitorLayoutComponent,
      children: [
        {
          path: 'exhibition',
          loadChildren: () => import('./modules/expo-exhibitor/expo-exhibitor.module')
            .then(m => m.ExpoExhibitorModule)
        }
      ]
    },
    {
      path: '',
      component: ExhibitorLayoutComponent,
      children: [
        {
          path: 'meeting',
          loadChildren: () => import('./components/meeting/meeting.module')
            .then(m => m.MeetingModule)
        }
      ]
    },
    {path: '',
      component: DefaultLayoutComponent,
      children: [
        // {path: 'notfound', component: NotFoundComponent},
        {path: '404', component: NotFoundComponent},
        {path: '**', redirectTo: '/'}
      ]
    }
// {
//   path: 'chat',
//   component: PopupFrameComponent,
//   children: [
//     {path: ':expoid/:id', component: ExpoExhibitorChatComponent}
//   ]
// }
  ]
;

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(
      routes,
      {
        preloadingStrategy: PreloadAllModules,
        useHash: environment.useHash,
        anchorScrolling: 'enabled'
      }
    )]
})
export class AppRoutingModule {

}
