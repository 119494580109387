<a href="{{'gdpr.linkContact' | translate}}" target="_blank" rel="noopener"
   (mousedown)="$event.preventDefault()"
   class="privacy-description mr-3 privacy-link">
  <span translate="gdpr.contact"></span> &nbsp;
  <i class="fas fa-external-link-alt"></i>
</a>
<a href="{{'gdpr.linkPrivacy' | translate}}" target="_blank" rel="noopener"
   (mousedown)="$event.preventDefault()"
   class="privacy-description privacy-link">
  <span translate="register.privacy"></span> &nbsp;
  <i class="fas fa-external-link-alt"></i>
</a>
