<div class="modal-header">
  <h4 class="modal-title" [translate]="'profile.header'"></h4>
  <a class="close" aria-label="Close" (click)="activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </a>
</div>
<div class="modal-body">
  <p [translate]="'profile.description'"></p>
  <div class="container">
    <form [formGroup]="valForm" class="form-validate mb-lg" role="form">
      <div class="row">
        <div class="col-sm-12 col-md-5">
          <div class="form-group has-feedback">
            <label for="name" [translate]="'profile.displayName'"></label>
            <input class="form-control" id="name" type="text" name="name"
                   [attr.placeholder]="'profile.displayNamePlaceholder' | translate"
                   autocomplete="off"
                   formControlName="name" required=""/>
            <app-control-validation-results [formGroup]="valForm"
                                            validatedControl="name"></app-control-validation-results>
          </div>
          <div class="form-group has-feedback">
            <label for="company" [translate]="'profile.company'"></label>
            <input class="form-control" id="company" type="text" name="company"
                   [attr.placeholder]="'profile.companyPlaceholder' | translate"
                   autocomplete="off"
                   formControlName="company"/>
            <app-control-validation-results [formGroup]="valForm"
                                            validatedControl="company"></app-control-validation-results>
          </div>
          <div class="form-group has-feedback">
            <label for="company" [translate]="'profile.role'"></label>
            <input class="form-control" id="role" type="text" name="role"
                   [attr.placeholder]="'profile.rolePlaceholder' | translate"
                   autocomplete="off"
                   formControlName="role"/>
            <app-control-validation-results [formGroup]="valForm"
                                            validatedControl="role"></app-control-validation-results>
          </div>
          <div class="form-group has-feedback">
            <label for="address" [translate]="'profile.address'"></label>
            <input class="form-control" id="address" type="text" name="address"
                   [attr.placeholder]="'profile.addressPlaceholder' | translate"
                   autocomplete="off"
                   formControlName="address"/>
            <app-control-validation-results [formGroup]="valForm"
                                            validatedControl="address"></app-control-validation-results>
          </div>
          <div class="form-group has-feedback">
            <label for="phoneNumber" [translate]="'profile.phoneNumber'"></label>
            <input class="form-control" id="phoneNumber" type="tel" name="phoneNumber"
                   [attr.placeholder]="'profile.phoneNumberPlaceholder' | translate"
                   autocomplete="off"
                   formControlName="phoneNumber"/>
            <app-control-validation-results [formGroup]="valForm"
                                            validatedControl="phoneNumber"></app-control-validation-results>
          </div>
          <div class="form-group has-feedback">
            <label for="email" [translate]="'profile.email'"></label>
            <input class="form-control" id="email" type="email" name="email"
                   [attr.placeholder]="'profile.emailPlaceholder' | translate"
                   autocomplete="off"
                   formControlName="email"/>
            <app-control-validation-results [formGroup]="valForm"
                                            validatedControl="email"></app-control-validation-results>
          </div>
        </div>
        <div class="col-sm-12 col-md-7">
          <div class="card">
            <div class="card-body">
              <label style="width: 100%;">Social networks
                <button class="btn btn-sm btn-primary float-right" (click)="addProfile($event)">Add</button>
              </label>
              <ng-container *ngIf="profile">
                <div class="row social" *ngFor="let item of profile.socialProfiles | keyvalue">
                  <div class="col-sm-4" [translate]="'profile.' + item.key"></div>
                  <div class="col-sm-4">
                    {{ item.value }}
                  </div>
                  <div class="col-sm-4">
                    <div class="float-right">
                      <i class="btn btn-sm btn-success fa fa-edit" (click)="editSocial($event, item)"> </i> &nbsp;
                      <i class="btn btn-sm btn-danger fa fa-trash-alt" (click)="removeSocial($event, item)"> </i>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="clearfix">
          <span class="text-danger" *ngIf="errorMessage">{{ errorMessage }}</span>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <div class="container-fluid row">
    <div class="col-md-2">
      <a class="btn btn-block btn-danger" href="#" (click)="close($event)">Cancel</a>
    </div>
    <div class="col-md-6">
      &nbsp;
    </div>
    <div class="col-md-4">
      <a class="btn btn-block btn-success" href="#" (click)="submitForm($event)">Save</a>
    </div>
  </div>
</div>
