/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export type BookingResultState = 0 | 1 | 10 | 20 | 100;

export const BookingResultState = {
    NUMBER_0: 0 as BookingResultState,
    NUMBER_1: 1 as BookingResultState,
    NUMBER_10: 10 as BookingResultState,
    NUMBER_20: 20 as BookingResultState,
    NUMBER_100: 100 as BookingResultState
};

