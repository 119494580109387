import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-frame',
  templateUrl: './empty-frame.component.html',
  styleUrls: ['./empty-frame.component.scss']
})
export class EmptyFrameComponent {

  constructor() { }
}
