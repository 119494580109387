import {Component, OnInit} from '@angular/core';
import {VersionCheckService} from '../../services/version-check.service';
import {GdprService} from '../../services/gdpr.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  privacyLink: string;

  contactLink: boolean;
  homepageLink: boolean;

  isProduction = environment.production;

  constructor(
    private gdprService: GdprService
    , private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.translateService.onLangChange.subscribe(() => {
      this.loadText();
    });
    this.loadText();
  }

  loadText() {
    this.translateService.get('gdpr.linkPrivacy')
      .subscribe(value => {
        this.privacyLink = value;
      });

    this.translateService.get('gdpr.linkContact')
      .subscribe(value => {
        this.contactLink = value;
      });

    this.translateService.get('gdpr.linkHome')
      .subscribe(value => {
        this.homepageLink = value;
      });
  }

  getDate(): string {
    return ((new Date()).getFullYear()).toString();
  }

  getBuildDate(): string {
    return environment.timeStamp;
  }

  showPrivacy($event: MouseEvent) {
    $event.preventDefault();
    this.gdprService.checkDialog();
  }
}
