import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VHostService {
  IsLoaded: EventEmitter<void> = new EventEmitter<void>();

  shortKey: string;
  showHall = false;
  loaded = false;
  isVhost = false;
  expoName: string;
  exhibitorShortKey: string;
  exhibitorName: string;
  defaultLanguage: string;
  accessMode: number;

  constructor() {
  }

  triggerLoaded() {
    // console.log('triggerLoaded');
    this.loaded = true;
    this.IsLoaded.emit();
  }
}
