import {EventEmitter, Injectable} from '@angular/core';
import {ColorHelperService} from '../components/booths/color-helper.service';
import {BoothConfigurationDto, BoothDetailItemDto, ExpoDesignDto} from '../virtual-expo-api';
import {DesignDefault} from '../objects/expo-design';
import {ExhibitorExtended} from '../objects/exhibitor-extended';
import {ImageUrlService} from '../components/booths/image-url.service';

@Injectable({
  providedIn: 'root'
})
export class ExpoDesignService {
  design: ExpoDesignDto;
  defaultDesign: ExpoDesignDto;

  isOverride: boolean;

  public designChanged: EventEmitter<{ expoId: string, design: ExpoDesignDto }>
    = new EventEmitter<{ expoId: string, design: ExpoDesignDto }>();
  public boothDesignChanged: EventEmitter<{ expoId: string, exhibitorId: string, design: BoothConfigurationDto }>
    = new EventEmitter<{ expoId: string, exhibitorId: string, design: BoothConfigurationDto }>();

  // public introRestarted: Subject<any> = new Subject<any>();
  constructor(
    private colorHelper: ColorHelperService
    , private imageUrlHelper: ImageUrlService
  ) {
    this.defaultDesign = new DesignDefault();
  }

  public applyDesignChanges(value: BoothDetailItemDto, exhibitor: ExhibitorExtended) {
    if (value.id === exhibitor.id) {
      exhibitor.boothConfiguration = value.boothConfiguration;
      exhibitor.avatar = value.avatar !== '' && value.avatar !== 'DELETED' ? value.avatar : null;
      exhibitor.lecternVisibility = value.lecternVisibility;
      exhibitor.hideAvatar = value.hideAvatar;
      exhibitor.hideFurniture = value.hideFurniture;
      exhibitor.hideLogo = value.hideLogo;
      exhibitor.hideWalls = value.hideWalls;
      exhibitor.posterA = value.posterA;
      exhibitor.posterB = value.posterB;
      exhibitor.backgroundImage = value.backgroundImage !== '' && value.backgroundImage !== 'DELETED' ? value.backgroundImage : null;
      exhibitor.boothConfiguration.bannerImage = value.boothConfiguration.bannerImage !== '' && value.boothConfiguration.bannerImage !== 'DELETED' ? value.boothConfiguration.bannerImage : null;
    }
  }

  applyDesign(expoId: string, design?: ExpoDesignDto, force?: boolean) {
    if (!this.isOverride) {
      if (!design) {
        this.design = this.defaultDesign;
      } else {
        this.design = design;
      }
    }
    if (force) {
      this.design = design;
    }
    if (this.design.expo_intro_image === 'DELETED') {
      this.design.expo_intro_image = '';
    }
    if (this.design.expo_desc_image === 'DELETED') {
      this.design.expo_desc_image = '';
    }
    if (this.design.backgroundImage === 'DELETED') {
      this.design.backgroundImage = '';
    }
    if (this.design.expo_desc_mode == null) {
      this.design.expo_desc_mode = 1;
    }
    if (this.design.button_size_top == null) {
      this.design.button_size_top = 'action-button-md';
    }
    if (this.design.button_size_bottom == null) {
      this.design.button_size_bottom = 'action-button-md';
    }
    if (this.design.button_size_booth == null) {
      this.design.button_size_booth = 'action-button-md';
    }
    if (this.design.button_background_color == null) {
      this.design.button_background_color = this.design.box_background_color;
    }
    if (this.design.button_font_color == null) {
      this.design.button_font_color = this.design.box_font_color;
    }
    const result: { [key: string]: string } = {};


    if (!this.design.defaultFontSize) {
      this.design.defaultFontSize = 18;
    }

    if (!this.design.introFontSize) {
      this.design.introFontSize = 18;
    }

    const defaultFontSize = (this.design.defaultFontSize / 18).toString() + 'vw';
    result[`expo-font-size`] = defaultFontSize;

    const introFontSize = (this.design.introFontSize / 18).toString() + 'vw';
    result[`expo-intro-font-size`] = introFontSize;

    this.addColorGroup(result, this.design.font_color, 'font');
    this.addColorGroup(result, this.design.title_color, 'title');
    this.addColorGroup(result, this.design.background_color, 'background');
    this.addColorGroup(result, this.design.modal_background_color, 'modal-background');
    this.addColorGroup(result, this.design.box_background_color, 'box-background');
    this.addColorGroup(result, this.design.box_font_color, 'box-font');
    this.addColorGroup(result, this.design.button_background_color, 'button-background');
    this.addColorGroup(result, this.design.button_font_color, 'button-font');
    this.setTheme(result);
    this.designChanged.emit({expoId: expoId, design: this.design});
  }

  private addColorGroup(result: { [key: string]: string } = {}, hex: string, area: string) {
    if (hex && hex.startsWith('rgba')) {
      hex = this.colorHelper.rgba2hex(hex);
    }
    if (hex && hex !== '') {
      const parts = this.colorHelper.colorStringToArray(hex);
      result[area + '-red'] = parts[0];
      result[area + '-green'] = parts[1];
      result[area + '-blue'] = parts[2];
    }
  }

  private setTheme(theme: { [key: string]: string }) {
    Object.keys(theme).forEach(k =>
      document.documentElement.style.setProperty(`--${k}`, theme[k])
    );
  }

  overrideDesign(expoId: string, design?: ExpoDesignDto, exhibitor?: any) {
    this.isOverride = true;
    this.applyDesign(expoId, design, true);
  }

  overrideBoothDesign(expoId: string, exhibitorId: string, design?: BoothConfigurationDto) {
    this.boothDesignChanged.emit({expoId: expoId, exhibitorId: exhibitorId, design: design});
  }
}
