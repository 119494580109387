import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ExpoLdMeta} from '../objects/page-ld-meta';
import {NgxSpinnerService} from 'ngx-spinner';
import {debounceTime} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {
  private triggerShow: Subject<string> = new Subject<string>();
  private triggerHide: Subject<string> = new Subject<string>();
  isShowing = false;

  constructor(private spinner: NgxSpinnerService) {
    this.triggerShow.pipe(debounceTime(500))
      .subscribe(value => {
        if (this.isShowing) {
          this.isShowing = false;
          this.spinner.show(value);
        }
      });

    this.triggerHide.pipe(debounceTime(500))
      .subscribe(value => {
        this.isShowing = false;
        this.spinner.hide(value);
      });
  }

  // public get Show(): Observable<string> {
  //   return this.triggerShow.asObservable();
  // }


  // public get Hide(): Observable<string> {
  //   return this.triggerHide.asObservable();
  // }

  show(name?: string) {
    this.isShowing = true;
    this.triggerShow.next(name);
  }

  hide(name?: string) {
    // this.spinner.hide(name);
    this.triggerHide.next(name);
  }
}
