/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { TrackingSettings } from './trackingSettings';


export interface CallDetails { 
    name?: string;
    expoName?: string;
    expoShortKey?: string;
    expoTrackingSettings?: TrackingSettings;
    exhibitorName?: string;
    exhibitorShortKey?: string;
    exhibitorTrackingSettings?: TrackingSettings;
    hash?: string;
}

/**
 * Namespace for property- and property-value-enumerations of CallDetails.
 */
export namespace CallDetails {
    /**
     * All properties of CallDetails.
     */
    export enum Properties {
        name = 'name',
        expoName = 'expoName',
        expoShortKey = 'expoShortKey',
        expoTrackingSettings = 'expoTrackingSettings',
        exhibitorName = 'exhibitorName',
        exhibitorShortKey = 'exhibitorShortKey',
        exhibitorTrackingSettings = 'exhibitorTrackingSettings',
        hash = 'hash'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of CallDetails.
    */
    export const ModelValidators: {[K in keyof CallDetails]: [string, ValidatorFn][]} = {
        name: [
        ],
        expoName: [
        ],
        expoShortKey: [
        ],
        expoTrackingSettings: [
        ],
        exhibitorName: [
        ],
        exhibitorShortKey: [
        ],
        exhibitorTrackingSettings: [
        ],
        hash: [
        ],
    };

    /**
    * The FormControlFactory for CallDetails.
    */
    export class FormControlFactory extends BaseFormControlFactory<CallDetails> {

        /**
         * Constructor.
         *
         * @param model An existing model for CallDetails.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: CallDetails = {
            name: null,
            expoName: null,
            expoShortKey: null,
            expoTrackingSettings: null,
            exhibitorName: null,
            exhibitorShortKey: null,
            exhibitorTrackingSettings: null,
            hash: null,
          }
        ) {
            super(model, CallDetails.ModelValidators);
        }
    }

}


