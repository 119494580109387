<a (click)="openExpoLink($event)" [href]="expoLink" class="expo-item">
  <div class="expo-item-wrap match">
    <div style="--aspect-ratio: 16/9" class="expo-logo">
      <div>
        <img [src]="logoWide" alt="">
      </div>
    </div>
    <div>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           viewBox="0 0 100 28"
      >
        <g transform="translate(0,0)">
          <foreignObject
            x="0" y="0" width="100" height="28"
            transform="translate(0,0)"
          >
            <xhtml:div>
              <h3 style="font-size: 5px; margin: 0; height: inherit; line-height: 7px">{{ expo.name }}</h3>
              <p class="description" style="font-size: 4px; margin: 0; height: 18px;">{{ expo.description }} </p>
            </xhtml:div>
          </foreignObject>
        </g>
      </svg>
    </div>
    <!--    <div>-->
    <!--      <h3>{{ expo.name }}</h3>-->
    <!--      <p class="description">{{ expo.description }} </p>-->
    <!--      &lt;!&ndash;      <div class="date">{{ 'expo.time' | translate:{start: startDate, end: endDate} }}</div>&ndash;&gt;-->
    <!--    </div>-->
  </div>
<!--  <div class="expoComingSoon" *ngIf="isFuture">-->
<!--    <div class="expoText" [translate]="'global.comingSoon'"></div>-->
<!--  </div>-->
<!--  <div class="expoComingSoon" *ngIf="isPast">-->
<!--    <div class="expoText" [translate]="'global.alreadyEnded'"></div>-->
<!--  </div>-->
</a>

<!--<a (click)="openExpoLink($event)" [href]="expoLink">-->
<!--  <div class="expoItem">-->
<!--    <div class="squareimage">-->
<!--      <img [src]="logoSquare" alt="" class="img-fluid">-->
<!--      <div class="locked-label" *ngIf="expo.accessMode === 10 && !access[expo.id]">-->
<!--        <i class="fa fa-lock"> </i><span class="register-comment" [translate]="'global.registrationRequired'"></span>-->
<!--      </div>-->
<!--      <div class="locked-label" *ngIf="expo.accessMode === 10 && access[expo.id]">-->
<!--        <i class="fa fa-unlock"> </i><span class="register-comment" [translate]="'global.registered'"></span>-->
<!--      </div>-->
<!--      <div class="locked-label" *ngIf="expo.accessMode === 100">-->
<!--        <i class="fa fa-lock fa-2x" style="color: red;"> </i>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="expoComingSoon" *ngIf="isFuture">-->
<!--      <div class="expoText" [translate]="'global.comingSoon'">-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="expoComingSoon" *ngIf="isPast">-->
<!--      <div class="expoText" [translate]="'global.alreadyEnded'">-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="expoOverlay">-->
<!--      <div class="expoText">-->
<!--        <h3>{{ expo.name }}</h3>-->
<!--&lt;!&ndash;        <h4>{{ 'expo.time' | translate:{start: startDate, end: endDate} }}</h4>&ndash;&gt;-->
<!--&lt;!&ndash;        <p class="expoDescription">{{ expo.description }}</p>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--&lt;!&ndash;    <div class="expoHeader">&ndash;&gt;-->
<!--&lt;!&ndash;      {{ 'expo.time' | translate:{start: startDate, end: endDate} }}<br/>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--  </div>-->
<!--</a>-->
<!--<a (click)="openExpoLink($event)" [href]="expoLink">-->
<!--  <div class="expoItem">-->
<!--    <div class="squareimage">-->
<!--      <img [src]="logoSquare" alt="" class="img-fluid">-->
<!--      <div class="locked-label" *ngIf="expo.accessMode === 10 && !access[expo.id]">-->
<!--        <i class="fa fa-lock"> </i><span class="register-comment" [translate]="'global.registrationRequired'"></span>-->
<!--      </div>-->
<!--      <div class="locked-label" *ngIf="expo.accessMode === 10 && access[expo.id]">-->
<!--        <i class="fa fa-unlock"> </i><span class="register-comment" [translate]="'global.registered'"></span>-->
<!--      </div>-->
<!--      <div class="locked-label" *ngIf="expo.accessMode === 100">-->
<!--        <i class="fa fa-lock fa-2x" style="color: red;"> </i>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="expoComingSoon" *ngIf="isFuture">-->
<!--      <div class="expoText" [translate]="'global.comingSoon'">-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="expoComingSoon" *ngIf="isPast">-->
<!--      <div class="expoText" [translate]="'global.alreadyEnded'">-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="expoOverlay">-->
<!--      <div class="expoText">-->
<!--        <h3>{{ expo.name }}</h3>-->
<!--&lt;!&ndash;        <h4>{{ 'expo.time' | translate:{start: startDate, end: endDate} }}</h4>&ndash;&gt;-->
<!--&lt;!&ndash;        <p class="expoDescription">{{ expo.description }}</p>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--&lt;!&ndash;    <div class="expoHeader">&ndash;&gt;-->
<!--&lt;!&ndash;      {{ 'expo.time' | translate:{start: startDate, end: endDate} }}<br/>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--  </div>-->
<!--</a>-->
