import {
  Component,
  HostBinding,
  Input,
  ChangeDetectionStrategy, OnInit, OnChanges, SimpleChanges
} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {TitleTagService} from '../../../services/title-tag.service';
import {ExpoLdMeta, PageLdMeta} from '../../../objects/page-ld-meta';

@Component({
  selector: 'app-json-ld',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JsonLDComponent implements OnInit, OnChanges {
  @Input()
  set json(currentValue: {}) {
    this.jsonLD = this.getSafeHTML(currentValue);
  }

  @HostBinding('innerHTML') jsonLD: SafeHtml;

  constructor(private sanitizer: DomSanitizer, private titleTagService: TitleTagService) {
  }

  ngOnInit() {
    this.titleTagService.UpdateExhibitorLd.subscribe((value) => {
      this.createExhibitorJson(value);
    });
    this.titleTagService.UpdateExpoLd.subscribe((value) => {
      this.createExpoJson(value);
    });
    this.createJson();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.createJson();
  }

  createJson() {
    const json = {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      url: 'https://virtualexpo.info',
      name: 'dotEnterprise VirtualExpo',
      contactPoint: {
        '@type': 'ContactPoint',
        email: 'support@dotenterprise.com',
        contactType: 'Customer service'
      }
    };

    this.jsonLD = this.getSafeHTML(json);
  }

  createExhibitorJson(value: PageLdMeta) {
    const json = {
      '@context': 'https://schema.org',
      '@type': 'Article',
      'mainEntityOfPage': {
        '@type': 'WebPage',
        '@id': value.url
      },
      'headline': value.title,
      'image': value.images,
      'datePublished': value.startDate.toISOString(),
      'publisher': {
        '@type': 'Organization',
        'name': value.orgaName,
        'logo': {
          '@type': 'ImageObject',
          'url': value.orgaLogo
        }
      },
      'author': {
        '@type': 'Organization',
        'name': value.orgaName,
        'logo': {
          '@type': 'ImageObject',
          'url': value.orgaLogo
        }
      }
    };

    this.jsonLD = this.getSafeHTML(json);
  }

  createExpoJson(value: ExpoLdMeta) {
    const json = {
      '@context': 'https://schema.org',
      '@type': 'Event',
      'mainEntityOfPage': {
        '@type': 'WebPage',
        '@id': value.url
      },
      'name': value.title ,
      'startDate': value.startDate.toISOString(),
      'endDate': value.endDate.toISOString(),
      'eventStatus': 'https://schema.org/EventScheduled',
      'eventAttendanceMode': 'https://schema.org/OnlineEventAttendanceMode',
      'location': {
        '@type': 'VirtualLocation',
        'url': value.url
      },
      'image': value.images,
      'description': value.description,
      'organizer': {
        '@type': 'Organization',
        'name': value.title,
        'url': value.url,
        'logo': {
          '@type': 'ImageObject',
          'url': value.images[0]
        }
      }
    };

    this.jsonLD = this.getSafeHTML(json);
  }

  getSafeHTML(value: {}) {
    const json = value
      ? JSON.stringify(value, null, 2).replace(/<\/script>/g, '<\\/script>')
      : '';
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
