<div class="modal-header">
  <h4 class="modal-title" [translate]="'videoCall.header'" [translateParams]="{'name': senderName }"></h4>
  <a class="close" aria-label="Close" (click)="hangup()">
    <span aria-hidden="true">&times;</span>
  </a>
</div>
<div class="modal-body">
  <div style="--aspect-ratio: 16/9">
    <div id="meet"></div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="hangup()" [translate]="'videoCall.hangup'"></button>
</div>
