/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ActionButton { 
    sequence?: number;
    active?: boolean;
    label: string;
    url: string;
    size?: number;
}

/**
 * Namespace for property- and property-value-enumerations of ActionButton.
 */
export namespace ActionButton {
    /**
     * All properties of ActionButton.
     */
    export enum Properties {
        sequence = 'sequence',
        active = 'active',
        label = 'label',
        url = 'url',
        size = 'size'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ActionButton.
    */
    export const ModelValidators: {[K in keyof ActionButton]: [string, ValidatorFn][]} = {
        sequence: [
        ],
        active: [
        ],
        label: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(4)],
                ['maxlength', Validators.maxLength(100)],
        ],
        url: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(4)],
                ['maxlength', Validators.maxLength(500)],
        ],
        size: [
        ],
    };

    /**
    * The FormControlFactory for ActionButton.
    */
    export class FormControlFactory extends BaseFormControlFactory<ActionButton> {

        /**
         * Constructor.
         *
         * @param model An existing model for ActionButton.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ActionButton = {
            sequence: null,
            active: null,
            label: null,
            url: null,
            size: null,
          }
        ) {
            super(model, ActionButton.ModelValidators);
        }
    }

}


