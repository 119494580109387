import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '../../../services/data.service';

@Component({
  selector: 'app-password-less-confirm',
  templateUrl: './password-less-confirm.component.html',
  styleUrls: ['./password-less-confirm.component.scss']
})
export class PasswordLessConfirmComponent implements OnInit {
  error = false;

  constructor(
    public activeModal: NgbActiveModal,
    private route: ActivatedRoute
    , private router: Router
    , private dataService: DataService
  ) {
  }

  ngOnInit(): void {
    const email = this.route.snapshot.paramMap.get('email');
    const token = this.route.snapshot.paramMap.get('token');
    this.dataService.signInService.validatePwdLess(email, token)
      .subscribe(value => {
        const authData = this.dataService.signInService.getTokenData();
        const nameId = authData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
        const givenName = authData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
        if (nameId === givenName) {
          this.router.navigate(['profile']);
        } else {
          this.router.navigate(['/']);
        }
      }, error => {
        this.error = true;
        // console.log(error);
      });
  }
}
