import { Inject, Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DownloadRegisterComponent } from "../components/components/download-register/download-register.component";
import { TrackRedirectData } from "./track-redirect-data";
import { SignInService } from "./sign-in.service";
import { Router } from "@angular/router";
import { DisplayListDto, ExpoExhibitorListItemDto, TrackDownloadDto } from "../virtual-expo-api";
import { ImageUrlService } from "../components/booths/image-url.service";
import { SignInData } from "./sign-in-data";
import { WindowService } from "./window.service";
import { DataService } from "./data.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ConfirmationDialogService } from "../components/components/confirmation-dialog/confirmation-dialog.service";

@Injectable({
  providedIn: "root"
})
export class TrackRedirectService {
  data: TrackRedirectData;

  constructor(private modalService: NgbModal
    , private signInService: SignInService
    , private dataService: DataService
    , private imageUrlService: ImageUrlService
    , private _window: WindowService
    , private confirmationService: ConfirmationDialogService
  ) {
    const tmp = sessionStorage.getItem("userdata");
    if (tmp) {
      this.data = JSON.parse(tmp);
    }
  }

  private submit(trackDownload: TrackDownloadDto, externalLinksDirectOpen: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (trackDownload.permitContact || trackDownload.accessMode === 0) {
        return this.dataService.webTrackDownloadPost(trackDownload)
          .then(value => {
            if (trackDownload.trackingType === 0) {
              if (trackDownload.displayId && trackDownload.url.startsWith(environment.cdnURL)) {
                const url = `${environment.apiurl}web/pdf/${trackDownload.displayId}?ngsw-bypass=true`;
                this._window.open(url, "_blank");
              } else {
                this.openExternal(trackDownload.url, { externalLinksDirectOpen });
              }
            } else if (trackDownload.trackingType === 3) {
              this.openExternal(trackDownload.url, { externalLinksDirectOpen });
            }
            resolve(true);
          }, error => {
            reject();
          });
      } else {
        reject();
      }
    });
  }

  doTrack(trackDownloadDto: TrackDownloadDto, externalLinksDirectOpen: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (trackDownloadDto.accessMode !== 0) {
        this.signInService.getToken()
          .then(value => {
            this.doExecuteTrack(trackDownloadDto, value, externalLinksDirectOpen)
              .then(value1 => {
                resolve(value1);
              }, reason => {
                reject(reason);
              })
              .catch(reason => {
                reject(reason);
              });
          }, reason => {
            reject(reason);
          });
      } else {
        this.doExecuteTrack(trackDownloadDto, null, externalLinksDirectOpen)
          .then(value1 => {
            resolve(value1);
          }, reason => {
            reject(reason);
          })
          .catch(reason => {
            reject(reason);
          });
      }
    });
  }

  doExecuteTrack(trackDownloadDto: TrackDownloadDto, value: SignInData, externalLinksDirectOpen: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (trackDownloadDto.accessMode === 0 || (value && value?.permit)) {
        trackDownloadDto.permitContact = value ? value.permit : false;
        trackDownloadDto.name = value && value.permit ? value.name : "visitor";
        trackDownloadDto.emailAddress = value && value.permit ? value.email : "guest@email.guest";
        this.submit(trackDownloadDto, externalLinksDirectOpen)
          .then(value1 => {
            resolve(value1);
          }, reason => {
            reject(reason);
          })
          .catch(reason => {
            reject(reason);
          });
      } else {
        reject();
      }
    });
  }

  trackDisplay(display: DisplayListDto, exhibitor: ExpoExhibitorListItemDto): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const trackDownloadDto: TrackDownloadDto = {
        url: this.imageUrlService.getImage(display.source, display),
        expoId: exhibitor.expo.id,
        exhibitorId: exhibitor.id,
        name: "",
        emailAddress: "",
        permitContact: false,
        trackingType: 0,
        accessMode: exhibitor.expo.accessMode
      };
      this.doTrack(trackDownloadDto, exhibitor.expo.externalLinksDirectOpen)
        .then(value => {
          resolve(value);
        }, reason => {
          reject(reason);
        })
        .catch(reason => {
          reject(reason);
        });
    });
  }

  trackPdf(display: DisplayListDto, exhibitor: ExpoExhibitorListItemDto): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const trackDownloadDto: TrackDownloadDto = {
        url: this.imageUrlService.getDownload(display.source, display),
        expoId: exhibitor.expo.id,
        exhibitorId: exhibitor.id,
        name: display.description,
        emailAddress: "",
        permitContact: false,
        trackingType: 0,
        accessMode: exhibitor.expo.accessMode,
        displayId: display.id
      };
      this.doTrack(trackDownloadDto, exhibitor.expo.externalLinksDirectOpen)
        .then(value => {
          resolve(value);
        }, reason => {
          reject(reason);
        })
        .catch(reason => {
          reject(reason);
        });
    });
  }

  trackLink(display: { link?: string, description?: string, id?: string }, exhibitor: ExpoExhibitorListItemDto): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let url = display.link;
      if (!url.startsWith("http://") && !url.startsWith("https://") && !url.startsWith("//")) {
        url = "//" + url;
      }
      const trackDownloadDto: TrackDownloadDto = {
        url: url,
        expoId: exhibitor.expo.id,
        exhibitorId: exhibitor.id,
        name: display.description,
        emailAddress: "",
        permitContact: false,
        trackingType: 3,
        accessMode: exhibitor.expo.accessMode,
        displayId: display.id
      };
      this.doTrack(trackDownloadDto, exhibitor.expo.externalLinksDirectOpen)
        .then(value => {
          resolve(value);
        }, reason => {
          reject(reason);
        })
        .catch(reason => {
          reject(reason);
        });
    });
  }

  trackTextChat(exhibitor: ExpoExhibitorListItemDto): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const trackDownloadDto: TrackDownloadDto = {
        expoId: exhibitor.expo.id,
        exhibitorId: exhibitor.id,
        name: "",
        emailAddress: "",
        permitContact: false,
        trackingType: 1,
        accessMode: exhibitor.expo.accessMode
      };
      this.doTrack(trackDownloadDto, exhibitor.expo.externalLinksDirectOpen)
        .then(value => {
          resolve(value);
        }, reason => {
          reject(reason);
        })
        .catch(reason => {
          reject(reason);
        });
    });
  }

  trackVideoChat(exhibitor: ExpoExhibitorListItemDto): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const trackDownloadDto: TrackDownloadDto = {
        expoId: exhibitor.expo.id,
        exhibitorId: exhibitor.id,
        name: "",
        emailAddress: "",
        permitContact: false,
        trackingType: 2,
        accessMode: exhibitor.expo.accessMode
      };
      this.doTrack(trackDownloadDto, exhibitor.expo.externalLinksDirectOpen)
        .then(value => {
          resolve(value);
        }, reason => {
          reject(reason);
        })
        .catch(reason => {
          reject(reason);
        });
    });
  }

  // openWindow(url: string, target: string, params: string) {
  //   if (!url || url.trim() === '') {
  //     return;
  //   }
  //   url = url.toLocaleLowerCase();
  //   if (!url.startsWith('http://') && !url.startsWith('https://') && !url.startsWith('//')) {
  //     url = '//' + url;
  //   }
  //   this._window.open(url, target, params);
  // }

  checkOpen(url: string, target: string, params: string, appendToken: boolean = false) {
    if (this.data && this.data.permit) {
      if (appendToken) {
        const token = this.signInService.getToken().then(value => {
          url += "?access-token=" + value.token;
          this._window.open(url, target, params);
        });
      } else {
        this._window.open(url, target, params);
      }
    } else {
      const ref = this.modalService.open(DownloadRegisterComponent, { backdrop: "static", size: "lg" });
      ref.result.then((result) => {

        if (result.success) {
          this.data = result.data as TrackRedirectData;
          const tmp = sessionStorage.setItem("userdata", JSON.stringify(this.data));
          if (appendToken) {
            const token = this.signInService.getToken().then(value => {
              url += "?access-token=" + value.token;
              this._window.open(url, target, params);
            });
          } else {
            this._window.open(url, target, params);
          }
        }
      }, (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
      ref.componentInstance.preset = this.data;
    }
  }

  openExternal(url: string, expoSettings?: { externalLinksDirectOpen?: boolean }) {
    if (url && url.trim() !== '' && !url.startsWith('http') && !url.startsWith('//')) {
      url = '//' + url;
    }

    if (expoSettings && expoSettings.externalLinksDirectOpen) {
      this._window.open(url, "_blank");
    } else {
      this.confirmationService.confirmTranslate("global.link-open-header", "global.link-open-message"
        , "global.link-open-yes", "global.link-open-no", "lg", { url: url })
        .then((result) => {
          if (result) {
            this._window.open(url, "_blank");
          }
        });
    }
  }
}

