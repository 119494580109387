import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injectable, Injector, NgModule} from '@angular/core';
import {
  HttpClientModule,
  HttpClient,
  HttpUrlEncodingCodec,
  HTTP_INTERCEPTORS,
  HttpErrorResponse
} from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandlerParams,
  MissingTranslationHandler
} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppComponent} from './app.component';
import {ExpoExhibitorSmallComponent} from './components/expo-exhibitor-small/expo-exhibitor-small.component';
import {MenuComponent} from './components/menu/menu.component';
import {FooterComponent} from './components/footer/footer.component';
import {AppRoutingModule} from './app-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {DownloadRegisterComponent} from './components/components/download-register/download-register.component';
import {PopupFrameComponent} from './components/popup-frame/popup-frame.component';
import {DefaultLayoutComponent} from './components/default-layout/default-layout.component';
import {VideoChatComponent} from './components/components/video-chat/video-chat.component';
import {environment} from '../environments/environment';
import {ApiModule, BASE_PATH, Configuration, ConfigurationParameters} from './virtual-expo-api';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {VersionCheckService} from './services/version-check.service';
import {LandingComponent} from './components/landing/landing.component';
import {PasswordLessLoginComponent} from './components/components/password-less-login/password-less-login.component';
import {PasswordLessConfirmComponent} from './components/components/password-less-confirm/password-less-confirm.component';
import {ProfileComponent} from './components/components/profile/profile.component';
import {AccountHeaderComponent} from './components/components/account-header/account-header.component';
import {AccountFooterComponent} from './components/components/account-footer/account-footer.component';
import {AccountLayoutComponent} from './components/components/account-layout/account-layout.component';
import {SharedModule} from './modules/shared/shared.module';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {EnterRegisterComponent} from './components/components/enter-register/enter-register.component';
import {ProfileEditSocialComponent} from './components/components/profile-edit-social/profile-edit-social.component';
import {JsonLDComponent} from './components/components/json-ld/json-ld.component';
// import {NgxSpinnerModule} from 'ngx-spinner';
import {EmptyFrameComponent} from './components/empty-frame/empty-frame.component';
import {ExhibitorLayoutComponent} from './components/exhibitor-layout/exhibitor-layout.component';
import * as Sentry from '@sentry/browser';
import {ServiceWorkerModule} from '@angular/service-worker';
import {APP_BASE_HREF, Location} from '@angular/common';
import {NgAddToCalendarModule} from './modules/ngAddToCalendar/ng-add-to-calendar.module';
import {ChatRegisterComponent} from './components/components/chat-register/chat-register.component';
import {ExpoCommonModule} from './modules/expo-common/expo-common.module';
import {LoadingIndicatorComponent} from './components/components/loading-indicator/loading-indicator.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {CheckForUpdateService} from './services/Updater/check-update.service';
import {PromptUpdateService} from './services/Updater/prompt-update.service';
import {LogUpdateService} from './services/Updater/log-update.service';

let ignoreError = false;

if (environment.sentryEnabled) {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.production ? 'production' : 'development',
    release: environment.timeStamp,
    autoSessionTracking: false,
    tracesSampleRate: 0.01,
    beforeBreadcrumb: (response, hint) => {
      // if (response &&
      //   response.category &&
      //   response.category === 'xhr' &&
      //   response.data &&
      //   response.data.status_code &&
      //   response.data.status_code === 401 || 404
      // ) {
      //   ignoreError = true;
      // } else {
      //   ignoreError = false;
      // }
      return response;
    },
    beforeSend: (event, hint) => {
      if (ignoreError) {
        return null;
      }
      return event;
    },
    // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
    // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
    // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
    integrations: [new Sentry.Integrations.TryCatch({
      XMLHttpRequest: false,
    })],
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  extractError(error) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }

  handleError(error) {
    const extractedError = this.extractError(error) || 'Handled unknown error';
    if (environment.sentryEnabled) {
      // Capture handled exception and send it to Sentry.
      const eventId = Sentry.captureException(extractedError);

      // When in development mode, log the error to console for immediate feedback.
      if (!environment.production) {
        console.error(extractedError);
      }

      // Optionally show user dialog to provide details on what happened.
      // Sentry.showReportDialog({ eventId });
    } else {
      console.error(extractedError);
    }
  }
}

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    // set configuration parameters here.
    basePath: environment.API_BASE_PATH !== '' ? environment.API_BASE_PATH : location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
  };
  return new Configuration(params);
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (environment.showMissingTranslations) {
      if (params.interpolateParams) {
        return params.interpolateParams['Default'] || params.key;
      }
      return params.key;
    } else {
      return '';
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    PopupFrameComponent,
    EmptyFrameComponent,
    // ExpoOverviewComponent,
    ExpoExhibitorSmallComponent,
    MenuComponent,
    FooterComponent,
    // ContactComponent,
    // TosComponent,
    // PrivacyComponent,
    NotFoundComponent,
    DownloadRegisterComponent,
    ChatRegisterComponent,
    EnterRegisterComponent,
    DefaultLayoutComponent,
    ExhibitorLayoutComponent,
    VideoChatComponent,
    // ExpoHallComponent,
    // ExpoGatewayComponent,
    LandingComponent,
    // PricingComponent,
    // AboutUsComponent,
    // RfqComponent,
    // TosB2bComponent,
    // CompanyDataSideComponent,
    JsonLDComponent,
    PasswordLessLoginComponent,
    PasswordLessConfirmComponent,
    ProfileComponent,
    ProfileEditSocialComponent,
    AccountHeaderComponent,
    AccountFooterComponent,
    AccountLayoutComponent,
    LoadingIndicatorComponent
    // Exhibitions2Component,
    // ExpoHall2Component
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationHelper,
      }
    }),
    ApiModule.forRoot(apiConfigFactory),
    NgbModule,
    SharedModule.forRoot(),
    NgxSpinnerModule,
    NgAddToCalendarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    ExpoCommonModule
  ],
  exports: [
    MenuComponent,
    LoadingIndicatorComponent
  ],
  providers: [
    CheckForUpdateService,
    PromptUpdateService,
    LogUpdateService,
    HttpUrlEncodingCodec,
    AuthInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {provide: BASE_PATH, useValue: environment.API_BASE_PATH},
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    {provide: APP_BASE_HREF, useValue: '/'}
  ],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  // constructor(
  //   private injector: Injector
  //   , private router: Router
  //   , private location: Location
  // ) {
  //   // const appElement = createCustomElement(AppComponent, {
  //   //   injector: this.injector
  //   // });
  //   //
  //   // // debugger;
  //   // // console.log('initializing custom element');
  //   // customElements.define('virtual-expo-element', appElement);
  //   //
  //   // let path = this.location.path(true);
  //   // if (path === '') {
  //   //   path = '/home';
  //   // }
  //   // // init router with starting path
  //   // this.router.navigateByUrl(path);
  //   //
  //   // // on every route change tell router to navigate to defined route
  //   // this.location.subscribe(data => {
  //   //   this.router.navigateByUrl(data.url);
  //   // });
  // }
  //
  // ngDoBootstrap() {
  // }
}
