import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-message',
  templateUrl: './popup-message.component.html',
  styleUrls: ['./popup-message.component.scss']
})
export class PopupMessageComponent {
  @Input() header: string;
  @Input() message: string;
  @Input() params: any;
  @Input() class: string;
  @Input() cancelText: string;
  @Input() okText: string;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  decline($event: MouseEvent) {
    $event.preventDefault();
    this.activeModal.close(false);
  }

  accept($event: MouseEvent) {
    $event.preventDefault();
    this.activeModal.close(true);
  }
}
