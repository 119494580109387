<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <a class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </a>
</div>
<div class="modal-body" [innerHTML]="message"></div>
<div class="modal-footer" style="justify-content: space-between;">
  <button type="button" class="btn" [ngClass]="{'btn-warning': warning, 'btn-danger': !warning }" (click)="decline()">{{ btnCancelText }}</button>
  <button type="button" class="btn" [ngClass]="{'btn-secondary': warning, 'btn-success': !warning }" (click)="accept()">{{ btnOkText }}</button>
</div>
