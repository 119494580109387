import {SwUpdate} from '@angular/service-worker';
import {PopupService} from '../popup.service';
import {Injectable} from '@angular/core';

@Injectable()
export class PromptUpdateService {

  constructor(
    updates: SwUpdate,
    private popupService: PopupService
  ) {
    updates.available.subscribe(event => {
      alert('A new version of the website is available, reloading');
      document.location.reload();
      // this.popupService.showInfo('New version', 'A new version of the website is available, reloading')
      //   .then(value => {
      //     updates.activateUpdate().then(() => document.location.reload());
      //   });
      // if (promptUser(event)) {
      //   updates.activateUpdate().then(() => document.location.reload());
      // }
    });
  }
}
