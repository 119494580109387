import {Component, OnInit} from '@angular/core';
import {ExpoBookedListDto, ExpoSimpleListItemDto} from '../../virtual-expo-api';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from '../../services/data.service';

const dayjs = require('dayjs');

@Component({
  selector: 'app-exhibitions2',
  templateUrl: './exhibitions2.component.html',
  styleUrls: ['./exhibitions2.component.scss']
})
export class Exhibitions2Component implements OnInit {
  expos: ExpoSimpleListItemDto[] = [];
  access: { [p: string]: ExpoBookedListDto } = {};

  currentExpos: Array<ExpoSimpleListItemDto> = new Array<ExpoSimpleListItemDto>();
  pastExpos: Array<ExpoSimpleListItemDto> = new Array<ExpoSimpleListItemDto>();
  futureExpos: Array<ExpoSimpleListItemDto> = new Array<ExpoSimpleListItemDto>();

  constructor(
    private dataService: DataService
    , private spinnerService: LoadingSpinnerService
    , private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.spinnerService.show();

    this.dataService.getExpoList()
      .then(value => {
        this.expos = value.expos;
        this.expos.forEach(expo => {
          if (this.isCurrent(expo)) {
            this.currentExpos.push(expo);
          } else if (this.isPast(expo)) {
            this.pastExpos.push(expo);
          } else if (this.isFuture(expo)) {
            this.futureExpos.push(expo);
          }
        });
        this.pastExpos.sort((a, b) =>
          a.dateEnd > b.dateEnd ? -1 : a.dateEnd < b.dateEnd ? 1 : 0
        );
        this.futureExpos.sort((a, b) =>
          a.dateEnd > b.dateEnd ? 1 : a.dateEnd < b.dateEnd ? -1 : 0
        );
        this.access = value.access;
        this.spinnerService.hide();
      });
    // if (this.authService.isLoggedIn()) {
    //   this.apiGateway.webGetExpoAccess()
    //     .subscribe(value => {
    //       this.access = value;
    //     }, error => {
    //       this.access = {};
    //     });
    // }
  }

  getIntro() {
    return this.translateService.instant('intro.chooseExhibition');
  }

  isCurrent(expo: ExpoSimpleListItemDto) {
    return !(this.isPast(expo) || this.isFuture(expo));
  }

  isPast(expo: ExpoSimpleListItemDto) {
    return dayjs(expo.dateEnd).toDate() < new Date();
  }

  isFuture(expo: ExpoSimpleListItemDto) {
    return dayjs(expo.dateStart).toDate() > new Date();
  }
}
