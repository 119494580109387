<div class="calendar-dialog">
  <div class="modal-header">
    <div style="--aspect-ratio: 16/9; width: 6rem; display: inline-block" *ngIf="expo">
      <div>
        <img [src]="expo.logoWideUrl" alt="Expo logo">
      </div>
    </div>
    <div style="line-height: 3rem; margin-left: 2rem;" class="modal-title">
      <span [translate]="'calendar.header'"></span>
      <span class="ml-5" *ngIf="view === 0">
        <a (click)="dayBack($event)" [ngClass]="{'hidden': dayList.indexOf(activeDay) === 0}"
           [title]="'calendar.dayBack' | translate"
        >
          <i class="fa fa-chevron-left"></i></a>
        <span [innerText]="getDate(activeDay)" class="calendar-date ml-2 mr-2"
              [title]="'calendar.selectDate' | translate"
              (click)="setListView($event)"></span>
        <a (click)="dayForward($event)" [ngClass]="{'hidden': dayList.indexOf(activeDay) === dayList.length - 1}"
           [title]="'calendar.dayForward' | translate"
        >
          <i class="fa fa-chevron-right"></i></a>
        </span>
    </div>
    <!--    <div class="header-buttons">-->
    <!--    </div>-->
    <a class="close" aria-label="Close" style="border: none;" (click)="activeModal.close()">
      <span aria-hidden="true" style="font-size: 1.5em">&times;</span>
    </a>
  </div>
  <div class="modal-body">
    <div class="container calendar">
      <div class="row" style="justify-content: space-between;">
        <div>
          <div class="btn btn-group-xs">
            <a (click)="setDayView($event)" class="btn"
               [ngClass]="{'btn-success btn-active': view === 0, 'btn-secondary': view === 1}"
               [translate]="'calendar.dayView'">Day view</a>
            <a (click)="setListView($event)" class="btn"
               [ngClass]="{'btn-success btn-active': view === 1, 'btn-secondary': view === 0}"
               [translate]="'calendar.listView'">List view</a>
          </div>
        </div>
        <div>
          <div class="btn btn-group-xs">
            <a (click)="allEvents($event)" class="btn"
               [ngClass]="{'btn-success btn-active': mode === 0, 'btn-secondary': mode === 1}"
               [translate]="'calendar.allEvents'">All events</a>
            <a (click)="myEvents($event)" class="btn"
               [ngClass]="{'btn-success btn-active': mode === 1, 'btn-secondary': mode === 0}"
               [translate]="'calendar.myEvents'">My events</a>
          </div>
        </div>
      </div>
      <div class="row flex" *ngIf="view === 0">
        <div class="col-12 col-md-4 stretch">
          <div class="item-col">
            <ul class="date-selector">
              <ng-container *ngIf="dayViewStage[activeDay]">
                <ng-container *ngFor="let item of dayViewStage[activeDay] | keyvalue">
                  <li (click)="selectStage($event, item.key)" [title]="'calendar.clickToSelect' | translate"
                      *ngIf="idMapping[item.key]"
                      [ngClass]="{'active': activeStage === item.key}" class="calendar-selector">
                    {{ idMapping[item.key] }}
                    <i class="fa fa-chevron-right"></i>
                  </li>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="dayViewBooth[activeDay]">
                <ng-container *ngFor="let item of dayViewBooth[activeDay] | keyvalue">
                  <li (click)="selectExhibitor($event, item.key)" [title]="'calendar.clickToSelect' | translate"
                      *ngIf="idMapping[item.key]"
                      [ngClass]="{'active': activeBooth === item.key}" class="calendar-selector">
                    {{ idMapping[item.key] }}
                    <i class="fa fa-chevron-right"></i>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-8 stretch">
          <div class="date-col container">
            <ng-container *ngIf="dayViewStage && dayViewStage[activeDay] && dayViewStage[activeDay][activeStage]">
              <div class="row flex date-item" *ngFor="let item of dayViewStage[activeDay][activeStage]">
                <div class="col-2 time stretch">
                  <div class="inner">
                    <div class="label">
                      <i class="fas fa-star" *ngIf="isSelected(item)" style="cursor: pointer;"
                         [title]="'calendar.bookmarkRemove' | translate"
                         (click)="selectItem($event, item)"></i>
                      <i class="far fa-star" *ngIf="!isSelected(item)" style="cursor: pointer;"
                         [title]="'calendar.bookmark' | translate"
                         (click)="selectItem($event, item)"></i>
                      <span *ngIf="activeDay !== '_global'">{{ formatTime(item.start) }}</span>
                    </div>
                    <span [translate]="'global.presentationDuration'" *ngIf="activeDay !== '_global'"
                          [translateParams]="{'duration': item.duration}"></span>
                  </div>
                </div>
                <div class="col-8 description stretch">
                  <div class="inner">
                    <span class="label">{{ item.label }}</span><br/>
                    <div class="item-description">
                      <div [innerHTML]="item.description"></div>
                    </div>
                    <div class="download" *ngIf="!isDatePast(item) && activeDay !== '_global'">
                      {{ 'calendar.download.label' | translate }}
                      <div class="btn-group btn-group-xs">
                        <a [href]="item.linkIcal" title="{{ 'calendar.download.ical' | translate }}" target="_blank"
                           (click)="trackCalendarAdd($event)"
                        >
                          <button class="btn btn-outline-info">
                            ICal
                          </button>
                        </a>
                        <a [href]="item.linkGoogle" title="{{ 'calendar.download.ics' | translate }}" target="_blank"
                           (click)="trackCalendarAdd($event)"
                        >
                          <button class="btn btn-outline-info">
                            Google
                          </button>
                        </a>
                        <a [href]="item.linkOutlook" title="{{ 'calendar.download.outlook' | translate }}"
                           (click)="trackCalendarAdd($event)" target="_blank">
                          <button class="btn btn-outline-info">
                            Outlook
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 action stretch">
                  <div class="inner">
                    <a (click)="openStage($event, item)" class="btn btn-xs btn-primary"
                       [translate]="'global.toStage'"></a>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="dayViewBooth && dayViewBooth[activeDay] && dayViewBooth[activeDay][activeBooth]">
              <div class="row flex date-item" *ngFor="let item of dayViewBooth[activeDay][activeBooth]">
                <div class="col-2 time stretch">
                  <div class="inner">
                    <div class="label">
                      <i class="fas fa-star" *ngIf="isSelectedExhibitor(item)" style="cursor: pointer;"
                         [title]="'calendar.bookmarkRemove' | translate"
                         (click)="selectItemExhibitor($event, item)"></i>
                      <i class="far fa-star" *ngIf="!isSelectedExhibitor(item)" style="cursor: pointer;"
                         [title]="'calendar.bookmark' | translate"
                         (click)="selectItemExhibitor($event, item)"></i>
                      <span *ngIf="activeDay !== '_global'">{{ formatTime(item.start) }}</span>
                    </div>
                    <span [translate]="'global.presentationDuration'" *ngIf="activeDay !== '_global'"
                          [translateParams]="{'duration': item.duration}"></span>
                  </div>
                </div>
                <div class="col-8 description stretch">
                  <div class="inner">
                    <span class="exhibitor">{{ item.exhibitorName }}</span><br/>
                    <span class="label">{{ item.label }}</span><br/>
                    <div [innerHTML]="item.description"></div>
                    <div class="download" *ngIf="!isDatePast(item) && activeDay !== '_global'">
                      {{ 'calendar.download.label' | translate }}
                      <div class="btn-group btn-group-xs">
                        <a [href]="item.linkIcal" title="{{ 'calendar.download.ical' | translate }}" target="_blank"
                           (click)="trackCalendarAdd($event)"
                        >
                          <button class="btn btn-outline-info">
                            ICal
                          </button>
                        </a>
                        <a [href]="item.linkGoogle" title="{{ 'calendar.download.ics' | translate }}" target="_blank"
                           (click)="trackCalendarAdd($event)"
                        >
                          <button class="btn btn-outline-info">
                            Google
                          </button>
                        </a>
                        <a [href]="item.linkOutlook" title="{{ 'calendar.download.outlook' | translate }}"
                           (click)="trackCalendarAdd($event)" target="_blank">
                          <button class="btn btn-outline-info">
                            Outlook
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 action stretch">
                  <div class="inner">
                    <a (click)="openExhibitor($event, item)" class="btn btn-xs primary"
                       [translate]="'global.toExhibitor'"></a>
                  </div>
                </div>
              </div>
            </ng-container>
            <div
              *ngIf="(!dayViewStage || !dayViewStage[activeDay] || !dayViewStage[activeDay][activeStage]) && (!dayViewBooth || !dayViewBooth[activeDay] || !dayViewBooth[activeDay][activeBooth])"
              [translate]="'calendar.noBookmarks'">
            </div>
          </div>
        </div>
      </div>
      <div class="row flex" *ngIf="view === 1">
        <div class="col-12 col-md-4 stretch">
          <div class="item-col">
            <ul class="date-selector">
              <li (click)="selectDate($event, '_global')" *ngIf="expo.groupedCalendar['_global']"
                  class="calendar-selector"
                  [ngClass]="{'active': activeDay === '_global'}" [title]="'calendar.clickToSelect' | translate">
                {{ 'calendar.noDate' | translate }}
                <i class="fa fa-chevron-right"></i>
              </li>
              <ng-container *ngFor="let item of dayList">
                <li (click)="selectDate($event, item)" *ngIf="item !== '_global'" class="calendar-selector"
                    [ngClass]="{'active': activeDay === item}" [title]="'calendar.clickToSelect' | translate">
                  {{ getDate(item) }}
                  <i class="fa fa-chevron-right"></i>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-8 stretch">
          <div class="date-col container">
            <div class="row flex date-item" *ngFor="let item of currentDay">
              <div class="col-2 time stretch">
                <div class="inner">
                  <div class="label">
                    <i class="fas fa-star" *ngIf="isSelected(item)" style="cursor: pointer;"
                       [title]="'calendar.bookmarkRemove' | translate"
                       (click)="selectItem($event, item)"></i>
                    <i class="far fa-star" *ngIf="!isSelected(item)" style="cursor: pointer;"
                       [title]="'calendar.bookmark' | translate"
                       (click)="selectItem($event, item)"></i>
                    <span *ngIf="activeDay !== '_global'">{{ formatTime(item.start) }}</span>
                  </div>
                  <span [translate]="'global.presentationDuration'" *ngIf="activeDay !== '_global'"
                        [translateParams]="{'duration': item.duration}"></span>
                </div>
              </div>
              <div class="col-8 description stretch">
                <div class="inner">
                  <span class="label">{{ item.label }}</span><br/>
                  <div class="item-description">
                    <div [innerHTML]="item.description"></div>
                  </div>
                  <div class="download" *ngIf="!isDatePast(item) && activeDay !== '_global'">
                    {{ 'calendar.download.label' | translate }}
                    <div class="btn-group btn-group-xs">
                      <a [href]="item.linkIcal" title="{{ 'calendar.download.ical' | translate }}" target="_blank"
                         (click)="trackCalendarAdd($event)"
                      >
                        <button class="btn btn-outline-info">
                          ICal
                        </button>
                      </a>
                      <a [href]="item.linkGoogle" title="{{ 'calendar.download.ics' | translate }}" target="_blank"
                         (click)="trackCalendarAdd($event)"
                      >
                        <button class="btn btn-outline-info">
                          Google
                        </button>
                      </a>
                      <a [href]="item.linkOutlook" title="{{ 'calendar.download.outlook' | translate }}"
                         (click)="trackCalendarAdd($event)" target="_blank">
                        <button class="btn btn-outline-info">
                          Outlook
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2 action stretch">
                <div class="inner">
                  <a (click)="openStage($event, item)" class="btn btn-xs btn-primary"
                     [translate]="'global.toStage'"></a>
                </div>
              </div>
            </div>
            <div class="row flex date-item" *ngFor="let item of currentPresentations">
              <div class="col-2 time stretch">
                <div class="inner">
                  <div class="label">
                    <i class="fas fa-star" *ngIf="isSelectedExhibitor(item)" style="cursor: pointer;"
                       [title]="'calendar.bookmarkRemove' | translate"
                       (click)="selectItemExhibitor($event, item)"></i>
                    <i class="far fa-star" *ngIf="!isSelectedExhibitor(item)" style="cursor: pointer;"
                       [title]="'calendar.bookmark' | translate"
                       (click)="selectItemExhibitor($event, item)"></i>
                    <span *ngIf="activeDay !== '_global'">{{ formatTime(item.start) }}</span>
                  </div>
                  <span [translate]="'global.presentationDuration'" *ngIf="activeDay !== '_global'"
                        [translateParams]="{'duration': item.duration}"></span>
                </div>
              </div>
              <div class="col-8 description stretch">
                <div class="inner">
                  <span class="exhibitor">{{ item.exhibitorName }}</span><br/>
                  <span class="label">{{ item.label }}</span><br/>
                  <div [innerHTML]="item.description"></div>
                  <div class="download" *ngIf="!isDatePast(item) && activeDay !== '_global'">
                    {{ 'calendar.download.label' | translate }}
                    <div class="btn-group btn-group-xs">
                      <a [href]="item.linkIcal" title="{{ 'calendar.download.ical' | translate }}" target="_blank"
                         (click)="trackCalendarAdd($event)"
                      >
                        <button class="btn btn-outline-info">
                          ICal
                        </button>
                      </a>
                      <a [href]="item.linkGoogle" title="{{ 'calendar.download.ics' | translate }}" target="_blank"
                         (click)="trackCalendarAdd($event)"
                      >
                        <button class="btn btn-outline-info">
                          Google
                        </button>
                      </a>
                      <a [href]="item.linkOutlook" title="{{ 'calendar.download.outlook' | translate }}"
                         (click)="trackCalendarAdd($event)" target="_blank">
                        <button class="btn btn-outline-info">
                          Outlook
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2 action stretch">
                <div class="inner">
                  <a (click)="openExhibitor($event, item)" class="btn btn-xs btn-primary"
                     [translate]="'global.toExhibitor'"></a>
                </div>
              </div>
            </div>
            <div
              *ngIf="(!currentDay || currentDay.length === 0) && (!currentPresentations || currentPresentations.length === 0)"
              [translate]="'calendar.noBookmarks'">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
