import {EventEmitter, Injectable, Injector} from '@angular/core';
import {
  AgoraTokenResultDto,
  AvailableBookingResult,
  BoockingCheckDto,
  BookingResultState,
  BookSlotDto,
  BoothConfigurationDto,
  BoothDetailItemDto, CalendarEntryBoothDto, CalendarEntryBoothViewDto,
  CalendarEntrySessionViewDto,
  CalendarEntryStageDto, CallDetails,
  ExpoBookedListDto,
  ExpoDesignDto,
  ExpoExhibitorListItemDto, ExpoListItemDto,
  ExpoSimpleListItemDto,
  ExpoStageEventDto,
  LoginResultDto,
  MessageExhibitorDto, PwdLessCallbackDto, PwdLessLoginDto, SessionPassword,
  SignInDto, StageDto, TrackDownloadDto,
  VHost,
  VisitorProfileDetailUpdateDto,
  VisitorProfileDto,
  WebService, YoutubeChannel
} from '../virtual-expo-api';

import {ExpoOverviewExtended} from '../objects/expo-overview-extended';
import {ExpoDesignService} from './expo-design.service';
import {DesignDefault} from '../objects/expo-design';
import {ColorHelperService} from '../components/booths/color-helper.service';
import {ImageUrlService} from '../components/booths/image-url.service';
import {MarkdownService} from 'ngx-markdown';
import {StorageService} from './storage.service';
import {TitleTagService} from './title-tag.service';
import {ExhibitorExtended, ExhibitorGroupedExtended} from '../objects/exhibitor-extended';
import {LinkHandlerService} from './link-handler.service';
import {SignInService} from './sign-in.service';
import {Router} from '@angular/router';
import {LoadingSpinnerService} from './loading-spinner.service';
import {CalendarEntryStageDtoExtended} from '../objects/calendar-entry-stage-dto-extended';
import {ICalendarEvent} from '../modules/ngAddToCalendar/model/calendar-event.model';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {CalendarTypeEnum} from '../modules/ngAddToCalendar/model/calendar-type.enum';
import {NgAddToCalendarService} from '../modules/ngAddToCalendar/service/ng-add-to-calendar.service';
import {SignInData} from './sign-in-data';
import {from, Observable, throwError} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {AlertService} from './alert.service';
import {SystemNotificationService} from './system-notification.service';
import {StageDtoExtended} from '../objects/stage-dto-extended';
import {CalendarEntryBoothDtoExtended} from '../objects/calendar-entry-booth-dto-extended';

const dayjs = require('dayjs');

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public currentExpo: ExpoOverviewExtended;

  public expoChanged: EventEmitter<ExpoOverviewExtended> = new EventEmitter<ExpoOverviewExtended>();

  public expoReload: EventEmitter<string> = new EventEmitter<string>();
  public expoDesignChanged: EventEmitter<{ expoId: string, design: ExpoDesignDto }>
    = new EventEmitter<{ expoId: string, design: ExpoDesignDto }>();
  public exhibitorDesignChanged: EventEmitter<{ expoId: string, exhibitorId: string, design: BoothConfigurationDto }>
    = new EventEmitter<{ expoId: string, exhibitorId: string, design: BoothConfigurationDto }>();

  private cache: { [key: string]: any } = {};
  private expoMap: { [key: string]: string } = {};
  private stageMap: { [key: string]: string } = {};
  private exhibitorMap: { [key: string]: string } = {};

  constructor(
    private webService: WebService
    , private colorHelper: ColorHelperService
    , private imageUrlHelper: ImageUrlService
    , private markdownService: MarkdownService
    , private titleTagService: TitleTagService
    , private linkHandlerService: LinkHandlerService
    , private router: Router
    , private spinnerService: LoadingSpinnerService
    , private sanitizer: DomSanitizer
    , private addToCalendarService: NgAddToCalendarService
    , private systemNotificationService: SystemNotificationService
    , public signInService: SignInService
  ) {
    systemNotificationService.updateStageCalendar
      .subscribe((stageUpdate: CalendarEntryStageDto) => {
        const cacheKey = `stage_${stageUpdate.stageId}`;
        if (this.cache[cacheKey]) {
          const stage: ExpoStageEventDto = this.cache[cacheKey];
          stage.current = stageUpdate;
          stage.stage.current = stageUpdate.id;
        }
      });
    systemNotificationService.clear
      .subscribe((stageId: string) => {
        const cacheKey = `stage_${stageId}`;
        if (this.cache[cacheKey]) {
          const stage: ExpoStageEventDto = this.cache[cacheKey];
          stage.current = null;
          stage.stage.current = 0;
        }
      });
    systemNotificationService.updateExhibitorCalendar
      .subscribe((update: CalendarEntryBoothDto) => {
        const cacheKey = `exhibitor_${update.exhibitorId}`;
        if (this.cache[cacheKey]) {
          const exhibitor: ExhibitorExtended = this.cache[cacheKey];
          exhibitor.currentCalendarEntry = update;
          exhibitor.current = update.id;
        }
      });
    systemNotificationService.clearExhibitor
      .subscribe((exhibitorId: string) => {
        const cacheKey = `exhibitor_${exhibitorId}`;
        if (this.cache[cacheKey]) {
          const exhibitor: ExhibitorExtended = this.cache[cacheKey];
          exhibitor.currentCalendarEntry = null;
          exhibitor.current = 0;
        }
      });
    systemNotificationService.updateExpo
      .subscribe((expoId: string) => {
        const cacheKey = `expo_${expoId}`;
        if (this.cache[cacheKey]) {
          this.loadExpo(this.cache[cacheKey].shortKey, true)
            .then(() => {
              this.expoReload.emit(expoId);
            });
        }
      });
  }

  public setDefaultTheme() {
    const defaultDesign = new DesignDefault();
    const result: { [key: string]: string } = {};

    result[`expo-font-size`] = '1.2vw';
    result[`expo-intro-font-size`] = '1.2vw';

    this.addColorGroup(result, defaultDesign.font_color, 'font');
    this.addColorGroup(result, defaultDesign.title_color, 'font');
    this.addColorGroup(result, defaultDesign.background_color, 'background');
    this.addColorGroup(result, defaultDesign.modal_background_color, 'modal-background');
    this.addColorGroup(result, defaultDesign.box_background_color, 'box-background');
    this.addColorGroup(result, defaultDesign.box_font_color, 'box-font');
    this.addColorGroup(result, defaultDesign.button_background_color, 'button-background');
    this.addColorGroup(result, defaultDesign.button_font_color, 'button-font');

    document.documentElement.style.setProperty(`--expo-font-size`, 'unset');

    this.setTheme(result);
  }

  public getExpo(shortKey: string): Promise<ExpoOverviewExtended> {
    return new Promise<ExpoOverviewExtended>((resolve, reject) => {
      this.spinnerService.show();
      this.loadExpo(shortKey, false)
        .then(expo => {
          const lastExpoId = this.currentExpo?.id;

          this.currentExpo = expo;
          this.systemNotificationService.activeExpo = expo;

          this.setTheme(expo.theme);
          this.titleTagService.setExpoTags(expo);
          this.queryRefresh();

          if (lastExpoId !== expo.id) {
            this.expoChanged.emit(expo);
          }

          this.currentExpo.isPreviewActive = this.currentExpo.isPreview && !this.imageUrlHelper.noCache;

          this.spinnerService.hide();
          resolve(expo);
        }, reason => {
          this.spinnerService.hide();
          reject(reason);
        });
    });
  }

  private loadExpo(shortKey: string, noCache: boolean): Promise<ExpoOverviewExtended> {
    return new Promise<ExpoOverviewExtended>((resolve, reject) => {
      if (!this.expoMap[shortKey] || noCache) {
        this.webService.webGetExpoOverview(shortKey, this.imageUrlHelper.noCache || noCache).subscribe(expoDto => {
          const data = expoDto as ExpoOverviewExtended;

          data.stageDict = {};

          this.processCalendar(data);
          this.processDesign(data);
          this.processExhibitors(data);
          this.processStages(data);

          data.logoSquareUrl = this.imageUrlHelper.getLogo(data);
          data.logoWideUrl = this.imageUrlHelper.getLogoWide(data);

          data.textIntro = this.markdownService.compile(data.intro);
          data.textDesc = this.markdownService.compile(data.descriptionLong);
          data.backgroundImage = this.imageUrlHelper.getImage(data.expoDesign.backgroundImage);
          data.introImage = this.imageUrlHelper.getImage(data.expoDesign.expo_intro_image);
          data.descriptionImage = this.imageUrlHelper.getImage(data.expoDesign.expo_desc_image);
          if (data.backgroundImage && data.backgroundImage.trim() !== '') {
            data.backgroundImageUrl = this.imageUrlHelper.getImage(data.backgroundImage);
          }

          data.routerLink = this.linkHandlerService.getExpoUrl(data);

          data.isPast = dayjs(data.dateEnd).toDate() < new Date();
          data.isFuture = dayjs(data.dateStart).toDate() > new Date();

          this.expoMap[shortKey] = data.id;
          const cacheKey = `expo_${data.id}`;
          this.cache[cacheKey] = data;
          resolve(data);
        }, error => {
          reject(error);
        });
      } else {
        const cacheKey = `expo_${this.expoMap[shortKey]}`;
        resolve(this.cache[cacheKey]);
      }
    });
  }

  public updateExpoDesign(expoId: string, design: ExpoDesignDto, expo: any) {
    const cacheKey = `expo_${expoId}`;
    if (this.cache[cacheKey]) {
      this.processDesign(this.cache[cacheKey], design, expo);

      this.cache[cacheKey].backgroundImage = this.imageUrlHelper.getImage(design.backgroundImage);
      this.cache[cacheKey].introImage = this.imageUrlHelper.getImage(design.expo_intro_image);
      this.cache[cacheKey].descriptionImage = this.imageUrlHelper.getImage(design.expo_desc_image);

      this.cache[cacheKey].logoSquareUrl = this.imageUrlHelper.getLogo(this.cache[cacheKey]);
      this.cache[cacheKey].logoWideUrl = this.imageUrlHelper.getLogoWide(this.cache[cacheKey]);

      this.cache[cacheKey].textIntro = this.markdownService.compile(this.cache[cacheKey].intro);
      this.cache[cacheKey].textDesc = this.markdownService.compile(this.cache[cacheKey].descriptionLong);

      if (this.cache[cacheKey].backgroundImage && this.cache[cacheKey].backgroundImage.trim() !== '') {
        this.cache[cacheKey].backgroundImageUrl = this.imageUrlHelper.getImage(this.cache[cacheKey].backgroundImage);
      }

      if (!expo.expoDesign.defaultFontSize) {
        this.cache[cacheKey].expoDesign.defaultFontSize = 18;
      }

      if (!expo.expoDesign.introFontSize) {
        this.cache[cacheKey].expoDesign.introFontSize = 18;
      }

      const defaultFontSize = (expo.expoDesign.defaultFontSize / 18).toString() + 'vw';
      this.cache[cacheKey].theme[`expo-font-size`] = defaultFontSize;

      const introFontSize = (expo.expoDesign.introFontSize / 18).toString() + 'vw';
      this.cache[cacheKey].theme[`expo-intro-font-size`] = introFontSize;

      this.expoDesignChanged.emit({expoId: expoId, design: design});
    }
  }

  public getStage(expoShortKey: string, stageShortKey: string, entryId: number):
    Promise<{ expo: ExpoOverviewExtended, stage: ExpoStageEventDto }> {
    return new Promise<{ expo: ExpoOverviewExtended, stage: ExpoStageEventDto }>((resolve, reject) => {
      this.spinnerService.show();
      this.getExpo(expoShortKey)
        .then(expo => {
          if (expo.accessMode !== 10 || this.imageUrlHelper.noCache) {
            this.signInService.getAnonToken(expo)
              .then(value1 => {
                this.loadStage(expoShortKey, stageShortKey, entryId)
                  .then(stage => {
                    this.spinnerService.hide();
                    resolve({expo, stage});
                  }, reason => {
                    this.spinnerService.hide();
                    reject(reason);
                  });
              }, reason => {
                this.spinnerService.hide();
                this.router.navigate(['/']);
              });
          } else {
            this.signInService.getEntryToken()
              .then(value1 => {
                this.loadStage(expoShortKey, stageShortKey, entryId)
                  .then(stage => {
                    this.spinnerService.hide();
                    resolve({expo, stage});
                  }, reason => {
                    this.spinnerService.hide();
                    reject(reason);
                  });
              }, reason => {
                this.spinnerService.hide();
                this.router.navigate(['/']);
              });
          }
        });
    });
  }

  private loadStage(expoShortKey: string, stageShortKey: string, entryId: number): Promise<ExpoStageEventDto> {
    return new Promise<ExpoStageEventDto>((resolve, reject) => {
      if (!this.stageMap[stageShortKey]) {
        this.webService.webGetStage2(expoShortKey, stageShortKey, entryId.toString(), this.imageUrlHelper.noCache)
          .subscribe(stageDto => {
            const data = stageDto as ExpoStageEventDto;

            this.stageMap[stageShortKey] = data.stage.id;
            const cacheKey = `stage_${data.stage.id}`;
            this.cache[cacheKey] = data;
            resolve(data);
          }, error => {
            reject(error);
          });
      } else {
        const cacheKey = `stage_${this.stageMap[stageShortKey]}`;
        resolve(this.cache[cacheKey]);
      }
    });
  }

  public getExhibitor(expoShortKey: string, exhibitorShortKey: string):
    Promise<{ expo: ExpoOverviewExtended, exhibitor: ExhibitorExtended }> {
    return new Promise<{ expo: ExpoOverviewExtended, exhibitor: ExhibitorExtended }>((resolve, reject) => {
      this.spinnerService.show();
      this.getExpo(expoShortKey)
        .then(expo => {
          if (expo.accessMode !== 10 || this.imageUrlHelper.noCache) {
            this.signInService.getAnonToken(expo)
              .then(value1 => {
                this.loadExhibitor(expo, exhibitorShortKey)
                  .then(exhibitor => {
                    this.titleTagService.setExpoExhibitorTags(expo, exhibitor);
                    this.queryRefresh();

                    this.spinnerService.hide();
                    resolve({expo, exhibitor});
                  }, reason => {
                    this.spinnerService.hide();
                    reject(reason);
                  });
              }, reason => {
                this.spinnerService.hide();
                this.router.navigate(['/']);
              });
          } else {
            this.signInService.getEntryToken()
              .then(value1 => {
                this.loadExhibitor(expo, exhibitorShortKey)
                  .then(exhibitor => {
                    this.titleTagService.setExpoExhibitorTags(expo, exhibitor);
                    this.queryRefresh();

                    this.spinnerService.hide();
                    resolve({expo, exhibitor});
                  }, reason => {
                    this.spinnerService.hide();
                    this.linkHandlerService.navigateExpoLocked(expo);
                  });
              }, reason => {
                this.spinnerService.hide();
                this.router.navigate(['/']);
              });
          }
        }, reason => {
          this.router.navigate(['/']);
        });
    });
  }

  private loadExhibitor(expo: ExpoOverviewExtended, exhibitorShortKey: string): Promise<ExhibitorExtended> {
    return new Promise<ExhibitorExtended>((resolve, reject) => {
      if (!this.exhibitorMap[exhibitorShortKey]) {
        this.webService.webGetExhibitor(expo.shortKey, exhibitorShortKey, this.imageUrlHelper.noCache)
          .subscribe((exhibitorDto: ExpoExhibitorListItemDto) => {
            const data = exhibitorDto as ExhibitorExtended;
            data.displays.sort((a, b) => a.sequence - b.sequence);
            data.links.sort((a, b) => a.sequence - b.sequence);
            data.contacts.sort((a, b) => a.sequence - b.sequence);

            data.logoSquareUrl = this.imageUrlHelper.getLogo(data);
            data.logoWideUrl = this.imageUrlHelper.getLogoWide(data);
            data.avatarUrl = this.imageUrlHelper.getAvatar(data);
            if (data.backgroundImage && data.backgroundImage.trim() !== '') {
              data.backgroundImageUrl = this.imageUrlHelper.getImage(data.backgroundImage);
            }
            if (data.boothConfiguration && data.boothConfiguration.bannerImage && data.boothConfiguration.bannerImage.trim() !== '') {
              data.bannerUrl = this.imageUrlHelper.getImage(data.boothConfiguration.bannerImage);
            }

            data.socialNetworks = new Array<{ network: string; id: string }>();
            Object.keys(data.socialProfiles).forEach(value => {
              data.socialNetworks.push({network: value, id: data.socialProfiles[value]});
            });
            data.filterType = -1;

            data.hasVideo = false;
            data.hasPdf = false;
            data.hasImage = false;
            data.hasPoster =
              (data.posterA && data.posterA.trim() !== '')
              || (data.posterB && data.posterB.trim() !== '')
            ;
            data.hasStage = data.stage && (data.stage.current || data.stage.vodType > 0) ? true : false;
            data.hasLinks = (data.links && data.links.length > 0)
              || (data.website && data.website.trim() !== '')
              ? true : false
            ;

            data.posterAUrl = this.imageUrlHelper.getImage(data.posterA);
            data.posterBUrl = this.imageUrlHelper.getImage(data.posterB);


            if (data.displays) {
              data.displays.forEach((value) => {
                if (value.displayType === 0 || value.displayType === 11) {
                  data.hasImage = true;
                } else if (value.displayType === 10) {
                  data.hasPdf = true;
                } else if (value.displayType === 1 || value.displayType === 2 || value.displayType === 3) {
                  data.hasVideo = true;
                }
              });
            }
            data.descLong = this.markdownService.compile(data.descriptionLong);
            data.routerLink = this.linkHandlerService.getExhibitorUrl(expo, data);

            for (let i = 1; i < data.boothConfiguration.colors.length; i++) {
              if (!data.boothConfiguration.colors[i]) {
                data.boothConfiguration.colors[i] = '#ffffff';
              }
            }

            data.nextCalendarEntries = new Array<CalendarEntryBoothDtoExtended>();
            const upcoming = new Array<CalendarEntryBoothDtoExtended>();
            const previous = new Array<CalendarEntryBoothDtoExtended>();
            const tzStamp = dayjs();
            const dayLabel: string = tzStamp.format('YYYY-MM-DD');

            data.calendarEntries.forEach(entry => {
              const newEntry = entry as CalendarEntryBoothDtoExtended;
              newEntry.startDate = dayjs(entry.start).toDate();

              const entryStamp = dayjs(entry.start).format('YYYY-MM-DD');
              if (entryStamp === dayLabel) {
                data.nextCalendarEntries.push(newEntry);
              } else if (entryStamp > dayLabel) {
                upcoming.push(newEntry);
              } else {
                previous.push(newEntry);
              }
            });
            if (data.nextCalendarEntries.length === 0) {
              data.nextCalendarEntries = upcoming;
              // if (data.nextCalendarEntries.length > 5) {
              //   data.nextCalendarEntries.length = 5;
              // }
            }
            if (data.nextCalendarEntries.length === 0) {
              data.nextCalendarEntries = previous;
              // if (data.nextCalendarEntries.length > 5) {
              //   data.nextCalendarEntries.splice(0, data.nextCalendarEntries.length - 5);
              // }
            }

            if (data.nextCalendarEntries) {
              data.nextCalendarEntries.sort((a, b) => {
                return a.start > b.start ? 1 : a.start < b.start ? -1 : 0;
              });
              data.nextCalendarEntries.forEach(entry => {
                entry.linkGoogle = this.getDownloadGoogleExhibitor(expo, entry);
                entry.linkIcal = this.getDownloadIcalExhibitor(expo, entry);
                entry.linkOutlook = this.getDownloadOutlookExhibitor(expo, entry);
                entry.description = this.markdownService.compile(entry.description);
              });
            }

            this.exhibitorMap[exhibitorShortKey] = data.id;
            const cacheKey = `exhibitor_${data.id}`;
            this.cache[cacheKey] = data;
            resolve(data);
          }, error => {
            reject(error);
          });
      } else {
        const cacheKey = `exhibitor_${this.exhibitorMap[exhibitorShortKey]}`;
        resolve(this.cache[cacheKey]);
      }
    });
  }

  public updateExhibitorDesign(expoId: string, exhibitorId: string, design: BoothDetailItemDto) {
    const cacheKey = `exhibitor_${exhibitorId}`;
    if (this.cache[cacheKey]) {
      const exhibitor: ExhibitorExtended = this.cache[cacheKey];
      delete design.contacts;
      Object.assign(this.cache[cacheKey], design);
      this.cache[cacheKey].socialNetworks = design.socialProfiles;
      exhibitor.descLong = this.markdownService.compile(exhibitor.descriptionLong);

      exhibitor.logoSquareUrl = this.imageUrlHelper.getLogo(exhibitor);
      exhibitor.logoWideUrl = this.imageUrlHelper.getLogoWide(exhibitor);
      exhibitor.avatarUrl = this.imageUrlHelper.getAvatar(exhibitor);
      if (exhibitor.backgroundImage && exhibitor.backgroundImage.trim() !== '') {
        exhibitor.backgroundImageUrl = this.imageUrlHelper.getImage(exhibitor.backgroundImage);
      }
      if (exhibitor.boothConfiguration && exhibitor.boothConfiguration.bannerImage
        && exhibitor.boothConfiguration.bannerImage.trim() !== '') {
        exhibitor.bannerUrl = this.imageUrlHelper.getImage(exhibitor.boothConfiguration.bannerImage);
      }

      exhibitor.socialNetworks = new Array<{ network: string; id: string }>();
      Object.keys(exhibitor.socialProfiles).forEach(value => {
        exhibitor.socialNetworks.push({network: value, id: exhibitor.socialProfiles[value]});
      });

      exhibitor.boothConfiguration = design.boothConfiguration;
      exhibitor.avatar = design.avatar !== '' && design.avatar !== 'DELETED' ? design.avatar : null;
      exhibitor.avatarUrl = this.imageUrlHelper.getAvatar(exhibitor);
      exhibitor.lecternVisibility = design.lecternVisibility;
      exhibitor.hideAvatar = design.hideAvatar;
      exhibitor.hideFurniture = design.hideFurniture;
      exhibitor.hideLogo = design.hideLogo;
      exhibitor.hideWalls = design.hideWalls;

      exhibitor.posterA = design.posterA !== 'DELETED' ? design.posterA : null;
      exhibitor.posterB = design.posterB !== 'DELETED' ? design.posterB : null;
      exhibitor.hasPoster =
        (exhibitor.posterA && exhibitor.posterA.trim() !== '')
        || (exhibitor.posterB && exhibitor.posterB.trim() !== '')
      ;
      exhibitor.hasStage = exhibitor.stage && (exhibitor.stage.current || exhibitor.stage.vodType > 0) ? true : false;
      exhibitor.hasLinks = (exhibitor.links && exhibitor.links.length > 0)
      || (exhibitor.website && exhibitor.website.trim() !== '')
        ? true : false
      ;

      exhibitor.posterAUrl = this.imageUrlHelper.getImage(exhibitor.posterA);
      exhibitor.posterBUrl = this.imageUrlHelper.getImage(exhibitor.posterB);

      exhibitor.backgroundImage = design.backgroundImage !== '' && design.backgroundImage !== 'DELETED' ? design.backgroundImage : null;
      if (exhibitor.backgroundImage && exhibitor.backgroundImage.trim() !== '') {
        exhibitor.backgroundImageUrl = this.imageUrlHelper.getImage(exhibitor.backgroundImage);
      }
      if (exhibitor.boothConfiguration && exhibitor.boothConfiguration.bannerImage
        && exhibitor.boothConfiguration.bannerImage.trim() !== '' && exhibitor.boothConfiguration.bannerImage.trim() !== 'DELETED') {
        exhibitor.bannerUrl = this.imageUrlHelper.getImage(exhibitor.boothConfiguration.bannerImage);
      } else {
        exhibitor.bannerUrl = '';
      }
      this.exhibitorDesignChanged.emit({
        expoId: expoId,
        exhibitorId: exhibitorId,
        design: exhibitor.boothConfiguration
      });
    }
  }

  private processCalendar(expo: ExpoOverviewExtended) {
    expo.groupedCalendar = {};
    expo.listCalendar = {};
    // expo.groupedSessions = {};
    expo.groupedPresentations = {};
    expo.allDays = new Array<string>();

    expo.calendar.forEach(calendarEntryStageDto => {
      const tzStamp = dayjs(calendarEntryStageDto.start);
      let dayLabel: string = tzStamp.format('YYYY-MM-DD').toString();

      expo.stageDict = {};
      expo.stages.forEach(value1 => {
        expo.stageDict[value1.id] = value1;
      });

      const stageName: string = expo.stageDict[calendarEntryStageDto.stageId].name;

      if (calendarEntryStageDto.dateMode === 1) {
        dayLabel = '_global';
      } else if (expo.allDays.indexOf(dayLabel) === -1) {
        expo.allDays.push(dayLabel);
      }

      if (!expo.groupedCalendar[dayLabel]) {
        expo.groupedCalendar[dayLabel] = {};
      }
      if (!expo.groupedCalendar[dayLabel][stageName]) {
        expo.groupedCalendar[dayLabel][stageName] = new Array<CalendarEntryStageDtoExtended>();
      }
      if (!expo.listCalendar[dayLabel]) {
        expo.listCalendar[dayLabel] = new Array<CalendarEntryStageDtoExtended>();
      }

      const newItem: CalendarEntryStageDtoExtended = calendarEntryStageDto as CalendarEntryStageDtoExtended;
      newItem.linkGoogle = this.getDownloadGoogle(expo, newItem);
      newItem.linkIcal = this.getDownloadIcal(expo, newItem);
      newItem.linkOutlook = this.getDownloadOutlook(expo, newItem);
      newItem.description = this.markdownService.compile(newItem.description);
      expo.listCalendar[dayLabel].push(newItem);

      expo.groupedCalendar[dayLabel][stageName].push(newItem);
    });

    Object.keys(expo.listCalendar).forEach(dayLabel => {
      expo.listCalendar[dayLabel].sort((a, b) => {
        if (a.start > b.start) {
          return 1;
        } else if (a.start < b.start) {
          return -1;
        }
        if (a.duration > b.duration) {
          return 1;
        } else if (a.duration < b.duration) {
          return -1;
        }
        if (a.stageName > b.stageName) {
          return 1;
        } else if (a.stageName < b.stageName) {
          return -1;
        }
        return 0;
      });
      Object.keys(expo.groupedCalendar[dayLabel]).forEach(stageName => {
        expo.groupedCalendar[dayLabel][stageName].sort((a, b) => {
          if (a.start > b.start) {
            return 1;
          } else if (a.start < b.start) {
            return -1;
          }
          if (a.duration > b.duration) {
            return 1;
          } else if (a.duration < b.duration) {
            return -1;
          }
          if (a.stageName > b.stageName) {
            return 1;
          } else if (a.stageName < b.stageName) {
            return -1;
          }
          return 0;
        });
      });
    });

    // if (expo.session && expo.session.length > 0) {
    //   expo.session.forEach(calendarEntrySessionViewDto => {
    //     const tzStamp = dayjs(calendarEntrySessionViewDto.start);
    //     const dayLabel: string = tzStamp.format('YYYY-MM-DD').toString();
    //
    //     if (expo.allDays.indexOf(dayLabel) === -1) {
    //       expo.allDays.push(dayLabel);
    //     }
    //
    //     if (!expo.groupedSessions[dayLabel]) {
    //       expo.groupedSessions[dayLabel] = new Array<CalendarEntrySessionViewDto>();
    //     }
    //     expo.groupedSessions[dayLabel].push(calendarEntrySessionViewDto);
    //   });
    // }

    if (expo.boothCalendar && expo.boothCalendar.length > 0) {
      expo.boothCalendar.forEach(calendarEntryBoothViewDto => {
        const tzStamp = dayjs(calendarEntryBoothViewDto.start);
        const dayLabel: string = tzStamp.format('YYYY-MM-DD').toString();

        if (expo.allDays.indexOf(dayLabel) === -1) {
          expo.allDays.push(dayLabel);
        }

        if (!expo.groupedPresentations[dayLabel]) {
          expo.groupedPresentations[dayLabel] = {};
        }
        if (!expo.groupedPresentations[dayLabel][calendarEntryBoothViewDto.exhibitorName]) {
          expo.groupedPresentations[dayLabel][calendarEntryBoothViewDto.exhibitorName] = new Array<CalendarEntryBoothDtoExtended>();
        }

        const newItem: CalendarEntryBoothDtoExtended = calendarEntryBoothViewDto as CalendarEntryBoothDtoExtended;
        newItem.linkGoogle = this.getDownloadGoogleExhibitor(expo, newItem);
        newItem.linkIcal = this.getDownloadIcalExhibitor(expo, newItem);
        newItem.linkOutlook = this.getDownloadOutlookExhibitor(expo, newItem);
        newItem.description = this.markdownService.compile(newItem.description);

        expo.groupedPresentations[dayLabel][calendarEntryBoothViewDto.exhibitorName].push(newItem);
      });
    }

    expo.allDays = expo.allDays.sort((a, b) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  }

  private processDesign(expo: ExpoOverviewExtended, design?: ExpoDesignDto, expoDetails?: any) {
    if (design) {
      expo.expoDesign = design;
    }
    if (!expo.expoDesign) {
      expo.expoDesign = new DesignDefault();
    }
    if (expoDetails) {
      delete expoDetails.exhibitors;
      Object.assign(expo, expoDetails);
    }
    if (expo.expoDesign.expo_intro_image === 'DELETED') {
      expo.expoDesign.expo_intro_image = '';
    }
    if (expo.expoDesign.expo_desc_image === 'DELETED') {
      expo.expoDesign.expo_desc_image = '';
    }
    if (expo.expoDesign.backgroundImage === 'DELETED') {
      expo.expoDesign.backgroundImage = '';
    }
    if (expo.expoDesign.expo_desc_mode == null) {
      expo.expoDesign.expo_desc_mode = 1;
    }
    if (!expo.expoDesign.title_color || expo.expoDesign.title_color === '') {
      expo.expoDesign.title_color = expo.expoDesign.font_color;
    }

    expo.theme = {};

    if (!expo.expoDesign.defaultFontSize) {
      expo.expoDesign.defaultFontSize = 18;
    }

    if (!expo.expoDesign.introFontSize) {
      expo.expoDesign.introFontSize = 18;
    }

    const defaultFontSize = (expo.expoDesign.defaultFontSize / 18).toString() + 'vw';
    expo.theme[`expo-font-size`] = defaultFontSize;

    const introFontSize = (expo.expoDesign.introFontSize / 18).toString() + 'vw';
    expo.theme[`expo-intro-font-size`] = introFontSize;

    this.addColorGroup(expo.theme, expo.expoDesign.font_color, 'font');
    this.addColorGroup(expo.theme, expo.expoDesign.title_color, 'title');
    this.addColorGroup(expo.theme, expo.expoDesign.background_color, 'background');
    this.addColorGroup(expo.theme, expo.expoDesign.modal_background_color, 'modal-background');
    this.addColorGroup(expo.theme, expo.expoDesign.box_background_color, 'box-background');
    this.addColorGroup(expo.theme, expo.expoDesign.box_font_color, 'box-font');
    this.addColorGroup(expo.theme, expo.expoDesign.button_background_color, 'button-background');
    this.addColorGroup(expo.theme, expo.expoDesign.button_font_color, 'button-font');

    if (design) {
      this.expoDesignChanged.emit({expoId: expo.id, design: expo.expoDesign});
    }
  }

  private processExhibitors(expo: ExpoOverviewExtended) {
    expo.exhibitors.sort((a, b) => b.sequence - a.sequence);
    expo.groupedExhibitors = {};

    if (expo.groupingEnabled && expo.exhibitorGroups && expo.exhibitorGroups.length > 0) {
      expo.groupedView = true;

      expo.groupedExhibitors[9999] = {
        group: {name: 'Default'},
        exhibitors: Array<ExhibitorGroupedExtended>(),
        logoUrl: null,
        groupDescription: null
      };
      expo.exhibitorGroups.forEach(value1 => {
        expo.groupedExhibitors[value1.id] = {
          group: value1,
          exhibitors: new Array<ExhibitorGroupedExtended>(),
          logoUrl: null,
          groupDescription: null
        };
      });
      expo.exhibitors.forEach(value1 => {
        const groupedExhibitor = value1 as ExhibitorGroupedExtended;
        groupedExhibitor.logoSquareUrl = this.imageUrlHelper.getLogo(value1);
        groupedExhibitor.routerLink = this.linkHandlerService.getExhibitorUrl(expo, value1);

        if (groupedExhibitor.exhibitorGroupId > 0) {
          if (expo.groupedExhibitors[groupedExhibitor.exhibitorGroupId].group.active) {
            expo.groupedExhibitors[groupedExhibitor.exhibitorGroupId].exhibitors.push(groupedExhibitor);
          } else {
            expo.groupedExhibitors[9999].exhibitors.push(groupedExhibitor);
          }
        } else {
          expo.groupedExhibitors[9999].exhibitors.push(groupedExhibitor);
        }
      });
    } else {
      expo.groupedExhibitors[9999] = {
        group: {name: 'Default'},
        exhibitors: new Array<ExhibitorGroupedExtended>(),
        logoUrl: null,
        groupDescription: null
      };
      expo.exhibitors.forEach(value1 => {
        const groupedExhibitor = value1 as ExhibitorGroupedExtended;
        groupedExhibitor.logoSquareUrl = this.imageUrlHelper.getLogo(value1);
        groupedExhibitor.routerLink = this.linkHandlerService.getExhibitorUrl(expo, value1);
        expo.groupedExhibitors[9999].exhibitors.push(groupedExhibitor);
      });
    }
    Object.keys(expo.groupedExhibitors).forEach(value1 => {
      if (expo.groupedExhibitors[value1].exhibitors.length === 0) {
        delete expo.groupedExhibitors[value1];
      } else {
        expo.groupedExhibitors[value1].exhibitors.sort((a, b) => a.sequence - b.sequence);
        const group = expo.groupedExhibitors[value1].group;
        expo.groupedExhibitors[value1].groupDescription = this.markdownService.compile(group.description);
        if ((group.logoSquare && group.logoSquare !== '') || (group.logo && group.logo !== '')) {
          expo.groupedExhibitors[value1].logoUrl = this.imageUrlHelper.getLogo(group);
        }
      }
    });
  }

  private processStages(expo: ExpoOverviewExtended) {
    expo.stages.sort((a, b) => a.sequence - b.sequence);
  }

  private queryRefresh() {
    if (window && window.parent) {
      window.parent.postMessage('design-request', '*');
      window.parent.postMessage('design-request-booth', '*');
    }
  }

  private addColorGroup(result: { [key: string]: string } = {}, hex: string, area: string) {
    if (hex && hex.startsWith('rgba')) {
      hex = this.colorHelper.rgba2hex(hex);
    }
    if (hex && hex !== '') {
      const parts = this.colorHelper.colorStringToArray(hex);
      result[area + '-red'] = parts[0];
      result[area + '-green'] = parts[1];
      result[area + '-blue'] = parts[2];
      const partsHsl = this.colorHelper.colorToHslArray(hex);
      result[area + '-h'] = partsHsl[0];
      result[area + '-s'] = partsHsl[1];
      result[area + '-l'] = partsHsl[2];
    }
  }

  private setTheme(theme: { [key: string]: string }) {
    Object.keys(theme).forEach(k =>
      document.documentElement.style.setProperty(`--${k}`, theme[k])
    );
  }

  getCalEvent(expo: ExpoOverviewExtended, item: CalendarEntryStageDto): ICalendarEvent {
    return {
      // Event title
      title: item.label,
      // Event start date
      start: dayjs(item.start).toDate(),
      // Event duration (IN MINUTES)
      duration: item.duration,
      // If an end time is set, this will take precedence over duration (optional)
      // end: new Date('June 15, 2013 23:00'),
      // Event Address (optional)
      // address: this.getStageLink(this.entry),
      // Event Description (optional)
      description: item.description,
      url: this.getStageLink(expo, item),
    };
  }

  private getStageLink(expo: ExpoOverviewExtended, entry: CalendarEntryStageDto): string {
    if (expo.isPreview) {
      return '#';
    }
    return this.linkHandlerService.getStageUrl(expo, {name: entry.stageName, shortKey: entry.stageShortKey}, entry.id);
  }

  getDownloadIcal(expo: ExpoOverviewExtended, item: CalendarEntryStageDto): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(CalendarTypeEnum.iCalendar, this.getCalEvent(expo, item))
    );
  }

  getDownloadGoogle(expo: ExpoOverviewExtended, item: CalendarEntryStageDto): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(CalendarTypeEnum.google, this.getCalEvent(expo, item))
    );
  }

  getDownloadOutlook(expo: ExpoOverviewExtended, item: CalendarEntryStageDto): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(CalendarTypeEnum.google, this.getCalEvent(expo, item))
    );
  }

  getCalEventBooth(expo: ExpoOverviewExtended, item: CalendarEntryBoothDtoExtended): ICalendarEvent {
    return {
      // Event title
      title: item.label,
      // Event start date
      start: dayjs(item.start).toDate(),
      // Event duration (IN MINUTES)
      duration: item.duration,
      // If an end time is set, this will take precedence over duration (optional)
      // end: new Date('June 15, 2013 23:00'),
      // Event Address (optional)
      // address: this.getStageLink(this.entry),
      // Event Description (optional)
      description: item.description,
      url: this.getBoothLink(expo, item),
    };
  }

  private getBoothLink(expo: ExpoOverviewExtended, entry: CalendarEntryBoothDtoExtended): string {
    if (expo.isPreview) {
      return '#';
    }
    return this.linkHandlerService.getExhibitorUrl(expo, {
      name: entry.exhibitorName,
      shortKey: entry.exhibitorShortKey
    });
  }

  getDownloadIcalExhibitor(expo: ExpoOverviewExtended, item: CalendarEntryBoothDtoExtended): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(CalendarTypeEnum.iCalendar, this.getCalEventBooth(expo, item))
    );
  }

  getDownloadGoogleExhibitor(expo: ExpoOverviewExtended, item: CalendarEntryBoothDtoExtended): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(CalendarTypeEnum.google, this.getCalEventBooth(expo, item))
    );
  }

  getDownloadOutlookExhibitor(expo: ExpoOverviewExtended, item: CalendarEntryBoothDtoExtended): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(CalendarTypeEnum.google, this.getCalEventBooth(expo, item))
    );
  }

  getExpoList(): Promise<{ expos: Array<ExpoSimpleListItemDto>, access: { [id: string]: ExpoBookedListDto } }> {
    return new Promise<{ expos: Array<ExpoSimpleListItemDto>, access: { [id: string]: ExpoBookedListDto } }>((resolve, reject) => {
      if (this.signInService.isLoggedIn()) {
        this.webService.webGetExpoAccess()
          .subscribe(access => {
            this.webService.webGetExpoList(this.imageUrlHelper.noCache).subscribe(expos => {
              resolve({expos: expos, access: access});
            }, error => {
              reject(error);
            });
          }, error => {
            reject(error);
          });
      } else {
        this.webService.webGetExpoList(this.imageUrlHelper.noCache).subscribe(expos => {
          resolve({expos: expos, access: {}});
        }, error => {
          reject(error);
        });
      }
    });
  }

  getVHosts(): Promise<Array<VHost>> {
    return new Promise<Array<VHost>>((resolve, reject) => {
      this.webService.webGetVHosts(this.imageUrlHelper.noCache)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webAnonToken(data: SignInDto): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.webService.webAnonToken(data)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webSignIn(data: SignInData): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.webService.webSignIn(data)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webAgoraToken(expoId: string, exhibitorId: string): Promise<AgoraTokenResultDto> {
    return new Promise<AgoraTokenResultDto>((resolve, reject) => {
      this.webService.webAgoraToken(expoId, exhibitorId)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webGetAvailableSlots(expoId: string, exhibitorId: string, data: BoockingCheckDto): Promise<AvailableBookingResult> {
    return new Promise<AvailableBookingResult>((resolve, reject) => {
      this.webService.webGetAvailableSlots(expoId, exhibitorId, data)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webBookSlot(expoId: string, exhibitorId: string, data: BookSlotDto): Promise<BookingResultState> {
    return new Promise<BookingResultState>((resolve, reject) => {
      this.webService.webBookSlot(expoId, exhibitorId, data)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webSendOfflineMessage(data: MessageExhibitorDto): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.webService.webSendOfflineMessage(data)
        .subscribe(value => {
          resolve();
        }, error => {
          reject(error);
        });
    });
  }

  webGetProfile(): Promise<VisitorProfileDto> {
    return new Promise<VisitorProfileDto>((resolve, reject) => {
      this.webService.webGetProfile()
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webPwdLessProfileUpdate(data: VisitorProfileDetailUpdateDto): Promise<LoginResultDto> {
    return new Promise<LoginResultDto>((resolve, reject) => {
      this.webService.webPwdLessProfileUpdate(data)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webGrantExpoAccess(data: ExpoBookedListDto): Promise<{ [p: string]: ExpoBookedListDto }> {
    return new Promise<{ [p: string]: ExpoBookedListDto }>((resolve, reject) => {
      this.webService.webGrantExpoAccess(data)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webOpenSession(stageId: number, password: SessionPassword): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.webService.webOpenSession(stageId, password)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webGetYoutubePlaylist(vodLibraraId: string): Promise<YoutubeChannel> {
    return new Promise<YoutubeChannel>((resolve, reject) => {
      this.webService.webGetYoutubePlaylist(vodLibraraId)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }
  //
  // webGetScene(sceneId: string): Promise<ExpoListItemDto> {
  //   return new Promise<ExpoListItemDto>((resolve, reject) => {
  //     this.webService.webGetScene(sceneId)
  //       .subscribe(value => {
  //         resolve(value);
  //       }, error => {
  //         reject(error);
  //       });
  //   });
  // }

  webGetCallId(callId: string): Promise<CallDetails> {
    return new Promise<CallDetails>((resolve, reject) => {
      this.webService.webGetCallId(callId)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webPwdLessStart(data: PwdLessLoginDto): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.webService.webPwdLessStart(data)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webPwdLessCallback(data: PwdLessCallbackDto): Promise<LoginResultDto> {
    return new Promise<LoginResultDto>((resolve, reject) => {
      this.webService.webPwdLessCallback(data)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webPwdLessTotpCallback(data: PwdLessCallbackDto): Promise<LoginResultDto> {
    return new Promise<LoginResultDto>((resolve, reject) => {
      this.webService.webPwdLessTotpCallback(data)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webRefresh(): Promise<LoginResultDto> {
    return new Promise<LoginResultDto>((resolve, reject) => {
      this.webService.webRefresh()
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webTrackDownloadPost(trackDownload: TrackDownloadDto): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.webService.webTrackDownloadPost(trackDownload)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }

  webGetChatId(id: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.webService.webGetChatId(id)
        .subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
    });
  }
}
