import { Component, OnInit } from '@angular/core';
import {VHostService} from '../../services/vhost.service';

@Component({
  selector: 'app-exhibitor-layout',
  templateUrl: './exhibitor-layout.component.html',
  styleUrls: ['./exhibitor-layout.component.scss']
})
export class ExhibitorLayoutComponent {

  constructor(public vHostService: VHostService) { }
}
