/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface AvailableSlotDto { 
    rowId?: number;
    start?: Date;
    interval?: number;
}

/**
 * Namespace for property- and property-value-enumerations of AvailableSlotDto.
 */
export namespace AvailableSlotDto {
    /**
     * All properties of AvailableSlotDto.
     */
    export enum Properties {
        rowId = 'rowId',
        start = 'start',
        interval = 'interval'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of AvailableSlotDto.
    */
    export const ModelValidators: {[K in keyof AvailableSlotDto]: [string, ValidatorFn][]} = {
        rowId: [
        ],
        start: [
        ],
        interval: [
        ],
    };

    /**
    * The FormControlFactory for AvailableSlotDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<AvailableSlotDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for AvailableSlotDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: AvailableSlotDto = {
            rowId: null,
            start: null,
            interval: null,
          }
        ) {
            super(model, AvailableSlotDto.ModelValidators);
        }
    }

}


