import {Injectable} from '@angular/core';
import {ConfirmationDialogComponent} from '../components/components/confirmation-dialog/confirmation-dialog.component';
import {GdprPermissionsComponent} from '../components/components/gdpr-permissions/gdpr-permissions.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {GdprPermissions} from '../objects/gdpr-permissions';

@Injectable({
  providedIn: 'root'
})
export class GdprService {
  public permissions: GdprPermissions;

  public permissionsUpdated: Subject<GdprPermissions> = new Subject<GdprPermissions>();

  constructor(private modalService: NgbModal) {
    this.loadPermissions();
  }

  private loadPermissions() {
    const perms = localStorage.getItem('permissions');
    if (perms && perms !== '') {
      this.permissions = JSON.parse(perms);
    } else {
      this.permissions = {
        agoraPermitted: false,
        analyticsPermitted: false,
        youtubePermitted: false,
        vimeoPermitted: false,
        cloudflarePermitted: false,
        freshChatPermitted: false,
        zoomPermitted: false,
        webexPermitted: false,
        permissionChecked: false,
        version: environment.permissionsVersion
      };
    }
  }

  public check(): Promise<GdprPermissions> {
    return new Promise<GdprPermissions>((resolve, reject) => {
      if ((!this.permissions.permissionChecked || this.permissions.version !== environment.permissionsVersion) && !location.href.match(/gdprok=true/)) {
        return this.checkDialog();
      } else if (location.href.match(/gdprok=true/)) {
        this.permissions = {
          vimeoPermitted: true,
          agoraPermitted: true,
          analyticsPermitted: true,
          cloudflarePermitted: true,
          freshChatPermitted: true,
          permissionChecked: true,
          version: 1,
          youtubePermitted: true,
          webexPermitted: true,
          zoomPermitted: true
        };
        resolve(this.permissions);
      } else {
        resolve(this.permissions);
      }
    });
  }

  public checkDialog(): Promise<GdprPermissions> {
    return new Promise<GdprPermissions>((resolve, reject) => {
      const modalRef = this.modalService.open(GdprPermissionsComponent, {size: 'lg', backdrop: 'static'});
      modalRef.componentInstance.permissions = {...this.permissions};
      const component: GdprPermissionsComponent = modalRef.componentInstance;
      modalRef.result.then(value => {
        if (value) {
          this.permissions = component.permissions;
          this.save();
          resolve(this.permissions);
        } else {
          this.loadPermissions();
          resolve(this.permissions);
        }
      }, reason => {
        resolve(this.permissions);
      });
    });
  }

  private save() {
    this.permissions.version = environment.permissionsVersion;
    localStorage.setItem('permissions', JSON.stringify(this.permissions));
    this.permissionsUpdated.next(this.permissions);
  }

  confirmYoutube() {
    this.permissions.youtubePermitted = true;
    this.save();
  }

  confirmVimeo() {
    this.permissions.vimeoPermitted = true;
    this.save();
  }

  confirmCloudflare() {
    this.permissions.cloudflarePermitted = true;
    this.save();
  }

  confirmFreshChat() {
    this.permissions.freshChatPermitted = true;
    this.save();
  }

  confirmZoom() {
    this.permissions.zoomPermitted = true;
    this.save();
  }

  confirmAgora() {
    this.permissions.agoraPermitted = true;
    this.save();
  }

  confirmWebEx() {
    this.permissions.webexPermitted = true;
    this.save();
  }

  confirmAllVideo() {
    this.permissions.youtubePermitted = true;
    this.permissions.vimeoPermitted = true;
    this.permissions.cloudflarePermitted = true;
    this.permissions.zoomPermitted = true;
    this.permissions.agoraPermitted = true;
    this.save();
  }
}
