/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export type ExpoButtonPosition = 0 | 1;

export const ExpoButtonPosition = {
    NUMBER_0: 0 as ExpoButtonPosition,
    NUMBER_1: 1 as ExpoButtonPosition
};

