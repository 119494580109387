/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ExhibitorGroupReferenceDto } from './exhibitorGroupReferenceDto';
import { CalendarEntrySessionViewDto } from './calendarEntrySessionViewDto';
import { CalendarEntryBoothViewDto } from './calendarEntryBoothViewDto';
import { CalendarEntryStageDto } from './calendarEntryStageDto';
import { InventoryItemType } from './inventoryItemType';
import { VideoChatMode } from './videoChatMode';
import { ExpoDesignDto } from './expoDesignDto';
import { AccessMode } from './accessMode';
import { StageOverviewDto } from './stageOverviewDto';
import { ExpoExhibitorOverviewDto } from './expoExhibitorOverviewDto';


export interface ExpoOverviewDto { 
    id?: string;
    shortKey?: string;
    name?: string;
    description?: string;
    descriptionLong?: string;
    intro?: string;
    dateStart?: Date;
    dateEnd?: Date;
    logo?: string;
    logoSquare?: string;
    logoWide?: string;
    exhibitors?: Array<ExpoExhibitorOverviewDto>;
    defaultScene?: number;
    worldActive?: boolean;
    isPreview?: boolean;
    googleAnalyticsId?: string;
    matomoId?: string;
    externalMatomoServer?: string;
    externalMatomoId?: string;
    accessMode?: AccessMode;
    timezone?: string;
    introType?: InventoryItemType;
    introSource?: string;
    calendar?: Array<CalendarEntryStageDto>;
    stages?: Array<StageOverviewDto>;
    exhibitorGroups?: Array<ExhibitorGroupReferenceDto>;
    groupingEnabled?: boolean;
    videoChatMode?: VideoChatMode;
    vodLibraryType?: InventoryItemType;
    vodLibraryId?: string;
    randomChatNames?: boolean;
    session?: Array<CalendarEntrySessionViewDto>;
    exhibitorInfoVisibility?: boolean;
    tagsAvailable?: boolean;
    tagsEnabled?: boolean;
    tags?: Array<string>;
    surveyEnabled?: boolean;
    zoomRequiresLogin?: boolean;
    externalLinksDirectOpen?: boolean;
    mediaLinksSkipZoom?: boolean;
    rowVersion?: number;
    expoDesign?: ExpoDesignDto;
    boothCalendar?: Array<CalendarEntryBoothViewDto>;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoOverviewDto.
 */
export namespace ExpoOverviewDto {
    /**
     * All properties of ExpoOverviewDto.
     */
    export enum Properties {
        id = 'id',
        shortKey = 'shortKey',
        name = 'name',
        description = 'description',
        descriptionLong = 'descriptionLong',
        intro = 'intro',
        dateStart = 'dateStart',
        dateEnd = 'dateEnd',
        logo = 'logo',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        exhibitors = 'exhibitors',
        defaultScene = 'defaultScene',
        worldActive = 'worldActive',
        isPreview = 'isPreview',
        googleAnalyticsId = 'googleAnalyticsId',
        matomoId = 'matomoId',
        externalMatomoServer = 'externalMatomoServer',
        externalMatomoId = 'externalMatomoId',
        accessMode = 'accessMode',
        timezone = 'timezone',
        introType = 'introType',
        introSource = 'introSource',
        calendar = 'calendar',
        stages = 'stages',
        exhibitorGroups = 'exhibitorGroups',
        groupingEnabled = 'groupingEnabled',
        videoChatMode = 'videoChatMode',
        vodLibraryType = 'vodLibraryType',
        vodLibraryId = 'vodLibraryId',
        randomChatNames = 'randomChatNames',
        session = 'session',
        exhibitorInfoVisibility = 'exhibitorInfoVisibility',
        tagsAvailable = 'tagsAvailable',
        tagsEnabled = 'tagsEnabled',
        tags = 'tags',
        surveyEnabled = 'surveyEnabled',
        zoomRequiresLogin = 'zoomRequiresLogin',
        externalLinksDirectOpen = 'externalLinksDirectOpen',
        mediaLinksSkipZoom = 'mediaLinksSkipZoom',
        rowVersion = 'rowVersion',
        expoDesign = 'expoDesign',
        boothCalendar = 'boothCalendar'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoOverviewDto.
    */
    export const ModelValidators: {[K in keyof ExpoOverviewDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        shortKey: [
        ],
        name: [
        ],
        description: [
        ],
        descriptionLong: [
        ],
        intro: [
        ],
        dateStart: [
        ],
        dateEnd: [
        ],
        logo: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        exhibitors: [
        ],
        defaultScene: [
        ],
        worldActive: [
        ],
        isPreview: [
        ],
        googleAnalyticsId: [
        ],
        matomoId: [
        ],
        externalMatomoServer: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        externalMatomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        accessMode: [
        ],
        timezone: [
        ],
        introType: [
        ],
        introSource: [
        ],
        calendar: [
        ],
        stages: [
        ],
        exhibitorGroups: [
        ],
        groupingEnabled: [
        ],
        videoChatMode: [
        ],
        vodLibraryType: [
        ],
        vodLibraryId: [
        ],
        randomChatNames: [
        ],
        session: [
        ],
        exhibitorInfoVisibility: [
        ],
        tagsAvailable: [
        ],
        tagsEnabled: [
        ],
        tags: [
        ],
        surveyEnabled: [
        ],
        zoomRequiresLogin: [
        ],
        externalLinksDirectOpen: [
        ],
        mediaLinksSkipZoom: [
        ],
        rowVersion: [
        ],
        expoDesign: [
        ],
        boothCalendar: [
        ],
    };

    /**
    * The FormControlFactory for ExpoOverviewDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoOverviewDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoOverviewDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoOverviewDto = {
            id: null,
            shortKey: null,
            name: null,
            description: null,
            descriptionLong: null,
            intro: null,
            dateStart: null,
            dateEnd: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            exhibitors: null,
            defaultScene: null,
            worldActive: null,
            isPreview: null,
            googleAnalyticsId: null,
            matomoId: null,
            externalMatomoServer: null,
            externalMatomoId: null,
            accessMode: null,
            timezone: null,
            introType: null,
            introSource: null,
            calendar: null,
            stages: null,
            exhibitorGroups: null,
            groupingEnabled: null,
            videoChatMode: null,
            vodLibraryType: null,
            vodLibraryId: null,
            randomChatNames: null,
            session: null,
            exhibitorInfoVisibility: null,
            tagsAvailable: null,
            tagsEnabled: null,
            tags: null,
            surveyEnabled: null,
            zoomRequiresLogin: null,
            externalLinksDirectOpen: null,
            mediaLinksSkipZoom: null,
            rowVersion: null,
            expoDesign: null,
            boothCalendar: null,
          }
        ) {
            super(model, ExpoOverviewDto.ModelValidators);
        }
    }

}


