import {Injectable} from '@angular/core';
import {DisplayListDto, ExpoExhibitorListItemDto, ExpoSimpleListItemDto} from '../../virtual-expo-api';
import {environment} from '../../../environments/environment';
import {LogoBase} from '../../objects/logo-base';
import {ScreenSource} from '../../objects/screen-source';
import {AlertService} from '../../services/alert.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ImageUrlService {

  public noCache: boolean;
  lastTs: number;

  constructor(
    private router: Router
    , private alertService: AlertService
  ) {
    const matches = location.href.match(/noCache=(\d+)/);
    if (matches) {
      const ttl = parseInt(matches[1], 10);
      if (ttl >= Date.now() - (1000 * 60 * 60 * 24)) {
        this.noCache = true;
      } else {
        this.alertService.info('outdated timestamp, redirecting');
        this.router.navigateByUrl(location.pathname);
      }
    }
  }

  public cacheTag(): string {
    if (this.noCache) {
      if (!this.lastTs || this.lastTs < Date.now() - 5000) {
        this.lastTs = Date.now();
      }
      return `?noCache=${this.lastTs}`;
    }
    return '';
  }

  public getUncachedUrl(url): string {
    if (url.startsWith('data')) {
      return url;
    }
    const match = url.match(/(.*)\?noCache=.*/);
    if (match) {
      return match[1];
    }
    return url;
  }

  private getUrlOrRaw(url: string): string {
    if (url.startsWith('http') || url.startsWith('data')) {
      return url;
    }
    return environment.cdnURL + '/uploads/' + url + this.cacheTag();
  }

  private pad2(num: number): string {
    return (num < 10 ? '0' : '') + num;
  }

  private parseYoutubeId(url: string): string {
    let media = url;
    if (media.length > 11) {
      let isId = media.match(/^.*(youtu.be\/|v\/|embed\/|watch\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*/);
      if (isId && isId.length > 3) {
        media = isId[3];
      }
      if (media.length > 11) {
        isId = media.match(/([A-Za-z0-9_\-]{11})/);
        if (isId.length > 1) {
          media = isId[1];
        }
      }
    }
    return media;
  }

  getProxyLink(url: string): string {
    return environment.imageProxyPrefix + url;
  }

  getImageLink(display: ScreenSource): string {
    let uri = '';
    const url = display.image;
    if (!url || url.trim() === '') {
      if (display.displayType === 10) {
        return '/assets/pdf.jpg';
        // } else {
        //   return '/assets/empty.jpg';
      }
    }
    if (display.displayType !== 1 && (url.startsWith('http') || url.startsWith('data'))) {
      uri = url;
    } else {
      if (display.displayType === 1) {
        // url = url.replace(/\-/g, '%2d');
        uri = this.getProxyLink( 'https://img.youtube.com/vi/' + this.parseYoutubeId(url) + '/0.jpg');
      } else if (display.displayType === 2) {
        if (display.source.startsWith('http')) {
          uri = this.getProxyLink( display.source);
        } else {
          uri = this.getProxyLink( 'https://i.vimeocdn.com/video/' + display.source + '.jpg');
        }
      } else if (display.displayType === 21) {
        uri = this.getProxyLink( 'https://img.youtube.com/vi/' + display.source + '/0.jpg');
      } else if (display.displayType === 22) {
        if (display.source.startsWith('http')) {
          uri = this.getProxyLink( display.source);
        } else {
          uri = this.getProxyLink('https://i.vimeocdn.com/video/' + display.source + '.jpg');

        }
      } else if (display.displayType === 3) {
        if (display.image && display.image !== '') {
          uri = environment.cdnURL + '/uploads/' + display.image + this.cacheTag();
        } else {
          uri = this.getProxyLink( `https://videodelivery.net/${display.source}/thumbnails/thumbnail.jpg`);
          if (display.sourceAdditional && display.sourceAdditional !== '') {
            uri += `?time=${display.sourceAdditional}`;
          }
        }
      } else if (display.displayType === 10) {
        uri = environment.cdnURL + '/uploads/' + url + this.cacheTag();
      } else if (display.displayType === 11) {
        uri = environment.cdnURL + '/uploads/' + url + this.cacheTag();
      } else {
        uri = environment.cdnURL + '/uploads/' + url + this.cacheTag();
      }
    }
    return uri;
  }

  getDownload(url: string, display?: any): string {
    if ((!url || url === '') && display.displayType.toString() === '10' && display.link && display.link !== '') {
      url = display.link;
    }
    if (!url) {
      return environment.cdnURL + '/empty.jpg';
    }
    if (!display || (display.displayType !== '1' && (url.startsWith('http') || url.startsWith('data')))) {
      return url;
    }
    if (display) {
      if (display.displayType === '1') {
        return this.getProxyLink( 'https://img.youtube.com/vi/' + this.parseYoutubeId(url) + '/sddefault.jpg');
      } else if (display.displayType === '3') {
        if (display.image && display.image !== '') {
          return environment.downloadURL + '/uploads/' + display.image;
        } else {
          let link = this.getProxyLink( `https://videodelivery.net/${display.source}/thumbnails/thumbnail.jpg`);
          if (display.sourceAdditional && display.sourceAdditional !== '') {
            link += `?time=${display.sourceAdditional}`;
          }
          return link;
        }
      }
    }
    return environment.downloadURL + '/uploads/' + url;
  }

  getAsset(url: string): string {
    if (!url) {
      return environment.cdnURL + '/empty.jpg';
    }
    if (url.startsWith('http') || url.startsWith('data')) {
      return url;
    }
    return environment.assetURL + '/' + url;
  }

  getImage(url: string, display?: any): string {
    if (url === 'DELETED') {
      return null;
    }
    if (!url) {
      return environment.cdnURL + '/empty.jpg';
    }
    if (url.startsWith('http')) {
      return url;
    }
    if (url.startsWith('data')) {
      return url.replace('#', '%23');
    }
    if (display) {
      if (display.displayType === '1') {
        return this.getProxyLink( 'https://img.youtube.com/vi/' + url + '/sddefault.jpg');
      } else if (display.displayType === '3') {
        if (display.image && display.image !== '') {
          return environment.cdnURL + '/uploads/' + display.image + this.cacheTag();
        } else {
          let link = this.getProxyLink( `https://videodelivery.net/${display.source}/thumbnails/thumbnail.jpg`);
          if (display.sourceAdditional && display.sourceAdditional !== '') {
            link += `?time=${display.sourceAdditional}`;
          }
          return link;
        }
      }
    }
    return environment.cdnURL + '/uploads/' + url + this.cacheTag();
  }

  getExhibitorLogo(exhibitor: { logo?: string, logoSquare?: string }): string {
    if (exhibitor) {
      if (exhibitor.logoSquare && exhibitor.logoSquare !== '') {
        return environment.cdnURL + '/uploads/' + exhibitor.logoSquare + this.cacheTag();
      }
      if (exhibitor.logo && exhibitor.logo !== '') {
        return environment.cdnURL + '/uploads/' + exhibitor.logo + this.cacheTag();
      }
    }
    return environment.cdnURL + '/empty.jpg';
  }

  getExhibitorLogoWide(exhibitor: ExpoExhibitorListItemDto): string {
    if (exhibitor) {
      if (exhibitor.logoWide && exhibitor.logoWide !== '') {
        return environment.cdnURL + '/uploads/' + exhibitor.logoWide + this.cacheTag();
      }
      return this.getExhibitorLogo(exhibitor);
    }
    return environment.cdnURL + '/empty.jpg';
  }

  getExpoLogo(expo: ExpoSimpleListItemDto): string {
    if (expo) {
      if (expo.logoSquare && expo.logoSquare !== '') {
        return environment.cdnURL + '/uploads/' + expo.logoSquare + this.cacheTag();
      }
      return expo.logo;
    }
    return environment.cdnURL + '/empty.jpg';
  }

  getExpoLogoWide(expo: ExpoSimpleListItemDto): string {
    if (expo) {
      if (expo.logoWide && expo.logoWide !== '') {
        return environment.cdnURL + '/uploads/' + expo.logoWide + this.cacheTag();
      }
      return this.getExpoLogo(expo);
    }
    return environment.cdnURL + '/empty.jpg';
  }

  getLogo(logoBase: LogoBase): string {
    if (logoBase) {
      if (logoBase.logoSquare && logoBase.logoSquare !== '') {
        return this.getUrlOrRaw(logoBase.logoSquare);
      }
      if (logoBase.logo && logoBase.logo !== '') {
        return this.getUrlOrRaw(logoBase.logo);
      }
    }
    return environment.cdnURL + '/empty.jpg';
  }

  getLogoWide(logoBase: LogoBase): string {
    if (logoBase) {
      if (logoBase.logoWide && logoBase.logoWide !== '') {
        return this.getUrlOrRaw(logoBase.logoWide);
      }
      return this.getLogo(logoBase);
    }
    return environment.cdnURL + '/empty.jpg';
  }

  getBoothIcon(exhibitor: { boothIcon?: string }, index: number): string {
    if (!exhibitor || !exhibitor.boothIcon || exhibitor.boothIcon === '') {
      const pos = ((index + 1) % 20) + 1;
      return `${environment.cdnURL}/assets/2d/info-2d-${this.pad2(pos)}.png`;
    } else {
      return environment.cdnURL + '/uploads/' + exhibitor.boothIcon + this.cacheTag();
    }
  }

  getAvatar(exhibitor: ExpoExhibitorListItemDto): string {
    if (!exhibitor.avatar || exhibitor.avatar === '') {
      return 'assets/booths/male2.png';
    } else {
      return this.getImage(exhibitor.avatar);
    }
  }
}
