import {HostListener, Injectable} from '@angular/core';
import {ProfileData} from '../objects/profile-data';
import {CalendarEntryStageDto} from '../virtual-expo-api';
import {StorageService} from './storage.service';
import {Subject} from 'rxjs';
import {share} from 'rxjs/operators';
import {ExpoOverviewExtended} from '../objects/expo-overview-extended';
import {DataService} from './data.service';
import {CalendarEntryBoothDtoExtended} from '../objects/calendar-entry-booth-dto-extended';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public profile: ProfileData;

  public selectedCalendar: Array<number> = new Array<number>();
  public selectedCalendarExhibitor: Array<number> = new Array<number>();
  public currentCalendar: Array<number> = new Array<number>();

  private onSubject = new Subject<any>();
  public changes = this.onSubject.asObservable().pipe(share());

  constructor(
    private storageService: StorageService
    , private dataService: DataService
  ) {
    const stored = storageService.get('localprofile');
    this.parseStorage(stored);

    this.storageService.changes.subscribe(value => {
      if (value.key === 'localprofile') {
        this.parseStorage(value.value);
        this.onSubject.next();
      }
    });

    this.dataService.expoChanged.subscribe(expo => {
      this.refreshSelected();
    });
  }

  addCalendar(item: CalendarEntryStageDto) {
    this.profile.calendar.push(item);
    this.updateStorage();
  }

  removeCalendar(item: CalendarEntryStageDto) {
    let found = 0;
    while (found >= 0) {
      found = -1;
      this.profile.calendar.forEach((value, index) => {
        if (value.id === item.id) {
          found = index;
        }
      });
      if (found >= 0) {
        this.profile.calendar.splice(found, 1);
      }
    }
    this.updateStorage();
  }

  addCalendarExhibitor(item: CalendarEntryBoothDtoExtended) {
    this.profile.calendarExhibitor.push(item);
    this.updateStorage();
  }

  removeCalendarExhibitor(item: CalendarEntryBoothDtoExtended) {
    let found = 0;
    while (found >= 0) {
      found = -1;
      this.profile.calendarExhibitor.forEach((value, index) => {
        if (value.id === item.id) {
          found = index;
        }
      });
      if (found >= 0) {
        this.profile.calendarExhibitor.splice(found, 1);
      }
    }
    this.updateStorage();
  }

  updateStorage() {
    this.storageService.store('localprofile', this.profile);
    this.refreshSelected();
  }

  parseStorage(data: any) {
    if (data) {
      this.profile = data;
      if (!this.profile.calendarExhibitor) {
        this.profile.calendarExhibitor = new Array<CalendarEntryBoothDtoExtended>();
      }
    } else {
      this.profile = {
        calendar: new Array<CalendarEntryStageDto>(),
        calendarExhibitor: new Array<CalendarEntryBoothDtoExtended>()
      };
    }
    this.refreshSelected();
  }

  refreshSelected() {
    this.selectedCalendar.length = 0;
    this.selectedCalendarExhibitor.length = 0;
    this.currentCalendar.length = 0;
    this.profile.calendar.forEach(value => {
      this.selectedCalendar.push(value.id);
      if (this.dataService.currentExpo && this.dataService.currentExpo.stageDict[value.stageId]) {
        this.currentCalendar.push(value.id);
      }
    });
    this.profile.calendarExhibitor.forEach(value => {
      this.selectedCalendarExhibitor.push(value.id);
    });
  }

  @HostListener('window:storage') onStorageChange(ev: StorageEvent) {
    if (ev.key === 'localprofile') {
      this.parseStorage(ev.newValue);
    }
  }
}
