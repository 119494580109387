/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 *
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { TrackingType } from './trackingType';
import { AccessMode } from './accessMode';


export interface TrackDownloadDto {
    expoId: string;
    exhibitorId: string;
    displayId?: string;
    name: string;
    emailAddress: string;
    permitContact: boolean;
    url?: string;
    trackingType?: TrackingType;
    accessMode?: AccessMode;
}

/**
 * Namespace for property- and property-value-enumerations of TrackDownloadDto.
 */
export namespace TrackDownloadDto {
    /**
     * All properties of TrackDownloadDto.
     */
    export enum Properties {
        expoId = 'expoId',
        exhibitorId = 'exhibitorId',
        displayId = 'displayId',
        name = 'name',
        emailAddress = 'emailAddress',
        permitContact = 'permitContact',
        url = 'url',
        trackingType = 'trackingType',
        accessMode = 'accessMode'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of TrackDownloadDto.
    */
    export const ModelValidators: {[K in keyof TrackDownloadDto]: [string, ValidatorFn][]} = {
        expoId: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(36)],
                ['maxlength', Validators.maxLength(36)],
        ],
        exhibitorId: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(36)],
                ['maxlength', Validators.maxLength(36)],
        ],
        displayId: [
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(6)],
                ['maxlength', Validators.maxLength(100)],
        ],
        emailAddress: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(6)],
                ['maxlength', Validators.maxLength(100)],
        ],
        permitContact: [
                ['required', Validators.required],
        ],
        url: [
        ],
        trackingType: [
        ],
        accessMode: [
        ],
    };

    /**
    * The FormControlFactory for TrackDownloadDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<TrackDownloadDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for TrackDownloadDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: TrackDownloadDto = {
            expoId: null,
            exhibitorId: null,
            displayId: null,
            name: null,
            emailAddress: null,
            permitContact: null,
            url: null,
            trackingType: null,
            accessMode: null,
          }
        ) {
            super(model, TrackDownloadDto.ModelValidators);
        }
    }

}


