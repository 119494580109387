import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private toastr: ToastrService
    , private translateService: TranslateService
  ) {
  }

  info(label: string | any) {
    this.toastr.info(label);
  }

  warning(label: string) {
    this.toastr.warning(label);
  }

  toast(type: string, title: string, message: string): void {
    if (type === 'success') {
      this.toastr.success(message, title);
    } else if (type === 'err' || type === 'error') {
      this.toastr.error(message, title);
    } else {
      this.toastr.info(message, title);
    }
  }

  success(message: string) {
    this.toastr.success(message, 'Success');
  }

  warn(message: string) {
    this.toastr.warning(message, '');
  }

  error(message: string) {
    this.toastr.error(message, 'Error');
  }

  saved() {
    this.success(this.translateService.instant('alert.success'));
  }

  fail() {
    this.error(this.translateService.instant('alert.fail'));
  }

  deleted() {
    this.warn(this.translateService.instant('alert.deleted'));
  }
}
