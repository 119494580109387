import {Injectable} from '@angular/core';
import * as introJs from 'intro.js/intro';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {DeviceDetectorService} from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class IntroService {
  private introJS = introJs();
  public introCompleted: boolean;
  public introEnabled: boolean;

  public introRestarted: Subject<any> = new Subject<any>();

  constructor(private router: Router, private translateService: TranslateService, private deviceService: DeviceDetectorService) {
    this.introEnabled = !(this.deviceService.isMobile() || this.deviceService.isTablet());
    this.introEnabled = false;

    if (this.introEnabled) {
      this.introCompleted = localStorage.getItem('introCompleted') === 'true';

      this.introJS.setOption('exitOnOverlayClick', false);
      this.introJS.setOption('overlayOpacity', 0.4);
      this.introJS.setOption('showStepNumbers', false);
      this.introJS.setOption('showBullets', false);
      this.introJS.setOption('hidePrev', true);
      this.introJS.setOption('hideNext', true);

      this.translateService.onLangChange
        .subscribe(() => {
          this.translateService.get('intro.next')
            .subscribe(value => {
              this.introJS.setOption('nextLabel', value);
            });
          this.translateService.get('intro.prev')
            .subscribe(value => {
              this.introJS.setOption('prevLabel', value);
            });
          this.translateService.get('intro.skip')
            .subscribe(value => {
              this.introJS.setOption('skipLabel', value);
            });
          this.translateService.get('intro.done')
            .subscribe(value => {
              this.introJS.setOption('doneLabel', value);
            });
        });

      this.introJS.onskip((ev: Event) => {
        this.onComplete(this, false);
      });

      this.introJS.oncomplete((passed: boolean) => {
        this.onComplete(this, passed);
      });

      this.router.events.subscribe(event => {
        if (!this.introCompleted) {
          if (event instanceof NavigationStart) {
            this.introJS.exit(false);
          } else if (event instanceof NavigationEnd) {
            setTimeout(() => {
              this.introJS.start();
            }, 2000);
          }
        }
      });
    }
  }

  private onExit() {
    // alert('this.onExit()');
  }

  private onComplete(service: IntroService, passed: boolean) {
    service.introCompleted = true;
    localStorage.setItem('introCompleted', 'true');
    // alert('this.onComplete()');
  }

  public showIntro() {
    localStorage.setItem('introCompleted', 'false');
    this.introCompleted = false;
    this.introJS.start();

    this.introRestarted.next();
  }

  toggleIntro() {
    this.introCompleted = !this.introCompleted;
    localStorage.setItem('introCompleted', this.introCompleted.toString());
    if (this.introCompleted) {
      this.introJS.exit(false);
    } else {
      this.introJS.start();
      this.introRestarted.next();
    }
  }
}
