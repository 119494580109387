<header class="header_area" id="" *ngIf="loaded && showMenu">
  <nav class="navbar navbar-expand-md navbar-light"
       [ngClass]="{'bg-transparent': isHomepage, 'bg-opacity': isExhibition}">
    <a class="navbar-brand" [routerLink]="'/'"
       [ngClass]="{'brand-home': isHomepage, 'exhibition': isExhibition}">
      <img [src]="orgaLogo" alt="VirtualExpo Logo">
    </a>
    <button class="navbar-toggler" type="button" aria-controls="navbarContent" [attr.aria-expanded]="!navbarOpen"
            aria-label="Toggle navigation" (click)="navbarOpen = !navbarOpen">
      <span class="navbar-toggler-icon ti-menu"></span>
    </button>

    <div class="navbar-collapse" [class.collapse]="!navbarOpen" id="navbarContent" style="margin-right: 2em;">
      <!--            <ul class="navbar-nav mr-auto">-->
      <!--            </ul>-->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link animsition-link" [routerLink]="['/']" (click)="navbarOpen = false;"
             [translate]="'global.exhibitions'"></a>
        </li>
        <li class="nav-item">
          <a class="nav-link animsition-link" [href]="homepageLink" target="_blank" rel="noopener"
             [title]="'global.aboutUs' | translate"
             [translate]="'global.aboutUs'"></a>
        </li>
        <li class="nav-item" *ngIf="is3dEnabled()">
          <a class="nav-link animsition-link" [routerLink]="'/enter'" [translate]="'global.enter'"
             [title]="'global.enter' | translate"
             (click)="navbarOpen = false;"></a>
        </li>
        <li class="nav-item">
          <a class="nav-link animsition-link" [href]="contactLink" target="_blank" rel="noopener"
             [translate]="'global.contact'" [title]="'global.contact' | translate"></a>
        </li>
        <!--              <li class="nav-item">-->
        <!--                <a class="nav-link animsition-link" [href]="environment.manageurl" target="_blank"-->
        <!--                   [translate]="'global.manage'"></a>-->
        <!--              </li>-->
        <!--              <ng-container *ngIf="(!vHostService.isVhost && showLogin) || dataService.isLoggedIn()">-->
        <ng-container *ngIf="showLogin || dataService.signInService.isLoggedIn()">
          <li class="nav-item" *ngIf="!dataService.signInService.isLoggedIn()">
            <!--                  <a class="nav-link animsition-link" href="#" (click)="login($event)" [translate]="'global.login'"-->
            <!--                     *ngIf="!dataService.signInService?.data?.isAnon"-->
            <!--                     [title]="'global.login' | translate"></a>-->
            <a class="nav-link animsition-link" href="#" (click)="signOut($event)"
               *ngIf="dataService.signInService?.data?.hasSignIn"
               [title]="'global.guestLogout' | translate">
              <span [translate]="'global.guestLogout'"></span> {{ dataService.signInService?.data?.name }}
            </a>
          </li>
          <li *ngIf="dataService.signInService.isLoggedIn()" class="nav-item" ngbDropdown>
            <a class="nav-link" href="#" style="cursor: pointer" ngbDropdownToggle id="navbarDropdown1"
               (click)="$event.preventDefault()"
               role="button" title="">
              {{ dataService.signInService.givenName }}
            </a>
            <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
              <a class="dropdown-item" href="#" ngbDropdownItem (click)="editProfile($event)"
                 *ngIf="!dataService.signInService.isAnon"
                 [translate]="'global.profile'" [title]="'global.profile' | translate"></a>
              <div class="dropdown-divider" *ngIf="!dataService.signInService.isAnon"></div>
              <a class="dropdown-item" ngbDropdownItem href="#" (click)="signOut($event)"><em
                class="icon-lock"></em> <span [translate]="'global.logout'"
                                              [title]="'global.logout' | translate"></span></a>
            </div>
          </li>
        </ng-container>
        <ng-container>
          <li class="nav-item">
            <div style="position: relative; cursor: pointer; margin: 0 0.25em;" (click)="openCalendar($event)"
                 [title]="'calendar.header' | translate">
              <i class="fas fa-star info-icon" *ngIf="profileService.currentCalendar.length > 0"></i>
              <i class="far fa-calendar-alt fa-2x info-icon-menu"></i>
            </div>
            <!--                  {{ profileService.selectedCalendar.length }} {{ profileService.currentCalendar.length }}-->
          </li>
          <li class="nav-item">
            <div style="position: relative; cursor: pointer; margin: 0 0.25em;" (click)="openChat($event)"
                 [title]="'userChat.header' | translate">
                    <span class="info-icon info-icon-text" [title]="'userChat.unreadMessages' | translate"
                          [ngClass]="{'icon-passive': unreadMessages === 0}">
                      {{ unreadMessages  }}
                    </span>
              <i class="far fa-comments fa-2x info-icon-menu"></i>
            </div>
            <!--                  {{ profileService.selectedCalendar.length }} {{ profileService.currentCalendar.length }}-->
          </li>
        </ng-container>
        <li class="nav-item">
          <div class="languageMenu">
            <a href="#" (click)="useLanguage($event,'en')" class="btn btn-sm languageButton" title="English"
               [ngClass]="{'languageButtonActive': language == 'en'}">en
            </a>
            <a href="#" (click)="useLanguage($event,'de')" class="btn btn-sm languageButton" title="Deutsch"
               [ngClass]="{'languageButtonActive': language == 'de'}">de
            </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>
<header class="header_area" id="light-header2" *ngIf="loaded && !showMenu">
  <div class="pull-right" style="padding-top: 0.5em;">
    <div class="languageMenu" style="background-color: #dddddd">
      <a href="#" (click)="useLanguage($event,'en')" class="btn btn-sm languageButton" title="English"
         [ngClass]="{'languageButtonActive': language == 'en'}">en
      </a>
      <a href="#" (click)="useLanguage($event,'de')" class="btn btn-sm languageButton" title="Deutsch"
         [ngClass]="{'languageButtonActive': language == 'de'}">de
      </a>
    </div>
  </div>
  <ng-container *ngIf="showLogin || dataService.signInService.isLoggedIn()">
    <div class="pull-right mr-5">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" *ngIf="!dataService.signInService.isLoggedIn() && !vHostService.isVhost">
          <a class="nav-link animsition-link" href="#" (click)="login($event)" [translate]="'global.login'"
             [title]="'global.login' | translate"></a>
        </li>
        <li *ngIf="dataService.signInService.isLoggedIn()" class="nav-item" ngbDropdown>
          <a class="nav-link" href="#" style="cursor: pointer" ngbDropdownToggle
             (click)="$event.preventDefault()"
             role="button" title="">
            {{ dataService.signInService.givenName }}
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
            <a class="dropdown-item" href="#" ngbDropdownItem (click)="editProfile($event)"
               *ngIf="!dataService.signInService.isAnon"
               [translate]="'global.profile'" [title]="'global.profile' | translate"></a>
            <div class="dropdown-divider" *ngIf="!dataService.signInService.isAnon"></div>
            <a class="dropdown-item" ngbDropdownItem href="#" (click)="signOut($event)"><em
              class="icon-lock"></em> <span [translate]="'global.logout'"
                                            [title]="'global.logout' | translate"></span></a>
          </div>
        </li>
      </ul>
    </div>
  </ng-container>
  <nav class="navbar navbar-expand-lg bg-transparent">
    <a class="navbar-brand" href="/" [ngClass]="{'vhost': vHostService.isVhost, 'exhibition': isExhibition}">
      <img [src]="orgaLogo" alt="VirtualExpo Logo">
    </a>
  </nav>
</header>

