<hr>
<footer class="footer_area section_padding_100 text-center bg-gray clearfix">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="footer_content">
          <div class="foo_contents">
            <div class="footer_logo">
              <a href="/">VirtualExpo</a>
            </div>
            <div class="contact-link-line">
              <a [href]="contactLink" target="_blank" rel="noopener">{{ 'global.imprint' | translate}}</a>
              <!--            <a [href]="environment.linkTos">{{ 'global.tos' | translate}}</a> *-->
              -
              <a [href]="privacyLink" target="_blank" rel="noopener">{{ 'global.privacy' | translate}}</a>
              <ng-container *ngIf="!isProduction">
                -
                <a href="#" (click)="showPrivacy($event)">Datenschutz-Reset</a>
              </ng-container>
              <!--           *  <a [href]="environment.linkHomepage" target="_blank" rel="noopener">{{ 'global.aboutUs' | translate}}</a>-->
            </div>
          </div>
          <p>
            <i class="fa fa-copyright"></i> {{ getDate() }} by
            <a [href]="homepageLink" target="_blank" rel="noopener">dotEnterprise Ulli Berthold</a>
          </p>
          <span style="font-size: 0.5em;">Built {{ getBuildDate() }}</span>
        </div>
      </div>
    </div>
  </div>
</footer>
