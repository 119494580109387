import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {
  VisitorProfileDetailUpdateDto
} from '../../../virtual-expo-api';
import {AlertService} from '../../../services/alert.service';
import {ProfileEditSocialComponent} from '../profile-edit-social/profile-edit-social.component';
import {KeyValue} from '@angular/common';
import {TypedFormGroup} from '../../../virtual-expo-api/formgroup';
import {DataService} from '../../../services/data.service';
import {LoadingSpinnerService} from '../../../services/loading-spinner.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profile: VisitorProfileDetailUpdateDto;
  valForm: TypedFormGroup<VisitorProfileDetailUpdateDto>;
  errorMessage: string;

  modalRef: NgbModalRef;

  constructor(public activeModal: NgbActiveModal, private dataService: DataService, private alertService: AlertService
    , private modalService: NgbModal, private spinner: LoadingSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.spinner.show('profile');
    const factory = new VisitorProfileDetailUpdateDto.FormControlFactory(this.profile);
    this.valForm = new TypedFormGroup<VisitorProfileDetailUpdateDto>({
      name: factory.createFormControl<string>('name'),
      phoneNumber: factory.createFormControl<string>('phoneNumber'),
      address: factory.createFormControl<string>('address'),
      company: factory.createFormControl<string>('company'),
      role: factory.createFormControl<string>('role'),
      email: factory.createFormControl<string>('email')
    });

    this.dataService
      .webGetProfile()
      .then(value => {
        this.profile = value;
        this.valForm.reset(value);
        this.spinner.hide('profile');
      }, error => {
        this.alertService.fail();
        this.spinner.hide('profile');
        this.activeModal.close();
      });
  }

  submitForm($event: MouseEvent) {
    $event.preventDefault();
    for (const c in this.valForm.controls) {
      if (c) {
        this.valForm.controls[c].markAsTouched();
      }
    }
    if (this.valForm.valid) {
      const saveData = {...this.profile, ...this.valForm.value};
      this.dataService.webPwdLessProfileUpdate(saveData)
        .then(value => {
            this.errorMessage = '';
            this.dataService.signInService.refresh();
            this.activeModal.close();
          },
          err => {
            this.alertService.warning('An error occured trying to save your profile change: ' + err.message);
            this.errorMessage = err.message;
          }
        );
    }
  }
  //
  // onImageAdded(data: string) {
  //   this.profile.imageRaw = data;
  // }

  editSocial($event: MouseEvent, item: KeyValue<string, string>) {
    $event.preventDefault();

    this.modalRef = this.modalService.open(ProfileEditSocialComponent, {backdrop: 'static'});
    const modalComponent = this.modalRef.componentInstance as ProfileEditSocialComponent;
    modalComponent.modalRef = this.modalRef;
    modalComponent.network = item.key;
    modalComponent.value = item.value;
    modalComponent.networkUpdated.subscribe((data: { oldNetwork: string, network: string, value: string }) => {
      if (data.oldNetwork && data.oldNetwork !== '' && data.oldNetwork !== data.network) {
        delete this.profile.socialProfiles[data.oldNetwork];
      }
      this.profile.socialProfiles[data.network] = data.value;
    });
  }

  addProfile($event: MouseEvent) {
    $event.preventDefault();

    this.modalRef = this.modalService.open(ProfileEditSocialComponent, {backdrop: 'static'});
    const modalComponent = this.modalRef.componentInstance as ProfileEditSocialComponent;
    modalComponent.modalRef = this.modalRef;
    modalComponent.network = '';
    modalComponent.value = '';
    modalComponent.networkUpdated.subscribe((data: { oldNetwork: string, network: string, value: string }) => {
      if (data.oldNetwork && data.oldNetwork !== '' && data.oldNetwork !== data.network) {
        delete this.profile.socialProfiles[data.oldNetwork];
      }
      this.profile.socialProfiles[data.network] = data.value;
    });
  }

  removeSocial($event: MouseEvent, item: KeyValue<string, string>) {
    $event.preventDefault();

    delete this.profile.socialProfiles[item.key];
  }

  close($event: MouseEvent) {
    $event.preventDefault();

    this.activeModal.close();
  }
}
