import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from '../../../services/data.service';

@Component({
  selector: 'app-password-less-login',
  templateUrl: './password-less-login.component.html',
  styleUrls: ['./password-less-login.component.scss']
})
export class PasswordLessLoginComponent implements OnInit {
  submitted: boolean;

  environment = environment;

  constructor(
    public activeModal: NgbActiveModal
    , private fb: FormBuilder
    , private router: Router
    , private dataService: DataService
    , private translateService: TranslateService
  ) {
  }

  valForm: FormGroup;
  valForm2: FormGroup;
  errorMessage: string;
  privacyLink: string;

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      if (c) {
        this.valForm.controls[c].markAsTouched();
      }
    }
    if (this.valForm.valid) {
      this.dataService.signInService.startPwdLess(this.valForm.controls['email'].value)
        .subscribe(res => {
            this.errorMessage = '';
            this.submitted = true;
          },
          error => {
            // console.log(error);
            this.errorMessage = 'An error occurred, please try again.';
          }
        );
    }
  }

  ngOnInit() {
    this.translateService.get('gdpr.linkPrivacy')
      .subscribe(value => {
        this.privacyLink = value;
      });

    this.valForm = this.fb.group({
      'email': [null, Validators.compose([Validators.required, Validators.email])]
    });

    this.valForm2 = this.fb.group({
      'token': [null, Validators.compose([Validators.required])]
    });


    if (this.dataService.signInService.isLoggedIn()) {
      this.dataService.signInService.logout();
    }
  }

  submitToken($event: Event) {
    $event.preventDefault();
    for (const c in this.valForm2.controls) {
      if (c) {
        this.valForm2.controls[c].markAsTouched();
      }
    }

    if (this.valForm2.valid) {
      this.dataService.signInService.validateTotpPwdLess(this.valForm.controls['email'].value, this.valForm2.controls['token'].value)
        .subscribe(res => {
            this.errorMessage = '';
            const authData = this.dataService.signInService.getTokenData();
            const nameId = authData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
            const givenName = authData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
            if (nameId === givenName) {
              this.router.navigate(['profile']);
            } else {
              location.reload();
            }
          },
          error => {
            this.errorMessage = 'Invalid, please try again.';
          }
        );
    }
  }
}
