/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ExhibitorGroupReferenceDto } from './exhibitorGroupReferenceDto';


export interface ExhibitorReferenceDto { 
    id?: string;
    shortKey?: string;
    name?: string;
    company?: string;
    exhibitorGroup?: ExhibitorGroupReferenceDto;
    sequence?: number;
}

/**
 * Namespace for property- and property-value-enumerations of ExhibitorReferenceDto.
 */
export namespace ExhibitorReferenceDto {
    /**
     * All properties of ExhibitorReferenceDto.
     */
    export enum Properties {
        id = 'id',
        shortKey = 'shortKey',
        name = 'name',
        company = 'company',
        exhibitorGroup = 'exhibitorGroup',
        sequence = 'sequence'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExhibitorReferenceDto.
    */
    export const ModelValidators: {[K in keyof ExhibitorReferenceDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        shortKey: [
        ],
        name: [
        ],
        company: [
        ],
        exhibitorGroup: [
        ],
        sequence: [
        ],
    };

    /**
    * The FormControlFactory for ExhibitorReferenceDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExhibitorReferenceDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExhibitorReferenceDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExhibitorReferenceDto = {
            id: null,
            shortKey: null,
            name: null,
            company: null,
            exhibitorGroup: null,
            sequence: null,
          }
        ) {
            super(model, ExhibitorReferenceDto.ModelValidators);
        }
    }

}


