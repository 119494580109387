<div class="modal-header">
  <h4 class="modal-title" [translate]="'login.header'"></h4>
  <a class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </a>
</div>
<div class="modal-body">
  <ng-container *ngIf="!error">
    <p [translate]="'login.confirmWait1'">
    </p>
    <p [translate]="'login.confirmWait2'">
    </p>
  </ng-container>
  <ng-container *ngIf="error">
    <p [translate]="'login.confirmError1'">

    </p>
    <p [translate]="'login.confirmError2'">

    </p>
  </ng-container>
</div>
