import {Component, OnInit} from '@angular/core';
import {
  CalendarEntryStageDto,
  ExpoSimpleListItemDto
} from '../../virtual-expo-api';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageUrlService} from '../booths/image-url.service';
import {TranslateService} from '@ngx-translate/core';
import {MarkdownService} from 'ngx-markdown';
import {Router} from '@angular/router';
import {LinkHandlerService} from '../../services/link-handler.service';
import {AlertService} from '../../services/alert.service';
import {ProfileService} from '../../services/profile.service';
import {ICalendarEvent} from '../../modules/ngAddToCalendar/model/calendar-event.model';
import {CalendarTypeEnum} from '../../modules/ngAddToCalendar/model/calendar-type.enum';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {NgAddToCalendarService} from '../../modules/ngAddToCalendar/service/ng-add-to-calendar.service';
import {CalendarEntryStageDtoExtended} from '../../objects/calendar-entry-stage-dto-extended';
import {ExpoOverviewExtended} from '../../objects/expo-overview-extended';
import {TrackingService} from '../../services/tracking.service';
import {DataService} from '../../services/data.service';
import {CalendarEntryBoothDtoExtended} from '../../objects/calendar-entry-booth-dto-extended';

const dayjs = require('dayjs');

@Component({
  selector: 'app-expo-calendar',
  templateUrl: './expo-calendar.component.html',
  styleUrls: ['./expo-calendar.component.scss']
})
export class ExpoCalendarComponent implements OnInit {
  expo: ExpoOverviewExtended;
  groupedCalendar: { [p: string]: CalendarEntryStageDtoExtended[] } = {};
  groupedPresentations: { [p: string]: CalendarEntryBoothDtoExtended[] } = {};

  activeDay: string;
  currentDay: Array<CalendarEntryStageDtoExtended> = new Array<CalendarEntryStageDtoExtended>();
  currentPresentations: Array<CalendarEntryBoothDtoExtended> = new Array<CalendarEntryBoothDtoExtended>();
  dateFormat: string;
  dayFormat: string;

  dayList: Array<string> = new Array<string>();

  idMapping: { [id: string]: string } = {};
  dayViewStage: { [day: string]: { [id: string]: Array<CalendarEntryStageDtoExtended> } } = {};
  dayViewBooth: { [day: string]: { [id: string]: Array<CalendarEntryBoothDtoExtended> } } = {};

  currentStage: Array<CalendarEntryStageDtoExtended> = new Array<CalendarEntryStageDtoExtended>();
  currentBooth: Array<CalendarEntryBoothDtoExtended> = new Array<CalendarEntryBoothDtoExtended>();

  activeStage: string;
  activeBooth: string;

  mode = 0;
  view = 0;

  constructor(
    public activeModal: NgbActiveModal
    , private imageUrlService: ImageUrlService
    , private translateService: TranslateService
    , private markdownService: MarkdownService
    , private sanitizer: DomSanitizer
    , private addToCalendarService: NgAddToCalendarService
    , private linkHandlerService: LinkHandlerService
    , private router: Router
    , private alertService: AlertService
    , private profileService: ProfileService
    , private trackingService: TrackingService
    , private dataService: DataService
  ) {
  }

  ngOnInit(): void {
    this.translateService.get('calendar.dateFormat').subscribe(value => {
      this.dateFormat = value;
    });
    this.translateService.get('calendar.dayFormat').subscribe(value => {
      this.dayFormat = value;
    });

    if (this.expo) {
      this.allEvents();
    } else {
      this.expo = this.dataService.currentExpo;
      if (this.profileService.currentCalendar.length > 0) {
        this.myEvents();
      } else {
        this.allEvents();
      }
    }

    this.profileService.changes.subscribe(value => {
      if (this.mode === 1) {
        this.groupCalendar();
      }
    });
  }

  groupCalendar() {
    const currentDate = dayjs(Date.now()).format('YYYY-MM-DD').toString();

    this.activeDay = '';
    if (Object.keys(this.groupedCalendar).length > 0 || Object.keys(this.groupedPresentations).length > 0) {
      let allKeys: Array<string> = Object.keys(this.groupedCalendar);
      allKeys = allKeys.sort((a, b) => {
        return a > b ? 1 : -1;
      });

      allKeys.forEach(value => {
        if (value === currentDate) {
          this.activeDay = currentDate;
        }
      });
      Object.keys(this.groupedPresentations).forEach(value => {
        if (value === currentDate) {
          this.activeDay = currentDate;
        }
      });
      if (this.activeDay === '') {
        allKeys.forEach(value => {
          if (value > currentDate && (this.activeDay === '' || this.activeDay > value)) {
            this.activeDay = value;
          }
        });
        Object.keys(this.groupedPresentations).forEach(value => {
          if (value > currentDate && (this.activeDay === '' || this.activeDay > value)) {
            this.activeDay = value;
          }
        });
      }
      if (this.activeDay === '') {
        this.activeDay = allKeys[allKeys.length - 1];
      }
      if (!this.activeDay || this.activeDay === '') {
        this.activeDay = currentDate;
      }
      if (this.groupedCalendar[this.activeDay]) {
        this.currentDay.length = 0;
        this.groupedCalendar[this.activeDay].forEach(value => {
          this.currentDay.push(value);
        });
      } else {
        this.currentDay.length = 0;
      }
      if (this.groupedPresentations[this.activeDay]) {
        this.currentPresentations.length = 0;
        this.groupedPresentations[this.activeDay].forEach(value => {
          this.currentPresentations.push(value);
        })
      } else {
        this.currentPresentations.length = 0;
      }
    } else {
      this.currentDay.length = 0;
      this.currentPresentations.length = 0;
    }
    if (!this.activeDay || this.activeDay === '') {
      this.activeDay = currentDate;
    }

    Object.keys(this.idMapping).forEach(day => {
      delete this.idMapping[day];
    });

    Object.keys(this.dayViewStage).forEach(day => {
      delete this.dayViewStage[day];
    });

    Object.keys(this.dayViewBooth).forEach(day => {
      delete this.dayViewBooth[day];
    });

    this.expo.stages.forEach(stage => {
      this.idMapping[stage.id] = stage.name;
    });

    this.expo.exhibitors.forEach(exhibitor => {
      this.idMapping[exhibitor.id] =
        exhibitor.shortLabel && exhibitor.shortLabel.trim() !== '' ? exhibitor.shortLabel :
          exhibitor.label && exhibitor.label.trim() !== '' ? exhibitor.label : exhibitor.name;
    });

    Object.keys(this.groupedCalendar).forEach(day => {
      if (!this.dayViewStage[day]) {
        this.dayViewStage[day] = {};
        Object.keys(this.idMapping).forEach(id => {
          this.dayViewStage[day][id] = new Array<CalendarEntryStageDtoExtended>();
        });
      }
      this.groupedCalendar[day].forEach(value => {
        this.dayViewStage[day][value.stageId].push(value);
      });
    });

    Object.keys(this.groupedPresentations).forEach(day => {
      if (!this.dayViewBooth[day]) {
        this.dayViewBooth[day] = {};
        Object.keys(this.idMapping).forEach(id => {
          this.dayViewBooth[day][id] = new Array<CalendarEntryBoothDtoExtended>();
        });
      }
      this.groupedPresentations[day].forEach(value => {
        if (!this.dayViewBooth[day]) {
          this.dayViewBooth[day] = {};
        }
        if (!this.dayViewBooth[day][value.exhibitorId]) {
          this.dayViewBooth[day][value.exhibitorId] = new Array<CalendarEntryBoothDtoExtended>();
        }
        this.dayViewBooth[day][value.exhibitorId].push(value);
        if (!this.idMapping[value.exhibitorId]) {
          this.idMapping[value.exhibitorId] = value.exhibitorName;
        }
      });
    });

    Object.keys(this.dayViewStage).forEach(day => {
      Object.keys(this.dayViewStage[day]).forEach(id => {
        if (this.dayViewStage[day][id].length === 0) {
          delete this.dayViewStage[day][id];
        }
      });
      if (Object.keys(this.dayViewStage[day]).length === 0) {
        delete this.dayViewStage[day];
      }
    });

    Object.keys(this.dayViewBooth).forEach(day => {
      Object.keys(this.dayViewBooth[day]).forEach(id => {
        if (this.dayViewBooth[day][id].length === 0) {
          delete this.dayViewBooth[day][id];
        }
      });
      if (Object.keys(this.dayViewBooth[day]).length === 0) {
        delete this.dayViewBooth[day];
      }
    });

    this.activateFirstItem();
  }

  activateFirstItem() {
    this.activeStage = '';
    this.activeBooth = '';

    if (this.dayViewStage[this.activeDay]) {
      this.expo.stages.forEach(stage => {
        if ((!this.activeStage || this.activeStage === '') && this.dayViewStage[this.activeDay][stage.id]) {
          this.activeStage = stage.id;
        }
      });
    }
    if (this.dayViewBooth[this.activeDay] && (!this.activeStage || this.activeStage === '')) {
      this.expo.exhibitors.forEach(booth => {
        if ((!this.activeBooth || this.activeBooth === '') && this.dayViewBooth[this.activeDay][booth.id]) {
          this.activeBooth = booth.id;
        }
      });
      if (!this.activeBooth) {
        this.activeBooth = Object.keys(this.dayViewBooth[this.activeDay])[0];
      }
    }
  }

  formatDay(start: string): string {
    return dayjs(start).format(this.dayFormat);
  }

  formatTime(start: Date): string {
    return dayjs(start).format(this.dateFormat);
  }

  selectDate($event: MouseEvent, item: string) {
    $event.preventDefault();
    this.currentDay = this.groupedCalendar[item];
    this.currentPresentations = this.groupedPresentations[item];
    this.activeDay = item;
  }

  openStage($event: MouseEvent, item: CalendarEntryStageDtoExtended) {
    $event.preventDefault();

    if (this.isFuture(this.expo) && this.expo.isPreviewActive) {
      this.alertService.info(this.translateService.instant('global.notOpenYet'));
    } else if (this.isPast(this.expo) && this.expo.isPreviewActive) {
      this.alertService.info(this.translateService.instant('global.notOpenStill'));
    } else if (this.expo.isPreviewActive) {
      this.alertService.info(this.translateService.instant('global.preview'));
    }
    this.linkHandlerService.navigateStage(this.expo, {
      name: item.stageName,
      shortKey: item.stageShortKey
    }, item.id).then(value => {
      this.activeModal.dismiss();
    });
  }

  openExhibitor($event: MouseEvent, item: CalendarEntryBoothDtoExtended) {
    $event.preventDefault();

    if (this.isFuture(this.expo) && this.expo.isPreviewActive) {
      this.alertService.info(this.translateService.instant('global.notOpenYet'));
    } else if (this.isPast(this.expo) && this.expo.isPreviewActive) {
      this.alertService.info(this.translateService.instant('global.notOpenStill'));
    } else if (this.expo.isPreviewActive) {
      this.alertService.info(this.translateService.instant('global.preview'));
    }
    this.linkHandlerService.navigateExhibitor(this.expo, {name: item.exhibitorName, shortKey: item.exhibitorShortKey})
      .then(value => {
        this.activeModal.dismiss();
      })
    ;
  }

  private isFuture(expo: ExpoSimpleListItemDto) {
    const current = new Date();
    return dayjs(expo.dateStart).toDate() > current;
  }

  private isPast(expo: ExpoSimpleListItemDto) {
    const current = new Date();
    return dayjs(expo.dateEnd).toDate() < current;
  }

  isSelected(item: CalendarEntryStageDto) {
    return this.profileService.selectedCalendar.indexOf(item.id) >= 0;
  }

  selectItem($event: MouseEvent, item: CalendarEntryStageDtoExtended) {
    $event.preventDefault();
    if (this.profileService.selectedCalendar.indexOf(item.id) >= 0) {
      this.trackingService.trackEvent(this.expo, null, 'Calendar', 'Bookmark remove', item.label);
      this.profileService.removeCalendar(item);
    } else {
      this.trackingService.trackEvent(this.expo, null, 'Calendar', 'Bookmark add', item.label);
      this.profileService.addCalendar(item);
    }
  }

  isSelectedExhibitor(item: CalendarEntryBoothDtoExtended) {
    return this.profileService.selectedCalendarExhibitor.indexOf(item.id) >= 0;
  }

  selectItemExhibitor($event: MouseEvent, item: CalendarEntryBoothDtoExtended) {
    $event.preventDefault();
    if (this.profileService.selectedCalendarExhibitor.indexOf(item.id) >= 0) {
      this.trackingService.trackEvent(this.expo, null, 'Calendar', 'Bookmark remove', item.label);
      this.profileService.removeCalendarExhibitor(item);
    } else {
      this.trackingService.trackEvent(this.expo, null, 'Calendar', 'Bookmark add', item.label);
      this.profileService.addCalendarExhibitor(item);
    }
  }

  getDate(input: string): string {
    const dateOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };

    const date = dayjs(input).toDate();
    return date.toLocaleDateString([], dateOptions);
  }

  allEvents($event?: MouseEvent) {
    if ($event) {
      $event.preventDefault();
    }
    this.mode = 0;
    let keys = Object.keys(this.groupedCalendar);
    keys.forEach(value => {
      delete this.groupedCalendar[value];
    });
    keys = Object.keys(this.expo.listCalendar);
    keys.forEach(key => {
      this.groupedCalendar[key] = [];
      this.expo.listCalendar[key].forEach(value => {
        this.groupedCalendar[key].push(value);
      });
    });

    keys = Object.keys(this.groupedPresentations);
    keys.forEach(value => {
      delete this.groupedPresentations[value];
    });
    keys = Object.keys(this.expo.groupedPresentations);
    keys.forEach(day => {
      this.groupedPresentations[day] = [];
      const exhibitors = Object.keys(this.expo.groupedPresentations[day]);
      exhibitors.forEach(exhibitor => {
        this.expo.groupedPresentations[day][exhibitor].forEach(item => {
          this.groupedPresentations[day].push(item);
        });
      });
      this.groupedPresentations[day] = this.groupedPresentations[day].sort((a, b) => {
        return a.start > b.start ? 1 : a.start < b.start ? -1 : 0;
      });
    });

    this.dayList.length = 0;
    this.expo.allDays.forEach(value => {
      this.dayList.push(value);
    });

    this.groupCalendar();
  }

  myEvents($event?: MouseEvent) {
    if ($event) {
      $event.preventDefault();
    }
    this.mode = 1;

    this.dayList.length = 0;

    let keys = Object.keys(this.groupedCalendar);
    keys.forEach(value => {
      delete this.groupedCalendar[value];
    });
    this.profileService.profile.calendar.forEach(calendarEntryStageDto => {
      if (this.profileService.currentCalendar.indexOf(calendarEntryStageDto.id) >= 0) {
        const tzStamp = dayjs(calendarEntryStageDto.start);
        const dayLabel: string = tzStamp.format('YYYY-MM-DD').toString();

        if (this.dayList.indexOf(dayLabel) === -1) {
          this.dayList.push(dayLabel);
        }

        if (!this.groupedCalendar[dayLabel]) {
          this.groupedCalendar[dayLabel] = new Array<CalendarEntryStageDtoExtended>();
        }
        const newItem: CalendarEntryStageDtoExtended = calendarEntryStageDto as CalendarEntryStageDtoExtended;
        newItem.linkGoogle = this.getDownloadGoogle(newItem);
        newItem.linkIcal = this.getDownloadIcal(newItem);
        newItem.linkOutlook = this.getDownloadOutlook(newItem);
        newItem.description = this.markdownService.compile(newItem.description);
        this.groupedCalendar[dayLabel].push(newItem);
      }
    });

    keys = Object.keys(this.groupedPresentations);
    keys.forEach(value => {
      delete this.groupedPresentations[value];
    });
    this.profileService.profile.calendarExhibitor.forEach(item => {
      if (this.profileService.selectedCalendarExhibitor.indexOf(item.id) >= 0) {
        const tzStamp = dayjs(item.start);
        const dayLabel: string = tzStamp.format('YYYY-MM-DD').toString();

        if (this.dayList.indexOf(dayLabel) === -1) {
          this.dayList.push(dayLabel);
        }

        if (!this.groupedPresentations[dayLabel]) {
          this.groupedPresentations[dayLabel] = new Array<CalendarEntryBoothDtoExtended>();
        }
        const newItem: CalendarEntryBoothDtoExtended = item as CalendarEntryBoothDtoExtended;
        this.groupedPresentations[dayLabel].push(newItem);
      }
    });
    keys = Object.keys(this.groupedPresentations);
    keys.forEach(dayLabel => {
      this.groupedPresentations[dayLabel] = this.groupedPresentations[dayLabel].sort((a, b) => {
        return a.start > b.start ? 1 : a.start < b.start ? -1 : 0;
      });
    });

    this.dayList = this.dayList.sort((a, b) => a > b ? 1 : a < b ? -1 : 0);

    this.groupCalendar();
  }

  isDatePast(item: { start?: Date }) {
    const current = new Date();
    return dayjs(item.start).toDate() < current;
  }

  //
  private getStageLink(entry: CalendarEntryStageDto): string {
    if (this.expo.isPreviewActive) {
      return '#';
    }
    return this.linkHandlerService.getStageUrl(this.expo, {
      name: entry.stageName,
      shortKey: entry.stageShortKey
    }, entry.id);
  }

  //
  getCalEvent(item: CalendarEntryStageDto): ICalendarEvent {
    return {
      // Event title
      title: item.label,
      // Event start date
      start: dayjs(item.start).toDate(),
      // Event duration (IN MINUTES)
      duration: item.duration,
      // If an end time is set, this will take precedence over duration (optional)
      // end: new Date('June 15, 2013 23:00'),
      // Event Address (optional)
      // address: this.getStageLink(this.entry),
      // Event Description (optional)
      description: item.description,
      url: this.getStageLink(item),
    };
  }

  getDownloadIcal(item: CalendarEntryStageDto): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(CalendarTypeEnum.iCalendar, this.getCalEvent(item))
    );
  }

  getDownloadGoogle(item: CalendarEntryStageDto): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(CalendarTypeEnum.google, this.getCalEvent(item))
    );
  }

  getDownloadOutlook(item: CalendarEntryStageDto): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(CalendarTypeEnum.google, this.getCalEvent(item))
    );
  }

  trackCalendarAdd($event: MouseEvent) {
    const label = ($event.currentTarget as any).innerText;
    this.trackingService.trackEvent(this.expo, null, 'Calendar', 'Download', label);
  }

  setDayView($event: MouseEvent) {
    $event.preventDefault();
    this.view = 0;
    this.activateFirstItem();
  }

  setListView($event: MouseEvent) {
    $event.preventDefault();
    this.view = 1;
  }

  selectStage($event: MouseEvent, name: string) {
    $event.preventDefault();
    this.activeStage = name;
    this.activeBooth = '';
  }

  selectExhibitor($event: MouseEvent, name: string) {
    $event.preventDefault();
    this.activeStage = '';
    this.activeBooth = name;
  }

  dayBack($event: MouseEvent) {
    $event.preventDefault();

    const index = this.dayList.indexOf(this.activeDay) - 1;

    if (index >= 0) {
      this.activeDay = this.dayList[index];
      this.currentDay = this.groupedCalendar[this.activeDay];
      this.currentPresentations = this.groupedPresentations[this.activeDay];

      this.activateFirstItem();
    }
  }

  dayForward($event: MouseEvent) {
    $event.preventDefault();

    const index = this.dayList.indexOf(this.activeDay) + 1;

    if (index < this.dayList.length) {
      this.activeDay = this.dayList[index];
      this.currentDay = this.groupedCalendar[this.activeDay];
      this.currentPresentations = this.groupedPresentations[this.activeDay];

      this.activateFirstItem();
    }
  }
}
