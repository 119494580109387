import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ExpoHall2Component} from '../../components/expo-hall2/expo-hall2.component';
import {SharedModule} from '../shared/shared.module';
import {GdprPermissionsComponent} from '../../components/components/gdpr-permissions/gdpr-permissions.component';
import {SessionDayComponent} from '../../components/expo-overview/session-day/session-day.component';
import {CalendarEntryComponent} from '../../components/expo-overview/calendar-entry/calendar-entry.component';
import {StageDayComponent} from '../../components/expo-overview/stage-day/stage-day.component';
import {SessionEntryComponent} from '../../components/expo-overview/session-entry/session-entry.component';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ControlValidationResultsComponent} from '../../components/components/control-validation-results/control-validation-results.component';
import {PopupMessageComponent} from '../../components/components/popup-message/popup-message.component';
import {UserChatComponent} from '../../components/components/user-chat/user-chat.component';
import {UserChatSessionComponent} from '../../components/components/user-chat-session/user-chat-session.component';
import {ModalIFrameComponent} from '../../components/modal-iframe/modal-iframe.component';
import {ExpoEntryComponent} from '../../components/components/expo-entry/expo-entry.component';
import {UpcomingCalendarComponent} from '../../components/components/upcoming-calendar/upcoming-calendar.component';
import {VideoCallComponent} from '../../components/components/video-call/video-call.component';
import {LinkFacebookComponent} from '../../components/components/link-facebook/link-facebook.component';
import {LinkTwitterComponent} from '../../components/components/link-twitter/link-twitter.component';
import {LinkXingComponent} from '../../components/components/link-xing/link-xing.component';
import {LinkLinkedinComponent} from '../../components/components/link-linkedin/link-linkedin.component';
import {LinkPhoneComponent} from '../../components/components/link-phone/link-phone.component';
import {LinkEmailComponent} from '../../components/components/link-email/link-email.component';
import {LinkSocialComponent} from '../../components/components/link-social/link-social.component';
import {BoothIconComponent} from '../../components/components/booth-icon/booth-icon.component';
import {GroupIconComponent} from '../../components/components/group-icon/group-icon.component';
import {StageIconComponent} from '../../components/components/stage-icon/stage-icon.component';
import {ExpoCalendarComponent} from '../../components/expo-calendar/expo-calendar.component';
import {ActionButtonComponent} from '../../components/components/action-button/action-button.component';
import {ExpoButtonsComponent} from '../../components/components/expo-buttons/expo-buttons.component';
import {SurveyComponent} from '../../components/components/survey/survey.component';



@NgModule({
  declarations: [
    ExpoHall2Component,
    CalendarEntryComponent,
    StageDayComponent,
    SessionDayComponent,
    SessionEntryComponent,
    ControlValidationResultsComponent,
    PopupMessageComponent,
    UserChatComponent,
    UserChatSessionComponent,
    ModalIFrameComponent,
    ExpoEntryComponent,
    UpcomingCalendarComponent,
    VideoCallComponent,
    LinkFacebookComponent,
    LinkTwitterComponent,
    LinkXingComponent,
    LinkLinkedinComponent,
    LinkPhoneComponent,
    LinkEmailComponent,
    LinkSocialComponent,
    BoothIconComponent,
    GroupIconComponent,
    StageIconComponent,
    ExpoCalendarComponent,
    ActionButtonComponent,
    ExpoButtonsComponent,
    SurveyComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  exports: [
    ExpoHall2Component,
    CalendarEntryComponent,
    StageDayComponent,
    SessionDayComponent,
    SessionEntryComponent,
    ControlValidationResultsComponent,
    PopupMessageComponent,
    UserChatComponent,
    UserChatSessionComponent,
    ModalIFrameComponent,
    ExpoEntryComponent,
    UpcomingCalendarComponent,
    VideoCallComponent,
    LinkFacebookComponent,
    LinkTwitterComponent,
    LinkXingComponent,
    LinkLinkedinComponent,
    LinkPhoneComponent,
    LinkEmailComponent,
    LinkSocialComponent,
    BoothIconComponent,
    GroupIconComponent,
    StageIconComponent,
    ExpoCalendarComponent,
    ActionButtonComponent,
    ExpoButtonsComponent,
    SurveyComponent
  ]
})
export class ExpoCommonModule { }
