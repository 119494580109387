import {Injectable} from '@angular/core';
import {HttpUrlEncodingCodec} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ImageUrlService} from '../components/booths/image-url.service';
import {Router} from '@angular/router';
import {SignInService} from './sign-in.service';

@Injectable({
  providedIn: 'root'
})
export class LinkHandlerService {

  constructor(
    private codec: HttpUrlEncodingCodec
    , private imageUrlService: ImageUrlService
    , private router: Router
    , private signInService: SignInService
  ) {
  }

  // private static combineNameAndShortKey(name: string, shortKey: string): string {
  //   name = name.trim();
  //   name = name.replace(/ /g, '-');
  //   name = name.replace(/_/g, '-');
  //   name = name.replace(/[^a-zA-Z0-9äÄöÖüÜß]/g, '-');
  //   name = name.substr(0, 39);
  //   // return this.codec.encodeKey(name) + '_' + shortKey;
  //   return name + '_' + shortKey;
  // }

  private static prepareItem(item: LinkObject): string {
    let name = item.name.trim();
    name = name.replace(/ /g, '-');
    name = name.replace(/_/g, '-');
    name = name.replace(/[^a-zA-Z0-9äÄöÖüÜß]/g, '-');
    name = name.substr(0, 39);
    // return this.codec.encodeKey(name) + '_' + shortKey;
    return name + '_' + item.shortKey;
  }

  prepare(name: string, shortKey: string): string {
    return LinkHandlerService.prepareItem({name: name, shortKey: shortKey});
  }

  parse(path: string): string {
    path = path.replace(/.*_/, '');
    return path;
  }

  cacheTag(): string {
    return this.imageUrlService.cacheTag();
  }

  getExpoUrl(expo: LinkObject): string {
    return `/exhibition/${LinkHandlerService.prepareItem(expo)}`;
  }

  getExpoLockedUrl(expo: LinkObject): string {
    return `/expo/${LinkHandlerService.prepareItem(expo)}`;
  }

  navigateExpo(expo: LinkObject): Promise<boolean> {
    return this.router.navigate(['/', 'exhibition', LinkHandlerService.prepareItem(expo)]);
  }

  navigateExpoLocked(expo: ExpoLinkObject): Promise<boolean> {
    if (expo.accessMode >= 10) {
      return this.router.navigate(['/', 'expo', LinkHandlerService.prepareItem(expo)]);
    } else {
      return this.navigateExpo(expo);
    }
  }

  getExhibitorUrl(expo: LinkObject, exhibitor: LinkObject): string {
    return `${this.getExpoUrl(expo)}/${LinkHandlerService.prepareItem(exhibitor)}`;
  }

  private navigateExhibitorGo(expo: ExpoLinkObject, exhibitor: LinkObject): Promise<boolean> {
    return this.router.navigate(['/', 'exhibition', LinkHandlerService.prepareItem(expo), LinkHandlerService.prepareItem(exhibitor)]);
  }

  navigateExhibitor(expo: ExpoLinkObject, exhibitor: LinkObject): Promise<boolean> {
    if (expo.accessMode > 1 && !this.imageUrlService.noCache) {
      // console.log('access mode, need token');
      this.signInService.getToken().then(token => {
        // console.log('access mode, got token');
        return this.navigateExhibitorGo(expo, exhibitor);
      }, reason => {
        // console.log('access mode, no token');
        return this.navigateExpo(expo);
      });
    } else {
      // console.log('no access mode');
      return this.navigateExhibitorGo(expo, exhibitor);
    }
  }

  getStageUrl(expo: LinkObject, stage: LinkObject, id?: number): string {
    let url = `${this.getExpoUrl(expo)}/stage/${LinkHandlerService.prepareItem(stage)}`;
    if (id) {
      url = `${url}/${id}`;
    }
    return url;
  }

  private navigateStageGo(expo: ExpoLinkObject, stage: LinkObject, id?: number): Promise<boolean> {
    if (id) {
      return this.router.navigate(['/', 'exhibition', LinkHandlerService.prepareItem(expo), 'stage'
        , LinkHandlerService.prepareItem(stage), id]);
    } else {
      return this.router.navigate(['/', 'exhibition', LinkHandlerService.prepareItem(expo), 'stage'
        , LinkHandlerService.prepareItem(stage)]);
    }
  }

  navigateStage(expo: ExpoLinkObject, stage: LinkObject, id?: number): Promise<boolean> {
    if (expo.accessMode > 1 && !this.imageUrlService.noCache) {
      if (this.signInService.isLoggedIn()) {
        return this.navigateStageGo(expo, stage, id);
      } else {
        this.signInService.getToken().then(token => {
          return this.navigateStageGo(expo, stage, id);
        }, reason => {
          return this.navigateExpo(expo);
        });
      }
    } else {
      return this.navigateStageGo(expo, stage, id);
    }
  }

  getSessionUrl(expo: LinkObject, id: number): string {
    let url = `${this.getExpoUrl(expo)}/session/${id}`;
    if (id) {
      url = `${url}/${id}`;
    }
    return url;
  }

  navigateSession(expo: LinkObject, id: number): Promise<boolean> {
    return this.router.navigate(['/', 'exhibition', LinkHandlerService.prepareItem(expo), 'session', id]);
  }
}

export interface LinkObject {
  name?: string | null;
  shortKey?: string | null;
}

export interface ExpoLinkObject extends LinkObject {
  accessMode?: number;
}
