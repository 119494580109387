/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ExpoBookedListDto { 
    expoId?: string;
    permitContact?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoBookedListDto.
 */
export namespace ExpoBookedListDto {
    /**
     * All properties of ExpoBookedListDto.
     */
    export enum Properties {
        expoId = 'expoId',
        permitContact = 'permitContact'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoBookedListDto.
    */
    export const ModelValidators: {[K in keyof ExpoBookedListDto]: [string, ValidatorFn][]} = {
        expoId: [
        ],
        permitContact: [
        ],
    };

    /**
    * The FormControlFactory for ExpoBookedListDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoBookedListDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoBookedListDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoBookedListDto = {
            expoId: null,
            permitContact: null,
          }
        ) {
            super(model, ExpoBookedListDto.ModelValidators);
        }
    }

}


