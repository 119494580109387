import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {MarkdownService} from 'ngx-markdown';
import {TranslateService} from '@ngx-translate/core';
import {ImageUrlService} from '../booths/image-url.service';
import {VHostService} from '../../services/vhost.service';
import {ExpoDesignService} from '../../services/expo-design.service';
import {DesignDefault} from '../../objects/expo-design';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  headerImage: string;
  headerContent: string;

  showExpos = false;

  constructor(
    private translateService: TranslateService
    , private markdownService: MarkdownService
    , private designService: ExpoDesignService
    , private dataService: DataService
    , private imageUrlService: ImageUrlService
    , private vHostService: VHostService
  ) {
  }

  ngOnInit() {
    this.dataService.setDefaultTheme();
    if (this.vHostService.loaded) {
      this.showExpos = !this.vHostService.isVhost;
      // console.log(`showExpos: ${this.showExpos}`);
    } else {
      this.vHostService.IsLoaded
        .subscribe(() => {
          this.showExpos = !this.vHostService.isVhost;
          // console.log(`showExpos delayed: ${this.showExpos}`);
        });
    }
    this.updateValues();
    this.translateService.onLangChange.subscribe(() => {
      this.updateValues();
    });
    this.translateService.onTranslationChange.subscribe(() => {
      this.updateValues();
    });
  }

  updateValues() {
    this.translateService.get('home.virtualenvironments.content')
      .subscribe(value => {
        this.headerContent = this.markdownService.compile(value);
      });
    this.headerImage = `url(${this.imageUrlService.getAsset('loading.png')})`;
  }

  is3dEnabled() {
    return environment.enable3D;
  }
}
