<div class="wrapper">
  <div class="block-center mt-xl wd-xl account-content">
    <!-- START panel-->
    <div class="panel panel-dark panel-flat">
      <app-account-header></app-account-header>
      <div class="panel-body">
        <router-outlet></router-outlet>
      </div>
      <app-account-footer></app-account-footer>
    </div>
  </div>
</div>
