import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {environment} from '../../environments/environment';
import {WindowService} from './window.service';
import {isPlatformBrowser} from '@angular/common';
import {ExpoExhibitorListItemDto, TrackingSettings} from '../virtual-expo-api';
import {DeviceDetectorService} from 'ngx-device-detector';
import {TrackRedirectService} from './track-redirect.service';
import {ConfirmationDialogService} from '../components/components/confirmation-dialog/confirmation-dialog.service';
import {GdprService} from './gdpr.service';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';

declare let gtag: any;

declare let _mtm: any;
declare let _paq: any;
declare let Matomo: any;

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  isInit = false;

  matomoLoaded = false;
  googleLoaded = false;

  matomoCache: { [key: string]: any } = {};

  constructor(
    @Inject(PLATFORM_ID) private platformId
    , private _window: WindowService
    , private deviceDetector: DeviceDetectorService
    , private confirmationService: ConfirmationDialogService
    , private gdprService: GdprService
  ) {
    if (isPlatformBrowser(this.platformId) && deviceDetector.userAgent.indexOf('HeadlessChrome') < 0) {
      this.gdprService.check()
        .then(value => {
          this.loadAnalytics();
        });

      this.gdprService.permissionsUpdated
        .subscribe(value => {
          this.loadAnalytics();
        });

      this.loadMatomo();
    }
  }

  trackPageView(expoSettings: TrackingSettings, exhibitorSettings: TrackingSettings, customTitle: string, customPath: string) {
    if (isPlatformBrowser(this.platformId) && this.deviceDetector.userAgent.indexOf('HeadlessChrome') < 0) {
      if (environment.googleActive) {
        this.loadAnalytics();
        if (typeof (gtag) !== 'undefined') {
          if (expoSettings.googleAnalyticsId) {
            gtag('config', expoSettings.googleAnalyticsId,
              {
                'page_path': customTitle
              }
            );
          }
          if (exhibitorSettings && exhibitorSettings.googleAnalyticsId) {
            gtag('config', exhibitorSettings.googleAnalyticsId,
              {
                'page_path': customTitle
              }
            );
          }
        }
      }

      if (environment.matomoActive) {
        try {
          _paq.push(['setCustomUrl', customPath]);
          _paq.push(['setDocumentTitle', customTitle]);
          _paq.push(['trackPageView']);

          if (expoSettings) {
            const expoSecondary = this.getSecondaryMatomoTracker(expoSettings);
            if (expoSecondary) {
              expoSecondary.trackPageView(customTitle);
            }

            const expoExternal = this.getExternalMatomoTracker(expoSettings);
            if (expoExternal) {
              expoExternal.trackPageView(customTitle);
            }
          }

          if (exhibitorSettings) {
            const exhibitorSecondary = this.getSecondaryMatomoTracker(exhibitorSettings);
            if (exhibitorSecondary) {
              exhibitorSecondary.trackPageView(customTitle);
            }

            const exhibitorExternal = this.getExternalMatomoTracker(exhibitorSettings);
            if (exhibitorExternal) {
              exhibitorExternal.trackPageView(customTitle);
            }
          }
        } catch (ex) {
        }
      }
    }
  }

  trackEvent(expoSettings: TrackingSettings, exhibitorSettings: TrackingSettings, label: string, action: string, value: any) {
    if (isPlatformBrowser(this.platformId) && this.deviceDetector.userAgent.indexOf('HeadlessChrome') < 0) {
      if (environment.googleActive) {
        // const targets = [environment.googleAnalyticsId];
        this.loadAnalytics();
        if (typeof (gtag) !== 'undefined') {
          const targets = [];
          if (expoSettings && expoSettings.googleAnalyticsId) {
            targets.push(expoSettings.googleAnalyticsId);
          }
          if (exhibitorSettings && exhibitorSettings.googleAnalyticsId) {
            targets.push(exhibitorSettings.googleAnalyticsId);
          }
          if (targets.length > 0) {
            gtag('event', 'view_item', {
              sendTo: targets,
              eventCategory: 'engagement',
              eventLabel: label,
              eventAction: action,
              eventValue: value
            });
          }
        }
      }

      if (environment.matomoActive) {
        try {
          const actionLabel = action + (value && value !== '' ? ': ' + value : '');

          _paq.push(['trackEvent', label, actionLabel]);

          if (expoSettings) {
            const expoSecondary = this.getSecondaryMatomoTracker(expoSettings);
            if (expoSecondary) {
              expoSecondary.trackEvent(label, actionLabel);
            }

            const expoExternal = this.getExternalMatomoTracker(expoSettings);
            if (expoExternal) {
              expoExternal.trackEvent(label, actionLabel);
            }
          }

          if (exhibitorSettings) {
            const exhibitorSecondary = this.getSecondaryMatomoTracker(exhibitorSettings);
            if (exhibitorSecondary) {
              exhibitorSecondary.trackEvent(label, actionLabel);
            }

            const exhibitorExternal = this.getExternalMatomoTracker(exhibitorSettings);
            if (exhibitorExternal) {
              exhibitorExternal.trackEvent(label, actionLabel);
            }
          }
        } catch (ex) {
        }
      }
    }
  }

  trackOpenWebsite(exhibitor: ExpoExhibitorListItemDto, $event: MouseEvent = null) {
    if ($event) {
      $event.preventDefault();
    }
    this.trackEvent(exhibitor.expo, exhibitor
      , 'externalLink', 'open', exhibitor.website);

    let url = exhibitor.website;
    if (url && !url.toLowerCase().startsWith('http')) {
      url = 'http://' + url;
    }

    this.openExternal(url);
    return true;
  }

  getExternalMatomoTracker(settings: TrackingSettings): any {
    if (settings && settings.externalMatomoServer && settings.externalMatomoServer.trim() !== ''
      && settings.externalMatomoId && settings.externalMatomoId.trim() !== '') {
      const cacheKey = `${settings.externalMatomoServer}_${settings.externalMatomoId}`;
      let tracker = this.matomoCache[cacheKey];
      if (!tracker) {
        tracker = Matomo.getTracker(settings.externalMatomoServer, settings.externalMatomoId);
        this.matomoCache[cacheKey] = tracker;
      }
      return tracker;
    }
    return null;
  }

  getSecondaryMatomoTracker(settings: TrackingSettings): any {
    if (settings && settings.matomoId && settings.matomoId.trim() !== '') {
      const cacheKey = `${environment.matomoServer}_${settings.matomoId}`;
      let tracker = this.matomoCache[cacheKey];
      if (!tracker) {
        tracker = Matomo.getTracker(`https://${environment.matomoServer}/matomo.php`, settings.matomoId);
        this.matomoCache[cacheKey] = tracker;
      }
      return tracker;
    }
    return null;
  }

  loadMatomo() {
    if (environment.matomoActive) {
      const maScript = document.createElement('script');
      const code = 'var _paq = window._paq || []; _paq.push([\'trackPageView\']); _paq.push([\'enableLinkTracking\']); \n'
        + `(function() { var u="//${environment.matomoServer}/"; _paq.push([\'setTrackerUrl\', u+\'matomo.php\']); `
        + `_paq.push([\'setSiteId\', \'${environment.matomoSiteId}\']); var d=document, g=d.createElement(\'script\'), `
        + 's=d.getElementsByTagName(\'script\')[0]; g.type=\'text/javascript\'; g.async=true; g.defer=true; '
        + 'g.src=u+\'matomo.js\'; s.parentNode.insertBefore(g,s);})();';
      maScript.innerText = code;
      document.documentElement.firstChild.appendChild(maScript);
    }
  }

  loadAnalytics() {
    if (environment.googleActive && isPlatformBrowser(this.platformId)) {
      const disableStr = 'ga-disable-' + environment.googleAnalyticsId;
      if (this.gdprService.permissions.analyticsPermitted && !this.googleLoaded) {
        document.cookie = disableStr + '=false; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
        window[disableStr] = false;
        const gaScript = document.createElement('script');
        gaScript.setAttribute('async', 'true');
        gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`);

        const gaScript2 = document.createElement('script');
        gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${environment.googleAnalyticsId}\');`;

        document.documentElement.firstChild.appendChild(gaScript);
        document.documentElement.firstChild.appendChild(gaScript2);

        const currentUrl = window.location.pathname;

        if (currentUrl) {
          gtag('config', environment.googleAnalyticsId,
            {
              'page_path': currentUrl
            }
          );
        }

        this.googleLoaded = true;
      } else if (!this.googleLoaded) {
        document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
        window[disableStr] = true;
      }
    }
  }

  private openExternal(url: string) {
    this.confirmationService.confirmTranslate('global.link-open-header', 'global.link-open-message'
      , 'global.link-open-yes', 'global.link-open-no', 'lg', {url: url})
      .then((result) => {
        if (result) {
          this._window.open(url, '_blank');
        }
      });
  }
}
