import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {KeyValue} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-control-validation-results',
  templateUrl: './control-validation-results.component.html',
  styleUrls: ['./control-validation-results.component.scss']
})
export class ControlValidationResultsComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() validatedControl: string;
  @Input() customErrorMap: { [key: string]: (value: any) => string };

  control: AbstractControl;

  errorMap: { [key: string]: (value: any) => string };

  constructor(
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.errorMap = {
      'required': (value) => this.translateService.instant('validationErrors.required'),
      'email': (value) => this.translateService.instant('validationErrors.emailRequired'),
      'minlength': (value) => this.translateService.instant('validationErrors.minLength', {
        requiredLength: value.requiredLength,
        actualLength: value.actualLength
      }),
      'maxlength': (value) => this.translateService.instant('validationErrors.maxLength', {
        requiredLength: value.requiredLength,
        actualLength: value.actualLength
      })
    };
    this.control = this.formGroup.controls[this.validatedControl];
  }

  getError(error: KeyValue<string, any>): string {
    if (this.customErrorMap && this.customErrorMap[error.key]) {
      return this.errorMap[error.key](error.value);
    }
    if (this.errorMap[error.key]) {
      return this.errorMap[error.key](error.value);
    }
    return JSON.stringify(error);
  }
}
