import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TrackingService } from './services/tracking.service';
import { VersionCheckService } from './services/version-check.service';
import { environment } from '../environments/environment';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { isPlatformBrowser, PlatformLocation } from '@angular/common';
import { LinkHandlerService } from './services/link-handler.service';
import { VHostService } from './services/vhost.service';
import { WindowService } from './services/window.service';
import { LoadingSpinnerService } from './services/loading-spinner.service';
import { SystemNotificationService } from './services/system-notification.service';
import { IntroService } from './services/intro.service';
import { GdprService } from './services/gdpr.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ExpoDesignService } from './services/expo-design.service';
import { StorageService } from './services/storage.service';
import { DataService } from './services/data.service';
import { SignInService } from './services/sign-in.service';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ImageUrlService } from './components/booths/image-url.service';
import { CheckForUpdateService } from './services/Updater/check-update.service';
import { PromptUpdateService } from './services/Updater/prompt-update.service';
import { LogUpdateService } from './services/Updater/log-update.service';

declare let gtag: any;
declare let _mtm: any;
declare let _paq: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
  // encapsulation: ViewEncapsulation.None
})
export class AppComponent implements AfterViewInit {
  title = 'VirtualExpo';

  loading = false;
  private currentUrl: string;

  constructor(
    public translate: TranslateService,
    private tracking: TrackingService,
    public router: Router,
    private platformLocation: PlatformLocation,
    private linkHandlerService: LinkHandlerService,
    private vhostService: VHostService,
    private route: ActivatedRoute,
    private windowService: WindowService,
    @Inject(PLATFORM_ID) private platformId,
    private loadingSpinnerService: LoadingSpinnerService,
    private deviceDetector: DeviceDetectorService,
    private systemNotificationService: SystemNotificationService,
    private designService: ExpoDesignService,
    public introService: IntroService,
    private gdprService: GdprService,
    private dataService: DataService,
    private signInService: SignInService,
    private imageUrlService: ImageUrlService,
    private checkForUpdateService: CheckForUpdateService,
    private promptUpdateService: PromptUpdateService,
    private logUpdateService: LogUpdateService
  ) {
    // this.versionCheckService.initVersionCheck(environment.versionCheckURL, environment.versionCheckInterval);

    if (deviceDetector.userAgent.indexOf('HeadlessChrome') < 0) {
      this.loadJitsi();
    }

    this.vhostService.IsLoaded.subscribe(() => {
      this.initZammad();

      this.initTranslation();

      this.systemNotificationService.initHub();

      if (isPlatformBrowser(platformId)) {
        const lang = localStorage.getItem('currentLang');
        if (lang && lang !== '') {
          this.translate.use(lang);
        } else {
          this.translate.use(this.translate.defaultLang);
        }

        this.router.events.subscribe((event) => {
          if (event instanceof NavigationStart) {
            this.loadingSpinnerService.show('nav');
          } else if (event instanceof NavigationEnd) {
            this.loadingSpinnerService.hide('nav');
            if (environment.googleActive && typeof gtag !== 'undefined') {
              this.currentUrl = event.urlAfterRedirects;
              gtag('config', environment.googleAnalyticsId, {
                page_path: event.urlAfterRedirects
              });
            }
          } else if (event instanceof NavigationError) {
            this.loadingSpinnerService.hide('nav');
          } else if (event instanceof NavigationCancel) {
            this.loadingSpinnerService.hide('nav');
          }
        });

        // loadingSpinnerService.Show.subscribe(value => {
        //   this.loading = true;
        // });
        // loadingSpinnerService.Hide.subscribe(value => {
        //   this.loading = false;
        // });
        this.loading = true;
      }
    });
  }

  ngAfterViewInit() {
    this.initVVHost();
  }

  initVVHost() {
    const domain = this.platformLocation.hostname;
    const path = this.platformLocation.pathname;
    if (
      domain !== environment.baseDomain &&
      !environment.elementsIntegration &&
      !this.imageUrlService.noCache
    ) {
      this.dataService.getVHosts().then((value) => {
        let success = false;
        let exit = false;
        const matches = location.href.match(/noCache=(\d+)/);
        let noCache = false;
        if (matches) {
          const ttl = parseInt(matches[1], 10);
          if (ttl >= Date.now() - 1000 * 60 * 60 * 24) {
            noCache = true;
          }
        }

        value.forEach((value1) => {
          if (
            value1.domain.toLowerCase() === domain.toLowerCase() &&
            !success
          ) {
            const expoLink = this.linkHandlerService.prepare(
              value1.expoName,
              value1.expoShortKey
            );
            this.vhostService.shortKey = value1.expoShortKey;
            this.vhostService.expoName = value1.expoName;
            this.vhostService.showHall = value1.showHall;
            this.vhostService.exhibitorShortKey = value1.exhibitorShortKey;
            this.vhostService.exhibitorName = value1.exhibitorName;
            this.vhostService.defaultLanguage = value1.defaultLanguage;
            this.vhostService.accessMode = value1.accessMode;
            if (
              this.vhostService.defaultLanguage &&
              this.vhostService.defaultLanguage !== ''
            ) {
              this.translate.setDefaultLang(this.vhostService.defaultLanguage);
            }

            const currentUrl = path;
            const baseUrl = '/exhibition/' + expoLink;
            const stageUrl = '/exhibition/' + expoLink + '/stage/';
            const stageUrl2 = '/stage/' + expoLink;

            if (
              !currentUrl.startsWith(baseUrl) &&
              !currentUrl.startsWith(stageUrl) &&
              !currentUrl.startsWith(stageUrl2)
            ) {
              // console.log('redirecting landing');
              if (value1.showHall) {
                this.router.navigate(['exhibition', expoLink]);
              } else if (value1.exhibitorName && value1.exhibitorName !== '') {
                const exhibitorLink = this.linkHandlerService.getExhibitorUrl(
                  { name: value1.expoName, shortKey: value1.expoShortKey },
                  {
                    name: value1.exhibitorName,
                    shortKey: value1.exhibitorShortKey
                  }
                );
                this.router.navigate(['exhibition', expoLink, exhibitorLink]);
              } else {
                this.router.navigate(['exhibition', expoLink]);
              }
            }
            success = true;
            this.vhostService.isVhost = true;

            if (value1.defaultLanguage && value1.defaultLanguage !== '') {
              const lang = value1.defaultLanguage;
              if (lang && lang !== '') {
                this.translate.use(lang);
              } else {
                this.translate.use(this.translate.defaultLang);
              }
            }
          }
        });
        // debugger;
        if (!success && !location.host.startsWith(environment.testDomain) && !location.host.startsWith('localhost')) {
          const re = new RegExp('^\\/exhibition\\/[\\w-]+\\_(\\w{10})', 'g');
          const expoMatch = re.exec(path);
          let expoId = null;
          if (expoMatch && expoMatch.length > 1) {
            expoId = expoMatch[1];
          }

          if (!noCache) {
            value.forEach((value1) => {
              if (
                expoId &&
                value1.expoShortKey.toLowerCase() === expoId.toLowerCase() &&
                !success
              ) {
                window.location.href = `https://${value1.domain}${path}`;
                exit = true;
              }
            });
          }
        }
        if (exit) {
          return;
        } else if (!noCache && !success && location.host !== 'virtualexpo.info' && !location.host.startsWith(environment.testDomain) && !location.host.startsWith('localhost')) {
          window.location.href = 'https://virtualexpo.info';
        } else if (!success) {
          const currentUrl = this.windowService.pathName();
          this.vhostService.triggerLoaded();
        } else {
          this.vhostService.triggerLoaded();
        }
      });
    } else {
      const currentUrl = this.windowService.pathName();
      this.vhostService.triggerLoaded();
      // if (currentUrl === '' || currentUrl === '/') {
      //   this.router.navigate(['/']);
      // }
    }
  }

  initZammad() {
    // this.translate.onLangChange.subscribe(() => {
    //   this.translate.get('init')
    //     .subscribe(value => {
    //       if (this.vhostService.loaded) {
    //         this.loadZammad();
    //       } else {
    //         this.vhostService.IsLoaded
    //           .subscribe(() => {
    //             this.loadZammad();
    //           });
    //       }
    //     });
    // });
  }

  loadZammad() {
    // if (isPlatformBrowser(this.platformId)) {
    //   if (this.vhostService.isVhost) {
    //     return;
    //   }
    //   const lang = this.translate.currentLang;
    //   $(function () {
    // eslint-disable-next-line
    //     new ZammadChat({
    //       fontSize: '12px',
    //       chatId: 1,
    //       lang: lang
    //     });
    //   });
    // }
  }

  loadJitsi() {
    if (environment.jitsiActive) {
      const newScript = document.createElement('script');
      newScript.defer = true;
      newScript.async = true;
      newScript.src = environment.jitsiLoader;
      document.documentElement.firstChild.appendChild(newScript);
    }
  }

  initTranslation() {
    this.translate.setDefaultLang('de');
    if (this.translate.getBrowserLang() !== 'de') {
      this.translate.setDefaultLang('en');
    }
    if (
      this.vhostService.defaultLanguage &&
      this.vhostService.defaultLanguage !== ''
    ) {
      this.translate.setDefaultLang(this.vhostService.defaultLanguage);
    }
  }

  onActivate(event) {
    this.windowService.scrollTop(event);
  }

  enableIntro($event: MouseEvent) {
    $event.preventDefault();
    this.introService.toggleIntro();
    // this.gdprService.checkDialog();
  }
}
