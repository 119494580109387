import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {WindowService} from '../../services/window.service';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent {
  title = 'VirtualExpo';

  constructor(private _window: WindowService) {
  }

  onActivate(event) {
    this._window.scrollUp(event);
  }

}
