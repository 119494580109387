import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {share} from 'rxjs/operators';
import {ExpoDesignService} from './expo-design.service';
import {DataService} from './data.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService implements OnDestroy {
  private onSubject = new Subject<{ key: string, value: any }>();
  public changes = this.onSubject.asObservable().pipe(share());

  constructor(
    private designService: ExpoDesignService
    , private dataService: DataService
  ) {
    this.start();
  }

  ngOnDestroy() {
    this.stop();
  }

  public getStorage() {
    const s = [];
    for (let i = 0; i < localStorage.length; i++) {
      s.push({
        key: localStorage.key(i),
        value: JSON.parse(localStorage.getItem(localStorage.key(i)))
      });
    }
    return s;
  }

  public get(key: string): any {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  }

  public store(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
    // the local application doesn't seem to catch changes to localStorage...
    this.onSubject.next({key: key, value: data});
  }

  public clear(key) {
    localStorage.removeItem(key);
    // the local application doesn't seem to catch changes to localStorage...
    this.onSubject.next({key: key, value: null});
  }

  public queryRefresh() {
    if (window && window.parent) {
      window.parent.postMessage('design-request', '*');
      window.parent.postMessage('design-request-booth', '*');
    }
  }


  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
    window.addEventListener('message', this.messageEventListener.bind(this));
    this.queryRefresh();
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage) {
      let v;
      try {
        v = JSON.parse(event.newValue);
      } catch (e) {
        v = event.newValue;
      }
      this.onSubject.next({key: event.key, value: v});
    }
  }

  private messageEventListener(event: MessageEvent) {
    const tmp = (event.data as string);
    if (tmp && tmp.startsWith && tmp.startsWith('{')) {
      const update = JSON.parse(event.data);
      if (update.command === 'update-design') {
        this.designService.overrideDesign(update.expoId, update.data, update.expo);
        this.dataService.updateExpoDesign(update.expoId, update.data, update.expo);
      }
      if (update.command === 'update-design-booth') {
        if (update.data.displays) {
          update.data.displays = update.data.displays.filter(item => !item.inActive);
        }
        if (update.data.links) {
          update.data.links = update.data.links.filter(item => !item.inActive);
        }
        if (update.data.infoSlides) {
          update.data.infoSlides = update.data.infoSlides.filter(item => !item.inActive);
        }

        this.designService.overrideBoothDesign(update.expoId, update.exhibitorId, update.data);
        this.dataService.updateExhibitorDesign(update.expoId, update.exhibitorId, update.data);
      }
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.onSubject.complete();
  }
}
