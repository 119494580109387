<div *ngIf="session" class="chatOuter" [ngClass]="{'chat-hidden': !session.open}">
  <div is="chatNameFrame" class="chatNameFrame" style="cursor: pointer;" (click)="toggle($event)">
    <div class="chatName">
      <i class="fas messageIcon" [ngClass]="{'fa-chevron-down': session.open, 'fa-chevron-up': !session.open}"
         title="{{ 'userChat.hideSession' | translate}}"></i>
      <span class="messageCount" *ngIf="unreadMessages > 0">({{unreadMessages}})</span>
      <span class="messageText" [innerText]="session.name"></span>
    </div>
    <div class="session-buttons">
      <div class="btn btn-xs btn-danger" style="min-width: 2em;" (click)="close($event)">
        <i class="fa fa-times"
           title="{{ 'userChat.closeSession' | translate}}"></i>
      </div>
    </div>
  </div>
  <div #chatContentFrame class="chatInnerFrame" [ngClass]="{'chat-hidden': !session.open}">

  </div>
  <div class="chatFooter" [ngClass]="{'chat-hidden': !session.open}">
    <div class="messageWrapper">
      <input type="text" (keydown.enter)="callSendMessage()" [(ngModel)]="message" class="messageText"
             [placeholder]="getInputPlaceholder()"/>
    </div>

    <div class="btn btn-success btn-xs ml-2" style="min-width: 2em;" (click)="sendButtonClick($event)">
      <i class="sendButton fa fa-paper-plane"
         title="{{ 'userChat.sendMessage' | translate}}"></i>
    </div>
  </div>
</div>
