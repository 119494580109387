import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SignInData} from '../../../services/sign-in-data';
import {TrackingService} from '../../../services/tracking.service';
import {environment} from '../../../../environments/environment';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss']
})
export class VideoCallComponent implements OnInit {
  @Input() callId: string;
  @Input() name: string;
  @Input() senderName: string;
  // @Input() exhibitorId: string;
  // @Input() exhibitor: any;
  @Input() token: SignInData;

  @Output() callHangup: EventEmitter<any> = new EventEmitter<any>();

  options: any;
  api: any = {};
  user: any = {};
  isLoaded = false;
  isOpen = false;

  constructor(
    public activeModal: NgbActiveModal
    , private confirmationService: ConfirmationDialogService
  ) {
  }

  ngOnInit() {
    this.options = {
      roomName: this.callId.replace(/-/gi, ''),
      jwt: this.token.token,
      parentNode: document.querySelector('#meet'),
      configOverwrite: {
        enableWelcomePage: false,
        authdomain: environment.authDomain
      },
      interfaceConfigOverwrite: {
        MOBILE_APP_PROMO: false,
        SHOW_JITSI_WATERMARK: false,
        APP_NAME: name,
      }
    };
    const tmp = sessionStorage.getItem('userdata');
    if (tmp) {
      this.user = JSON.parse(tmp);
    }
    this.api = new JitsiMeetExternalAPI(environment.videoUrl, this.options);
    // this.tracking.trackEvent(this.exhibitor.expo, this.exhibitor, 'videochat', this.exhibitor.name, 'video chat opened');
  }

  handleLoad() {
    // this.api = api;
    this.api.executeCommand('subject', name);
    this.api.executeCommand('displayName', [this.user.name]);
    this.api.executeCommand('toggleChat');
  }

  hangup() {
    this.confirmationService.confirmTranslate('videoCall.hangupHeader', 'videoCall.hangupMessage', 'videoCall.hangupOk', 'videoCall.hangupCancel')
      .then(value => {
        this.api.executeCommand('hangup');
        setTimeout(() => {
          this.api = null;
          const node = document.getElementById('meet');
          while (node.lastChild) {
            node.removeChild(node.lastChild);
          }
          this.activeModal.dismiss();
          this.callHangup.emit();
        }, 250);
      });
  }

  hangupCall() {
    this.api.executeCommand('hangup');
    setTimeout(() => {
      this.api = null;
      const node = document.getElementById('meet');
      while (node.lastChild) {
        node.removeChild(node.lastChild);
      }
      this.activeModal.dismiss();
    }, 250);
  }
}
