/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface CalendarEntrySessionViewDataDto { 
    id: number;
    name?: string;
    hasPassword?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of CalendarEntrySessionViewDataDto.
 */
export namespace CalendarEntrySessionViewDataDto {
    /**
     * All properties of CalendarEntrySessionViewDataDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        hasPassword = 'hasPassword'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of CalendarEntrySessionViewDataDto.
    */
    export const ModelValidators: {[K in keyof CalendarEntrySessionViewDataDto]: [string, ValidatorFn][]} = {
        id: [
                ['required', Validators.required],
        ],
        name: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        hasPassword: [
        ],
    };

    /**
    * The FormControlFactory for CalendarEntrySessionViewDataDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<CalendarEntrySessionViewDataDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for CalendarEntrySessionViewDataDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: CalendarEntrySessionViewDataDto = {
            id: null,
            name: null,
            hasPassword: null,
          }
        ) {
            super(model, CalendarEntrySessionViewDataDto.ModelValidators);
        }
    }

}


