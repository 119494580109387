<div class="modal-header">
  <h4 class="modal-title" [translate]="'login.header'"></h4>
  <a class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </a>
</div>
<div class="modal-body">
  <div class="container">
    <ng-container *ngIf="!submitted">
      <p [innerHTML]="'login.description' | translate"></p>
      <p [innerHTML]="'login.description2' | translate"></p>
      <form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="loginForm" novalidate=""
            (submit)="submitForm($event, valForm.value)">
        <div class="form-group has-feedback input-group mb-3">
          <input class="form-control" id="exampleInputEmail1" type="email" name="email"
                 [attr.placeholder]="'login.placeholder' | translate"
                 autocomplete="email" formControlName="email" required=""/>
          <div class="input-group-append">
            <span class="fa fa-envelope input-group-text text-muted"></span>
          </div>
        </div>
        <span class="text-danger"
              *ngIf="valForm.controls['email'].hasError('required') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)"
              [translate]="'profile.fieldRequired'"></span>
        <span class="text-danger"
              *ngIf="valForm.controls['email'].hasError('minlength') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)"
              [translate]="'profile.fieldLength'" [translateParams]="{min: 6, max: 100}"></span>
        <span class="text-danger"
              *ngIf="valForm.controls['email'].hasError('maxlength') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)"
              [translate]="'profile.fieldLength'" [translateParams]="{min: 6, max: 100}"></span>
        <span class="text-danger"
              *ngIf="valForm.controls['email'].hasError('email') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)"
              [translate]="'profile.emailRequired'"></span>
        <button class="btn btn-block btn-primary mt-lg" type="submit" [ngClass]="{'disabled': !valForm.valid}"
                [disabled]="!valForm.valid">Login
        </button>
      </form>
      <h4 [translate]="'login.footer'"></h4>
      <p [translate]="'login.footer2'"></p>
      <!--  <a routerLink="/login">Login</a> using email and password.-->
      <p class="text-center">
        <a [href]="privacyLink" [translate]="'registerChat.privacy'" target="_blank" rel="noopener"></a>
      </p>
    </ng-container>
    <ng-container *ngIf="submitted">
      <p [innerHTML]="'login.checkMail' | translate">
      </p>
      <form [formGroup]="valForm2" class="form-validate mb-lg" role="form" name="loginForm2" novalidate=""
            (submit)="submitToken($event)">
        <div class="form-group has-feedback">
          <div class="input-group">
            <input class="form-control" id="token" type="string" name="token" placeholder="Enter token"
                   autocomplete="token" formControlName="token" required="required"/>
          </div>
          <app-control-validation-results [formGroup]="valForm2"
                                          validatedControl="token"></app-control-validation-results>
        </div>
        <button class="btn btn-block btn-primary mt-lg" type="submit" translate="login.login"></button>
      </form>
      <p [innerHTML]="'login.checkMail2' | translate">
      </p>
    </ng-container>
  </div>
</div>
