<div class="modal-header">
  <h4 class="modal-title" [translate]="'register.header'"></h4>
  <a class="close" aria-label="Close" (click)="cancelLogin($event)">
    <span aria-hidden="true">&times;</span>
  </a>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-xs-12">
      <div style="padding: 1em 2em;">
        <span [translate]="'register.intro'"></span>
        <hr>
        <form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="expoForm">
          <div class="form-group has-feedback">
            <label for="name">{{ 'register.name' | translate}}</label>
            <input class="form-control" id="name" type="text" name="name" [placeholder]="'register.entername' | translate"
                   autocomplete="off"
                   formControlName="name" />
            <app-control-validation-results [formGroup]="valForm" [validatedControl]="'name'"></app-control-validation-results>
          </div>
          <div class="form-group has-feedback">
            <label for="email">{{ 'register.email' | translate}}</label>
            <input class="form-control" id="email" type="email" name="email" [placeholder]="'register.entermail' | translate"
                   autocomplete="off"
                   formControlName="email" email="true"/>
            <app-control-validation-results [formGroup]="valForm" [validatedControl]="'email'"></app-control-validation-results>
          </div>
          <div class="form-group has-feedback">
            <label for="permit">{{ 'register.permit' | translate}}</label>
            <input class="form-control" id="permit" type="checkbox" name="permit"
                   formControlName="permit"/>
            <span class="text-danger"
                  *ngIf="valForm.controls['permit'].hasError('requiredTrue') && (valForm.controls['permit'].dirty || valForm.controls['permit'].touched)">{{ 'form.permissionrequired' | translate }}</span>
          </div>
        </form>
        <hr>
        <span [innerHTML]="'register.description' | translate"></span><br/>
        <app-legal-links></app-legal-links>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-outline-dark" (click)="cancelLogin($event)" [translate]="'register.deny'" formnovalidate></button>

  <button type="button" class="btn btn-success btn-lg" (click)="submitForm($event)" [translate]="'register.allow'"
          [title]="'register.formRequired' | translate"
          [disabled]="!valForm.value.permit || !valForm.valid"
  ></button>
</div>
