/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface PwdLessCallbackDto { 
    email: string;
    token: string;
}

/**
 * Namespace for property- and property-value-enumerations of PwdLessCallbackDto.
 */
export namespace PwdLessCallbackDto {
    /**
     * All properties of PwdLessCallbackDto.
     */
    export enum Properties {
        email = 'email',
        token = 'token'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of PwdLessCallbackDto.
    */
    export const ModelValidators: {[K in keyof PwdLessCallbackDto]: [string, ValidatorFn][]} = {
        email: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(6)],
                ['maxlength', Validators.maxLength(100)],
        ],
        token: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(1)],
        ],
    };

    /**
    * The FormControlFactory for PwdLessCallbackDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<PwdLessCallbackDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for PwdLessCallbackDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: PwdLessCallbackDto = {
            email: null,
            token: null,
          }
        ) {
            super(model, PwdLessCallbackDto.ModelValidators);
        }
    }

}


