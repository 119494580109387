import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  isBrowser;
  _window: any;

  constructor(@Inject(PLATFORM_ID) private platformId) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      this._window = window;
    }
  }

  redirectTo(url: string) {
    if (this.isBrowser) {
      this._window.location.href = url;
    }
  }

  reload() {
    if (this.isBrowser) {
      this._window.location.reload();
    }
  }

  open(url: string, target: string, params?: any) {
    if (this.isBrowser) {
      this._window.open(url, '_blank', params);
    }
  }

  baseUrl(): string {
    if (this.isBrowser) {
      return `${this._window.location.protocol}//${this._window.location.host}`;
    } else {
      return '.';
    }
  }

  pathName(): string {
    if (this.isBrowser) {
      return this._window.location.pathname;
    } else {
      return '';
    }
  }

  scrollUp(event) {
    this.scrollTop(event);
    // if (this.isBrowser) {
    //   const scrollToTop = this._window.setInterval(() => {
    //     const pos = this._window.pageYOffset;
    //     if (pos > 0) {
    //       this._window.scrollTo(0, pos - 20); // how far to scroll on each step
    //     } else {
    //       this._window.clearInterval(scrollToTop);
    //     }
    //   }, 16);
    // }
  }

  scrollTop(event) {
    if (this.isBrowser) {
      this._window.scrollTo(0, 0); // how far to scroll on each step
    }
  }
}
