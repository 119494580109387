import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {LinkHandlerService} from '../../services/link-handler.service';
import {ExpoBookedListDto, ExpoSimpleListItemDto} from '../../virtual-expo-api';
import {ImageUrlService} from '../booths/image-url.service';
import {Router} from '@angular/router';
import {TrackRedirectService} from '../../services/track-redirect.service';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../../services/alert.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

const dayjs = require('dayjs');

@Component({
  selector: 'app-expo-small',
  templateUrl: './expo-small.component.html',
  styleUrls: ['./expo-small.component.scss']
})
export class ExpoSmallComponent implements OnInit {
  @Input() expo: ExpoSimpleListItemDto;
  @Input() access: { [p: string]: ExpoBookedListDto };
  @Input() isPastEvent: boolean;

  dayjs = dayjs;
  currentDate: Date = new Date();
  expoLink: SafeUrl;
  link: SafeUrl;
  logoSquare: string;
  isFuture: boolean;
  isPast: boolean;
  startDate: string;
  endDate: string;
  private dateFormat: string;
  logoWide: string;


  constructor(private linkHandler: LinkHandlerService, private imageUrlService: ImageUrlService, private router: Router
    , private trackRedirectService: TrackRedirectService, private translateService: TranslateService
    , private alertService: AlertService, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.updateValues();

    this.translateService.onLangChange.subscribe(() => {
      this.updateValues();
    });
    this.translateService.onTranslationChange.subscribe(() => {
      this.updateValues();
    });
  }

  private updateValues() {
    this.translateService.get('expo.dateFormat')
      .subscribe(value => {
        this.dateFormat = value;
        this.startDate = this.getDate(this.expo.dateStart);
        this.endDate = this.getDate(this.expo.dateEnd);
      });
    this.link = this.getExpoLink();
    this.logoSquare = this.getLogoSquare();
    this.logoWide = this.imageUrlService.getLogoWide(this.expo);
    this.isFuture = this.isFutureDate(this.expo);
    this.isPast = this.isPastDate(this.expo);
    this.expoLink = this.getExpoLink();
  }

  private getLogoSquare(): string {
    return this.imageUrlService.getExpoLogo(this.expo);
  }

  private getExpoLink(): SafeUrl {
    if (this.isFuture && !(this.expo.isPreview && !this.imageUrlService.noCache)) {
      return `/`;
    } else if (this.isPastEvent || (this.isPast && !(this.expo.isPreview && !this.imageUrlService.noCache))) {
      return `/`;
    } else if (this.expo.accessMode < 10 || this.access[this.expo.id]) {
      return this.sanitizer.sanitize(SecurityContext.URL, this.linkHandler.getExpoUrl(this.expo));
      // return `/exhibition/${this.getLink()}`;
    } else {
      return this.linkHandler.getExpoLockedUrl(this.expo);
    }
  }

  openExpoLink($event: MouseEvent) {
    $event.preventDefault();
    if (this.isFuture && !(this.expo.isPreview && !this.imageUrlService.noCache)) {
      this.alertService.info(this.translateService.instant('global.notOpenYet'));
    } else if (this.isPastEvent || (this.isPast && !(this.expo.isPreview && !this.imageUrlService.noCache))) {
      this.alertService.info(this.translateService.instant('global.notOpenStill'));
    } else if (this.expo.accessMode < 10 || this.access[this.expo.id]) {
      return this.linkHandler.navigateExpo(this.expo);
    } else {
      return this.linkHandler.navigateExpoLocked(this.expo);
    }
  }

  private getDate(input: Date): string {
    return dayjs(input).format(this.dateFormat);
  }

  private isFutureDate(expo: ExpoSimpleListItemDto) {
    return dayjs(expo.dateStart).toDate() > this.currentDate;
  }

  private isPastDate(expo: ExpoSimpleListItemDto) {
    return dayjs(expo.dateEnd).toDate() < this.currentDate;
  }
}
