import {Injectable} from '@angular/core';
import {ImageUrlService} from './image-url.service';
import {SignInService} from '../../services/sign-in.service';
import {Router} from '@angular/router';
import {WindowService} from '../../services/window.service';
import {DomSanitizer, SafeStyle, SafeUrl} from '@angular/platform-browser';
import {ExpoExhibitorListItemDto} from '../../virtual-expo-api';
import {ColorSchemeIndex} from '../../objects/color-scheme-index.enum';
import {TinyColor} from '@ctrl/tinycolor';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ColorHelperService {
  lumFactor = 0.05;

  constructor(
    private imageUrlService: ImageUrlService
    , private router: Router
    , private _window: WindowService
    , protected sanitizer: DomSanitizer
  ) {
  }

  getBaseUrl(): string {
    return this._window.baseUrl();
  }

  getRouterUrl(): string {
    return this.router.url;
  }

  getColor(exhibitor: ExpoExhibitorListItemDto, index: ColorSchemeIndex): string {
    if (!exhibitor || !exhibitor.boothConfiguration) {
      return '#eeeeff';
    }
    let color = exhibitor.boothConfiguration.colors[index];
    if (!color || typeof color === 'undefined') {
      color = '#eeeeff';
    }
    return color;
  }

  getSchemeColor(exhibitor: ExpoExhibitorListItemDto, schemeIndex: ColorSchemeIndex): string {
    return this.getColor(exhibitor, schemeIndex);
  }

  getPrimaryColor(exhibitor: ExpoExhibitorListItemDto): string {
    return this.getColor(exhibitor, 1);
  }

  getPrimaryColorLighter(exhibitor: ExpoExhibitorListItemDto): string {
    const color = this.getColor(exhibitor, 1);
    const result = this.colorLuminance(color, -this.lumFactor);
    return result;
  }

  getPrimaryColorDarker(exhibitor: ExpoExhibitorListItemDto): string {
    const color = this.getColor(exhibitor, 1);
    const result = this.colorLuminance(color, this.lumFactor);
    return result;
  }

  getSecondaryColor(exhibitor: ExpoExhibitorListItemDto): string {
    return this.getColor(exhibitor, 3);
  }

  getSecondaryColorLighter(exhibitor: ExpoExhibitorListItemDto): string {
    const color = this.getColor(exhibitor, 3);
    const result = this.colorLuminance(color, -this.lumFactor);
    return result;
  }

  getSecondaryColorDarker(exhibitor: ExpoExhibitorListItemDto): string {
    const color = this.getColor(exhibitor, 3);
    const result = this.colorLuminance(color, this.lumFactor);
    return result;
  }

  getPrimaryFill(exhibitor: ExpoExhibitorListItemDto): SafeUrl {
    let color = this.getColor(exhibitor, 2);
    color = this.colorLuminance(color, -this.lumFactor);
    return this.sanitizer.bypassSecurityTrustStyle('fill:' + color + ';');
  }

  getPrimaryHighlightStyle(exhibitor: ExpoExhibitorListItemDto): SafeUrl {
    let color = this.getColor(exhibitor, 2);
    color = this.colorLuminance(color, -this.lumFactor);
    return this.sanitizer.bypassSecurityTrustStyle('fill:' + color + ';stroke:' + color + ';');
  }

  getPrimaryStroke(exhibitor: ExpoExhibitorListItemDto): SafeUrl {
    let color = this.getColor(exhibitor, 1);
    color = this.colorLuminance(color, -this.lumFactor);
    return this.sanitizer.bypassSecurityTrustStyle('stroke:' + color + ';');
  }

  getPrimaryHighlightStroke(exhibitor: ExpoExhibitorListItemDto): SafeUrl {
    let color = this.getColor(exhibitor, 2);
    color = this.colorLuminance(color, -this.lumFactor);
    return this.sanitizer.bypassSecurityTrustStyle('stroke:' + color + ';');
  }

  getStroke(exhibitor: ExpoExhibitorListItemDto, index: ColorSchemeIndex): SafeUrl {
    let color = this.getColor(exhibitor, index);
    color = this.colorLuminance(color, -this.lumFactor);
    return this.sanitizer.bypassSecurityTrustStyle('stroke:' + color + ';');
  }

  getStop(exhibitor: ExpoExhibitorListItemDto, index: ColorSchemeIndex, lum: number): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle('stop-color:' + this.colorLuminance(this.getColor(exhibitor, index), lum) + ';stop-opacity:1;');
  }

  getSecondaryStroke(exhibitor: ExpoExhibitorListItemDto): SafeUrl {
    let color = this.getColor(exhibitor, 3);
    color = this.colorLuminance(color, -this.lumFactor);
    return this.sanitizer.bypassSecurityTrustStyle('stroke:' + color + ';');
  }

  getSecondaryHighlightStroke(exhibitor: ExpoExhibitorListItemDto): SafeUrl {
    let color = this.getColor(exhibitor, 4);
    color = this.colorLuminance(color, -this.lumFactor);
    return this.sanitizer.bypassSecurityTrustStyle('stroke:' + color + ';');
  }

  getPrimaryHighlightStrokeWithGradient(exhibitor: ExpoExhibitorListItemDto, gradient: string): SafeUrl {
    let color = this.getColor(exhibitor, 2);
    color = this.colorLuminance(color, -this.lumFactor);
    const url = `url('${this.getBaseUrl()}${this.getRouterUrl()}${gradient}')`;
    return this.sanitizer.bypassSecurityTrustStyle(`fill:${url};stroke:${color};`);
  }

  getPrimaryHighlightLighterStyle(exhibitor: ExpoExhibitorListItemDto): SafeUrl {
    const color = this.colorLuminance(exhibitor.boothConfiguration.colors[2], -this.lumFactor);
    return this.sanitizer.bypassSecurityTrustStyle('fill:' + color + ';stroke:' + color + ';');
  }

  getSecondaryHighlightStrokeWithGradient(exhibitor: ExpoExhibitorListItemDto, gradient: string): SafeUrl {
    let color = this.getColor(exhibitor, 4);
    color = this.colorLuminance(color, -this.lumFactor);
    const url = `url('${this.getBaseUrl()}${this.getRouterUrl()}${gradient}')`;
    return this.sanitizer.bypassSecurityTrustStyle(`fill:${url};stroke:${color};`);
  }

  getSecondaryHighlightStyle(exhibitor: ExpoExhibitorListItemDto): SafeUrl {
    const color = exhibitor.boothConfiguration.colors[4];
    return this.sanitizer.bypassSecurityTrustStyle('fill:' + color + ';stroke:' + color + ';');
  }

  getSecondaryHighlightLighterStyle(exhibitor: ExpoExhibitorListItemDto): SafeUrl {
    const color = this.colorLuminance(exhibitor.boothConfiguration.colors[4], -this.lumFactor);
    return this.sanitizer.bypassSecurityTrustStyle('fill:' + color + ';stroke:' + color + ';');
  }

  addFillWithServer(gradient: string): SafeStyle {
    const url = `url('${this.getBaseUrl()}${this.getRouterUrl()}${gradient}')`;
    return this.sanitizer.bypassSecurityTrustStyle(`fill:${url}`);
  }

  getServerLink(gradient: string): string {
    const url = `${this.getBaseUrl()}${this.getRouterUrl()}${gradient}`;
    return url;
    // return this.sanitizer.bypassSecurityTrustResourceUrl(`${url}`);
  }

  colorLuminance(hex: string, lum: number): string {
    let color = new TinyColor(hex);
    color = color.lighten(lum * 100);
    return color.toHslString();
  }

  getFillUrl(anchor: string): SafeStyle {
    const url = `url('${this.getBaseUrl()}${this.getRouterUrl()}${anchor}')`;
    return this.sanitizer.bypassSecurityTrustStyle(`fill:${url}`);
  }

  getMaskUrl(anchor: string): SafeStyle {
    const url = `url('${this.getBaseUrl()}${this.getRouterUrl()}${anchor}')`;
    return this.sanitizer.bypassSecurityTrustStyle(`mask:${url}`);
  }

  getCDNFillUrl(imageUrl: string): SafeStyle {
    const url = `url('${environment.cdnURL}${imageUrl}')`;
    return this.sanitizer.bypassSecurityTrustStyle(`fill:${url}`);
  }

  colorStringToArray(hex: string): string[] {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    const rgb: Array<string> = new Array<string>();
    for (let i = 0; i < 3; i++) {
      let c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c), 255));
      rgb.push(c.toString());
    }

    return rgb;
  }

  colorToHslArray(hex: string): string[] {
    const rgb = this.colorStringToArray(hex);

    const hsl = this.rgbToHsl(parseFloat(rgb[0]), parseFloat(rgb[1]), parseFloat(rgb[2]));

    return [(hsl[0] * 360).toString(), (hsl[1] * 100).toString() + '%', (hsl[2] * 100).toString() + '%'];
  }

  rgba2hex(orig) {
    var a, isPercent,
      rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
      alpha = (rgb && rgb[4] || "").trim(),
      hex = rgb ?
        (rgb[1] | 1 << 8).toString(16).slice(1) +
        (rgb[2] | 1 << 8).toString(16).slice(1) +
        (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

    if (alpha !== "") {
      a = alpha;
    } else {
      a = '00';
    }
    // multiply before convert to HEX
    a = ((a * 255) | 1 << 8).toString(16).slice(1)
    hex = hex + a;

    return hex;
  }

  /**
   * Converts an RGB color value to HSL. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
   * Assumes r, g, and b are contained in the set [0, 255] and
   * returns h, s, and l in the set [0, 1].
   *
   * @param   {number}  r       The red color value
   * @param   {number}  g       The green color value
   * @param   {number}  b       The blue color value
   * @return  {Array}           The HSL representation
   */
  rgbToHsl(r: number, g: number, b: number): number[] {
    r /= 255, g /= 255, b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return [h, s, l];
  }

}
