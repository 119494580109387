/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ChatLogDto } from './chatLogDto';


export interface ChatLogPackageDto { 
    chatId?: string;
    expoId?: string;
    chatLogDtos?: Array<ChatLogDto>;
}

/**
 * Namespace for property- and property-value-enumerations of ChatLogPackageDto.
 */
export namespace ChatLogPackageDto {
    /**
     * All properties of ChatLogPackageDto.
     */
    export enum Properties {
        chatId = 'chatId',
        expoId = 'expoId',
        chatLogDtos = 'chatLogDtos'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ChatLogPackageDto.
    */
    export const ModelValidators: {[K in keyof ChatLogPackageDto]: [string, ValidatorFn][]} = {
        chatId: [
        ],
        expoId: [
        ],
        chatLogDtos: [
        ],
    };

    /**
    * The FormControlFactory for ChatLogPackageDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ChatLogPackageDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ChatLogPackageDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ChatLogPackageDto = {
            chatId: null,
            expoId: null,
            chatLogDtos: null,
          }
        ) {
            super(model, ChatLogPackageDto.ModelValidators);
        }
    }

}


