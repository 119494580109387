import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-expo-stage-redirect',
  template: ''
})
export class ExpoStageRedirectComponent implements OnInit {

  constructor(
    private route: ActivatedRoute
    , private router: Router
  ) {
  }

  ngOnInit(): void {
    const expoId = this.route.snapshot.paramMap.get('expoId');
    const stageId = this.route.snapshot.paramMap.get('stageId');
    const itemId = this.route.snapshot.paramMap.get('id');
    let path = `/exhibition/${expoId}/stage/${stageId}`;
    if (itemId) {
      path += `/${itemId}`;
    }
    this.router.navigateByUrl(path);
  }

}
