import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {VHostService} from '../../services/vhost.service';
import {isPlatformBrowser} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProfileComponent} from '../components/profile/profile.component';
import {PasswordLessLoginComponent} from '../components/password-less-login/password-less-login.component';
import {NavigationEnd, Router} from '@angular/router';
import {ProfileService} from '../../services/profile.service';
import {ExpoCalendarComponent} from '../expo-calendar/expo-calendar.component';
import {UserChatService} from '../../services/user-chat.service';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {
  navbarOpen = false;
  language = 'en';
  showMenu = false;
  isHomepage = false;
  isExhibition = true;
  privacyLink: string;
  contactLink: any;
  homepageLink: any;
  showLogin = environment.showLogin;
  orgaLogo = environment.orgaLogo;
  loaded = false;
  unreadMessages: number;

  constructor(private translate: TranslateService, public vHostService: VHostService
    , @Inject(PLATFORM_ID) private platformId, public dataService: DataService
    , private modalService: NgbModal, private router: Router
              , public  profileService: ProfileService
              , public userChatService: UserChatService
  ) {
  }

  ngOnInit() {
    // this.translate.onLangChange.subscribe(() => {
    //   this.loadText();
    // });
    // this.loadText();

    // if (!this.vHostService.loaded) {
    //   this.vHostService.IsLoaded.subscribe(() => {
    //     this.loadData();
    //   });
    // } else {
    //   this.loadData();
    // }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = (event as NavigationEnd).urlAfterRedirects;
        // this.isHomepage = (url.indexOf('home') >= 0);
        this.isExhibition = (url.indexOf('home') < 0);
      }
    });
    this.isExhibition = (this.router.url.indexOf('home') < 0);
    // this.isHomepage = this.router.url.indexOf('home') >= 0;
    // this.isExhibition = (this.router.url.indexOf('exhibition') >= 0);
  }

  ngAfterViewInit() {
    this.loadData();
  }

  loadText() {
    this.translate.get('gdpr.linkPrivacy')
      .subscribe(value => {
        this.privacyLink = value;
      });

    this.translate.get('gdpr.linkContact')
      .subscribe(value => {
        this.contactLink = value;
      });

    this.translate.get('gdpr.linkHome')
      .subscribe(value => {
        this.homepageLink = value;
      });
  }

  loadData() {
    // console.log('menu loadData');
    this.vHostService.IsLoaded.subscribe(() => {
      // console.log('menu isVhost', this.vHostService.isVhost);
      this.showMenu = !this.vHostService.isVhost;
      setTimeout(() => {
        this.loaded = true;

        this.unreadMessages = this.userChatService.unreadMessages;
        this.userChatService.unreadMessagesChanged
          .subscribe( () => {
            this.unreadMessages = this.userChatService.unreadMessages;
          });
      }, 100);
      // this.loaded = true;
    });

    this.translate.onLangChange.subscribe(() => {
      this.language = this.translate.currentLang;
      this.loadText();
    });
    this.loadText();
  }

  is3dEnabled() {
    return environment.enable3D;
  }

  useLanguage(event: MouseEvent, newLanguage: string) {
    if (event) {
      event.preventDefault();
    }
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('currentLang', newLanguage);
    }
    this.language = newLanguage;
    this.translate.use(newLanguage);
  }

  showChat() {
    return !environment.production;
  }

  signOut($event: MouseEvent) {
    $event.preventDefault();
    this.dataService.signInService.logout();
    this.router.navigate(['/']);
  }

  editProfile($event: MouseEvent) {
    $event.preventDefault();

    const ref = this.modalService.open(ProfileComponent, {size: 'lg', backdrop: 'static'});
  }

  login($event: MouseEvent) {
    $event.preventDefault();

    const ref = this.modalService.open(PasswordLessLoginComponent, {size: 'md', backdrop: 'static'});
  }

  openCalendar($event: MouseEvent) {
    $event.preventDefault();
    const modal = this.modalService.open(ExpoCalendarComponent, {size: 'lg', backdrop: 'static'});
    const instance = modal.componentInstance as ExpoCalendarComponent;
    // instance.expo = this.expo;
    // instance.stageDict = this.expo.stageDict;
    modal.result
      .then(value => {

      }, reason => {

      });
  }

  openChat($event: MouseEvent) {
    $event.preventDefault();
  }
}
