<div [class]="class">
  <div class="modal-header">
    <h4 class="modal-title" [translate]="header" *ngIf="header"></h4>
    <a class="close" aria-label="Close" (click)="activeModal.close(false)">
      <span aria-hidden="true">&times;</span>
    </a>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="message">
      <p [translate]="message" [translateParams]="params">
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline($event)" [translate]="cancelText"
            *ngIf="cancelText"></button>
    <button type="button" class="btn btn-success" (click)="accept($event)" [translate]="okText" *ngIf="okText"></button>
    <button type="button" class="btn btn-primary" (click)="activeModal.close(false)" [translate]="'global.close'"
            *ngIf="!okText && !cancelText"></button>
  </div>
</div>
