import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-footer',
  templateUrl: './account-footer.component.html',
  styleUrls: ['./account-footer.component.scss']
})
export class AccountFooterComponent {

  constructor() { }

}
