/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { CategoryDto } from './categoryDto';
import { ExhibitorGroupReferenceDto } from './exhibitorGroupReferenceDto';
import { ExpoExhibitorListItemDto } from './expoExhibitorListItemDto';
import { VideoChatMode } from './videoChatMode';
import { ExpoDesignDto } from './expoDesignDto';
import { AccessMode } from './accessMode';


export interface ExpoListItemDto { 
    id?: string;
    shortKey?: string;
    name?: string;
    description?: string;
    descriptionLong?: string;
    intro?: string;
    dateStart?: Date;
    dateEnd?: Date;
    logo?: string;
    logoSquare?: string;
    logoWide?: string;
    exhibitors?: Array<ExpoExhibitorListItemDto>;
    categories?: Array<CategoryDto>;
    exhibitorGroups?: Array<ExhibitorGroupReferenceDto>;
    chatActive?: boolean;
    schedulingActive?: boolean;
    worldActive?: boolean;
    isPreview?: boolean;
    googleAnalyticsId?: string;
    matomoId?: string;
    externalMatomoServer?: string;
    externalMatomoId?: string;
    accessMode?: AccessMode;
    videoChatMode?: VideoChatMode;
    randomChatNames?: boolean;
    groupingEnabled?: boolean;
    exhibitorInfoVisibility?: boolean;
    liveStreamEnabled?: boolean;
    externalLinksDirectOpen?: boolean;
    mediaLinksSkipZoom?: boolean;
    domain?: string;
    expoDesign?: ExpoDesignDto;
    rowVersion?: number;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoListItemDto.
 */
export namespace ExpoListItemDto {
    /**
     * All properties of ExpoListItemDto.
     */
    export enum Properties {
        id = 'id',
        shortKey = 'shortKey',
        name = 'name',
        description = 'description',
        descriptionLong = 'descriptionLong',
        intro = 'intro',
        dateStart = 'dateStart',
        dateEnd = 'dateEnd',
        logo = 'logo',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        exhibitors = 'exhibitors',
        categories = 'categories',
        exhibitorGroups = 'exhibitorGroups',
        chatActive = 'chatActive',
        schedulingActive = 'schedulingActive',
        worldActive = 'worldActive',
        isPreview = 'isPreview',
        googleAnalyticsId = 'googleAnalyticsId',
        matomoId = 'matomoId',
        externalMatomoServer = 'externalMatomoServer',
        externalMatomoId = 'externalMatomoId',
        accessMode = 'accessMode',
        videoChatMode = 'videoChatMode',
        randomChatNames = 'randomChatNames',
        groupingEnabled = 'groupingEnabled',
        exhibitorInfoVisibility = 'exhibitorInfoVisibility',
        liveStreamEnabled = 'liveStreamEnabled',
        externalLinksDirectOpen = 'externalLinksDirectOpen',
        mediaLinksSkipZoom = 'mediaLinksSkipZoom',
        domain = 'domain',
        expoDesign = 'expoDesign',
        rowVersion = 'rowVersion'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoListItemDto.
    */
    export const ModelValidators: {[K in keyof ExpoListItemDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        shortKey: [
        ],
        name: [
        ],
        description: [
        ],
        descriptionLong: [
        ],
        intro: [
        ],
        dateStart: [
        ],
        dateEnd: [
        ],
        logo: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        exhibitors: [
        ],
        categories: [
        ],
        exhibitorGroups: [
        ],
        chatActive: [
        ],
        schedulingActive: [
        ],
        worldActive: [
        ],
        isPreview: [
        ],
        googleAnalyticsId: [
        ],
        matomoId: [
        ],
        externalMatomoServer: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        externalMatomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        accessMode: [
        ],
        videoChatMode: [
        ],
        randomChatNames: [
        ],
        groupingEnabled: [
        ],
        exhibitorInfoVisibility: [
        ],
        liveStreamEnabled: [
        ],
        externalLinksDirectOpen: [
        ],
        mediaLinksSkipZoom: [
        ],
        domain: [
        ],
        expoDesign: [
        ],
        rowVersion: [
        ],
    };

    /**
    * The FormControlFactory for ExpoListItemDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoListItemDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoListItemDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoListItemDto = {
            id: null,
            shortKey: null,
            name: null,
            description: null,
            descriptionLong: null,
            intro: null,
            dateStart: null,
            dateEnd: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            exhibitors: null,
            categories: null,
            exhibitorGroups: null,
            chatActive: null,
            schedulingActive: null,
            worldActive: null,
            isPreview: null,
            googleAnalyticsId: null,
            matomoId: null,
            externalMatomoServer: null,
            externalMatomoId: null,
            accessMode: null,
            videoChatMode: null,
            randomChatNames: null,
            groupingEnabled: null,
            exhibitorInfoVisibility: null,
            liveStreamEnabled: null,
            externalLinksDirectOpen: null,
            mediaLinksSkipZoom: null,
            domain: null,
            expoDesign: null,
            rowVersion: null,
          }
        ) {
            super(model, ExpoListItemDto.ModelValidators);
        }
    }

}


