import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupMessageComponent} from '../components/components/popup-message/popup-message.component';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(
    private ngbModal: NgbModal
  ) {
  }

  public showInfo(title: string, message: string, params: any = {}): Promise<any> {
    const modalRef = this.ngbModal.open(PopupMessageComponent);
    const inst = modalRef.componentInstance as PopupMessageComponent;
    inst.class = 'info';
    inst.header = title;
    inst.message = message;
    inst.params = params;
    return modalRef.result;
  }

  public showWarn(title: string, message: string, params: any = {}): Promise<any> {
    const modalRef = this.ngbModal.open(PopupMessageComponent);
    const inst = modalRef.componentInstance as PopupMessageComponent;
    inst.class = 'warn';
    inst.header = title;
    inst.message = message;
    inst.params = params;
    return modalRef.result;
  }
}
