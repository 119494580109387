import {Component, OnInit, ViewChild} from '@angular/core';
import {UserChatMessage, UserChatService} from '../../../services/user-chat.service';

@Component({
  selector: 'app-user-chat',
  templateUrl: './user-chat.component.html',
  styleUrls: ['./user-chat.component.scss']
})
export class UserChatComponent {

  constructor(
    public userChatService: UserChatService
  ) { }

  recountUnread() {
    this.userChatService.recountUnread();
  }
}
