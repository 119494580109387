<h1 [translate]="'global.exhibitions'"></h1>

<section class="container-fluid">
  <div class="row expo-blk-wrap" [attr.data-intro]="getIntro()" data-position="auto" id="introChooseExhibition">
    <ng-container *ngFor="let expo of currentExpos">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 expo-item">
        <app-expo-small [expo]="expo" [access]="access"></app-expo-small>
      </div>
    </ng-container>
  </div>
</section>

<ng-container *ngIf="futureExpos && futureExpos.length > 0">
  <h1 [translate]="'global.futureExhibitions'"></h1>

  <section class="container-fluid">
    <div class="row expo-blk-wrap">
      <ng-container *ngFor="let expo of futureExpos">
        <div class="col-6 col-sm-4 col-md-3 col-lg-2 expo-item">
          <app-expo-small [expo]="expo" [access]="access"></app-expo-small>
        </div>
      </ng-container>
    </div>
  </section>
</ng-container>

<ng-container *ngIf="pastExpos && pastExpos.length > 0">
  <h1 [translate]="'global.pastExhibitions'"></h1>

  <section class="container-fluid">
    <div class="row expo-blk-wrap">
      <ng-container *ngFor="let expo of pastExpos">
        <div class="col-4 col-sm-4 col-md-3 col-lg-2 expo-item">
          <app-expo-small [expo]="expo" [access]="access" [isPastEvent]="true"></app-expo-small>
        </div>
      </ng-container>
    </div>
  </section>
</ng-container>
