import {EventEmitter, Injectable, Injector} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DownloadRegisterComponent} from '../components/components/download-register/download-register.component';
import {SignInData} from './sign-in-data';
import {RandomNameGeneratorService} from './random-name-generator.service';
import {ChatRegisterComponent} from '../components/components/chat-register/chat-register.component';
import {TranslateService} from '@ngx-translate/core';
import {ExpoEntryComponent} from '../components/components/expo-entry/expo-entry.component';
import {DataService} from './data.service';
import {LoginResultDto, PwdLessCallbackDto, PwdLessLoginDto, WebService} from '../virtual-expo-api';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable, Subscription, throwError} from 'rxjs';

const dayjs = require('dayjs');

@Injectable({
  providedIn: 'root'
})
export class SignInService {
  data: SignInData;

  cacheKey = 'signindata';
  private dataAnon: SignInData;

  givenName: string;
  email: string;

  OnLogin: EventEmitter<boolean> = new EventEmitter<boolean>();
  OnLogout: EventEmitter<boolean> = new EventEmitter<boolean>();
  isAnon = true;
  private jwtHelper: JwtHelperService;

  constructor(
    private modalService: NgbModal
    , private translateService: TranslateService
    , private randomNameGeneratorService: RandomNameGeneratorService
    , private webService: WebService
  ) {
    this.jwtHelper = new JwtHelperService();

    if (this.isLoggedIn()) {
      this.data = {
        email: this.email,
        name: this.givenName,
        token: this.getTokenString(),
        permit: true,
        isAnon: false,
        expoId: '',
        hasSignIn: false,
        userId: ''
      };
    } else {
      const tmp = sessionStorage.getItem(this.cacheKey);
      if (tmp) {
        this.data = JSON.parse(tmp);
        const decodedToken = this.jwtHelper.decodeToken(this.data.token);
        this.data.userId = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];

        if (this.data.isAnon) {
          this.dataAnon = this.data;
        }
      }
    }

    this.OnLogin.subscribe((value) => {
      this.data = {
        email: this.email,
        name: this.givenName,
        token: this.getTokenString(),
        permit: true,
        isAnon: false,
        expoId: '',
        hasSignIn: true,
        userId: ''
      };
    });

    this.OnLogout.subscribe((value) => {
      this.data = null;
      sessionStorage.removeItem(this.cacheKey);
    });
  }

  getAnonToken(expoBase: { randomChatNames?: boolean }): Promise<SignInData> {
    const promise = new Promise<SignInData>((resolve, reject) => {
      if (this.data && this.data.permit && this.data.token && this.data.token !== '') {
        resolve(this.data);
        return;
      }

      if (this.dataAnon) {
        resolve(this.dataAnon);
        return;
      }

      let name = `${this.translateService.instant('global.guestName')} ${Math.floor(Math.random() * 89999) + 10000}`;
      if (expoBase.randomChatNames) {
        name = this.randomNameGeneratorService.generateName();
      }

      this.data = {
        email: '',
        name: name,
        permit: false,
        token: '',
        isAnon: true,
        expoId: '',
        hasSignIn: false,
        userId: ''
      };
      this.webService.webAnonToken(this.data)
        .subscribe((success) => {
            this.data.token = success as string;

            const decodedToken = this.jwtHelper.decodeToken(this.data.token);
            this.data.userId = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];

            const tmp = sessionStorage.setItem(this.cacheKey, JSON.stringify(this.data));
            this.dataAnon = this.data;
            resolve(this.data);
          },
          (error) => {
            reject(error);
          }
        );
    });
    return promise;
  }

  getToken(): Promise<SignInData> {
    const promise = new Promise<SignInData>((resolve, reject) => {
      if (this.data && this.data.permit && this.data.token && this.data.token !== '') {
        resolve(this.data);
        return;
      }
      const ref = this.modalService.open(DownloadRegisterComponent, {backdrop: 'static', size: 'lg'});
      ref.result.then((result) => {
        if (result && result.success) {
          this.data = result.data as SignInData;
          this.webService.webSignIn(this.data)
            .subscribe((success) => {
                this.data.token = success as string;

                const decodedToken = this.jwtHelper.decodeToken(this.data.token);
                this.data.userId = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];

                this.data.hasSignIn = true;
                const tmp = sessionStorage.setItem(this.cacheKey, JSON.stringify(this.data));

                resolve(this.data);
              },
              (error) => {
                reject(error);
              }
            );
        } else {
          reject();
        }
      }, (reason) => {
        reject(reason);
      });
      if (this.data && !this.data.isAnon) {
        ref.componentInstance.preset = this.data;
      }
    });
    return promise;
  }

  getEntryToken(): Promise<SignInData> {
    const promise = new Promise<SignInData>((resolve, reject) => {
      if (this.data && this.data.permit && this.data.token && this.data.token !== '') {
        resolve(this.data);
        return;
      }
      const ref = this.modalService.open(ExpoEntryComponent, {backdrop: 'static', size: 'xl'});
      ref.result.then((result) => {
        if (result && result.success) {
          this.data = result.data as SignInData;
          this.webService.webSignIn(this.data)
            .subscribe((success) => {
                this.data.token = success as string;

                const decodedToken = this.jwtHelper.decodeToken(this.data.token);
                this.data.userId = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];

                this.data.hasSignIn = true;
                const tmp = sessionStorage.setItem(this.cacheKey, JSON.stringify(this.data));
                resolve(this.data);
              },
              (error) => {
                reject(error);
              }
            );
        } else {
          reject();
        }
      }, (reason) => {
        reject(reason);
      });
      if (this.data && !this.data.isAnon) {
        ref.componentInstance.preset = this.data;
      }
    });
    return promise;
  }

  signIn(data: SignInData): Promise<SignInData> {
    return new Promise<SignInData>((resolve, reject) => {
      this.webService.webSignIn(data)
        .subscribe((success) => {
            this.data = data;
            this.data.token = success as string;

            const decodedToken = this.jwtHelper.decodeToken(this.data.token);
            this.data.userId = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];

            this.data.hasSignIn = true;
            const tmp = sessionStorage.setItem(this.cacheKey, JSON.stringify(this.data));
            this.validateSignIn(this.data.token);
            resolve(this.data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getChatToken(expoId: string): Promise<SignInData> {
    const promise = new Promise<SignInData>((resolve, reject) => {
      if (this.data && this.data.token && this.data.token !== '' && this.data.expoId === expoId) {
        resolve(this.data);
        return;
      }
      const ref = this.modalService.open(ChatRegisterComponent, {backdrop: 'static', size: 'xl'});
      ref.result.then((result) => {
        if (result.success) {
          this.data = result.data as SignInData;
          this.data.expoId = expoId;
          this.data.email = `${encodeURI(this.data.name)}@${this.data.email}.internal`;
          this.data.permit = true;
          this.webService.webSignIn(this.data)
            .subscribe((success) => {
                this.data.token = success as string;

                const decodedToken = this.jwtHelper.decodeToken(this.data.token);
                this.data.userId = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];

                this.data.hasSignIn = true;
                const tmp = sessionStorage.setItem(this.cacheKey, JSON.stringify(this.data));
                resolve(this.data);
              },
              (error) => {
                reject(error);
              }
            );
        }
      }, (reason) => {
        reject(reason);
      });
      if (!this.data.isAnon) {
        ref.componentInstance.preset = this.data;
      }
    });
    return promise;
  }

  signOut(expoBase: { randomChatNames?: boolean }): Promise<SignInData> {
    this.data = null;
    this.dataAnon = null;
    this.logout();
    return this.getAnonToken(expoBase);
  }


  setSession(authResult: LoginResultDto): void {
    const expiresAt = dayjs().add(authResult.lifetime, 'second');

    localStorage.setItem('id_token', authResult.token);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  }

  public startPwdLess(email: string): Observable<boolean> {
    const loginData: PwdLessLoginDto = {email: email};
    return this.webService.webPwdLessStart(loginData);
  }

  public validatePwdLess(email: string, token: string): Observable<LoginResultDto> {
    const loginData: PwdLessCallbackDto = {email: email, token: token};
    return this.webService.webPwdLessCallback(loginData)
      .pipe(source => {
        source.subscribe(value => {
          this.setSession(value);
          this.getTokenData();
          this.isAnon = false;
          this.OnLogin.emit(true);
          return value;
        });
        return source;
      });
  }

  public validateTotpPwdLess(email: string, token: string): Observable<LoginResultDto> {
    const loginData: PwdLessCallbackDto = {email: email, token: token};
    return this.webService.webPwdLessTotpCallback(loginData)
      .pipe(source => {
        source.subscribe(value => {
          this.setSession(value);
          this.getTokenData();
          this.isAnon = false;
          this.OnLogin.emit(true);
          return value;
        });
        return source;
      });
  }

  //
  // public updatedPwdLessProfile(dto: PwdLessProfileDto): Observable<LoginResultDto> {
  //   return this.accountApi.webPwdLessProfile(dto)
  //     .pipe(source => {
  //       source.subscribe(value => {
  //         AuthService.setSession(value);
  //         this.getTokenData();
  //         return value;
  //       });
  //       return source;
  //     });
  // }

  logout(): void {
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    this.givenName = '';
    this.email = '';
    this.isAnon = true;
    this.OnLogout.emit(true);
  }

  public isLoggedIn(): boolean {
    if (this.getTokenData()) {
      return dayjs().isBefore(this.getExpiration());
    }
    return false;
  }

  isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  getExpiration(): any {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return dayjs(expiresAt);
  }

  public getTokenString(): string {
    const token = localStorage.getItem('id_token');
    return token;
  }

  public getTokenData(): any {
    const helper = new JwtHelperService();

    const token = localStorage.getItem('id_token');
    if (token && token !== 'null' && token !== '[object Object]') {
      const decodedToken = helper.decodeToken(token);
      const expirationDate = helper.getTokenExpirationDate(token);
      const isExpired = helper.isTokenExpired(token);
      this.email = decodedToken.sub;
      this.givenName = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
      return decodedToken;
    }
    this.givenName = '';
    this.email = '';
    return null;
  }

  public validateToken(token: string): Observable<LoginResultDto> {
    if (!token || token === 'null' || token === '[object Object]') {
      throwError('invalid');
    }
    localStorage.setItem('id_token', token);
    const helper = new JwtHelperService();
    const expirationDate = helper.getTokenExpirationDate(token);
    localStorage.setItem('expires_at', JSON.stringify(expirationDate.valueOf()));

    return this.webService.webRefresh()
      .pipe(source => {
        source.subscribe(value => {
          this.setSession(value);
          return value;
        }, error => {
          localStorage.removeItem('id_token');
          localStorage.removeItem('expires_at');
          return null;
        });
        return source;
      });
  }

  public validateSignIn(token: string): Observable<LoginResultDto> {
    if (!token || token === 'null' || token === '[object Object]') {
      return throwError('invalid');
    }
    localStorage.setItem('id_token', token);
    const helper = new JwtHelperService();
    const expirationDate = helper.getTokenExpirationDate(token);
    localStorage.setItem('expires_at', JSON.stringify(expirationDate.valueOf()));

    const data = this.getTokenData();
    this.isAnon = true;
  }

  refresh() {
    const tmp = localStorage.getItem('id_token');
    this.validateToken(tmp)
      .pipe(source => {
        source.subscribe(value => {
          this.getTokenData();
        }, error => {
          this.givenName = '';
          this.email = '';
          this.isAnon = true;
        });
        return source;
      });
  }
}
