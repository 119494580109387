import {Injectable} from '@angular/core';
import {MetaTag} from '../objects/meta-tag';
import {Meta, Title} from '@angular/platform-browser';
import {Observable, Subject} from 'rxjs';
import {ExpoLdMeta, PageLdMeta} from '../objects/page-ld-meta';
import {ExpoExhibitorListItemDto, ExpoOverviewDto} from '../virtual-expo-api';
import {LinkHandlerService} from './link-handler.service';
import {Location} from '@angular/common';
import {ImageUrlService} from '../components/booths/image-url.service';
import {MarkdownService} from 'ngx-markdown';
import {TrackingService} from './tracking.service';
import {ExhibitorExtended} from '../objects/exhibitor-extended';
import {ExpoOverviewExtended} from '../objects/expo-overview-extended';

declare let _mtm: any;
declare let _paq: any;

@Injectable({
  providedIn: 'root'
})
export class TitleTagService {

  private urlMeta = 'og:url';
  private titleMeta = 'og:title';
  private descriptionMeta = 'og:description';
  private imageMeta = 'og:image';
  private secureImageMeta = 'og:image:secure_url';
  private twitterTitleMeta = 'twitter:text:title';
  private twitterImageMeta = 'twitter:image';

  private _updateExhibitorLd: Subject<PageLdMeta> = new Subject();

  public get UpdateExhibitorLd(): Observable<PageLdMeta> {
    return this._updateExhibitorLd.asObservable();
  }

  private _updateExpoLd: Subject<ExpoLdMeta> = new Subject();

  public get UpdateExpoLd(): Observable<ExpoLdMeta> {
    return this._updateExpoLd.asObservable();
  }

  constructor(private titleService: Title, private metaService: Meta, private location: Location
    , private linkHandlerService: LinkHandlerService, private imageUrlService: ImageUrlService
    , private markdownService: MarkdownService, private trackingService: TrackingService
  ) {
  }

  private setTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  private getImage(image: string): string {
    let imageUrl = `${image}`;
    if (image === '') {
      imageUrl = '/assets/loading.png';
    }
    return imageUrl;
  }

  private getPrefix(): string {
    const host = location.hostname;
    const proto = location.protocol;
    const port = (location.port ?? '').trim();
    let prefix = `${proto}//${host}:${port}`;
    if ((location.port === '80' && proto === 'http:') || (location.port === '443' && proto === 'https:') || location.port === '') {
      prefix = `${proto}//${host}`;
    }
    return prefix;
  }

  private setMetaTags(url: string, title: string, description: string, image: string): void {
    const imageUrl = this.getImage(image);
    const tags = [
      new MetaTag(this.urlMeta, url, true),
      new MetaTag(this.titleMeta, title, true),
      new MetaTag(this.descriptionMeta, description, true),
      new MetaTag(this.imageMeta, imageUrl, true),
      new MetaTag(this.secureImageMeta, imageUrl, true),
      new MetaTag(this.twitterTitleMeta, title, false),
      new MetaTag(this.twitterImageMeta, imageUrl, false),

      new MetaTag('description', description, false)
    ];
    this.setTags(tags);
  }

  private parseHtmlEntities(str): string {
    return str.replace(/&#([0-9]{1,4});/gi, function (match, numStr) {
      const num = parseInt(numStr, 10); // read num as normal number
      return String.fromCharCode(num);
    });
  }

  private clearDescription(description: string): string {
    description = this.markdownService.compile(description, true);
    description = this.parseHtmlEntities(description);
    description = description.replace(/[\r]/g, '');
    // description = description.replace(/&#10;/g, ' ');
    description = description.replace(/[\n\t]/g, ' ');
    description = description.replace(/<[^>]*>?/gm, '');
    return description;
  }

  public setExpoTags(dto: ExpoOverviewDto): void {
    const prefix = this.getPrefix();

    const path = this.location.prepareExternalUrl(this.linkHandlerService.getExpoUrl(dto));

    const ld: ExpoLdMeta = {
      url: prefix + path,
      title: dto.name.trim(),
      description: dto.description && dto.description !== ''
        ? dto.description
        : (dto.descriptionLong || '').substr(0, 200),
      images: [this.imageUrlService.getLogo(dto)],
      startDate: new Date(dto.dateStart),
      endDate: new Date(dto.dateEnd)
    };

    ld.description = this.clearDescription(ld.description);

    this.setMetaTags(ld.url, ld.title, ld.description, ld.images[0]);
    this._updateExpoLd.next(ld);

    this.setTitle(ld.title);

    this.trackingService.trackPageView(dto, null, ld.title, `/${path}`);

    // if (environment.matomoActive) {
    //   _paq.push(['setCustomUrl', '/' + path]);
    //   _paq.push(['setDocumentTitle', ld.title]);
    //   _paq.push(['trackPageView']);
    // }
  }

  private setTags(tags: MetaTag[]): void {
    tags.forEach(siteTag => {
      const tag = siteTag.isFacebook ? this.metaService.getTag(`property='${siteTag.name}'`) : this.metaService.getTag(`name='${siteTag.name}'`);
      if (siteTag.isFacebook) {
        this.metaService.updateTag({property: siteTag.name, content: siteTag.value});
      } else {
        this.metaService.updateTag({name: siteTag.name, content: siteTag.value});
      }
    });
  }

  public setExhibitorTags(exhibitor: ExpoExhibitorListItemDto) {
    const prefix = this.getPrefix();

    const path = this.location.prepareExternalUrl(this.linkHandlerService.getExhibitorUrl(exhibitor.expo, exhibitor));
    const ld: PageLdMeta = {
      url: prefix + path,
      title: exhibitor.expo.name.trim() + ': ' + exhibitor.label,
      description: exhibitor.description && exhibitor.description.trim() !== ''
        ? exhibitor.description
        : (exhibitor.descriptionLong || '').substr(0, 200),
      images: [this.imageUrlService.getLogo(exhibitor)],
      orgaName: exhibitor.expo.name,
      orgaLogo: this.imageUrlService.getLogo(exhibitor.expo),
      startDate: new Date(exhibitor.expo.dateStart)
    };

    ld.description = this.clearDescription(ld.description);

    this.setMetaTags(ld.url, ld.title, ld.description, ld.images[0]);
    this._updateExhibitorLd.next(ld);

    this.setTitle(ld.title);

    this.trackingService.trackPageView(exhibitor.expo, exhibitor, ld.title, `/${path}`);

    // if (environment.matomoActive) {
    //   _paq.push(['setCustomUrl', '/' + path]);
    //   _paq.push(['setDocumentTitle', ld.title]);
    //   _paq.push(['trackPageView']);
    // }
  }

  public setExpoExhibitorTags(expo: ExpoOverviewExtended, exhibitor: ExhibitorExtended) {
    const prefix = this.getPrefix();

    const path = this.location.prepareExternalUrl(this.linkHandlerService.getExhibitorUrl(expo, exhibitor));
    const ld: PageLdMeta = {
      url: prefix + path,
      title: expo.name.trim() + ': ' + exhibitor.label,
      description: exhibitor.description && exhibitor.description.trim() !== ''
        ? exhibitor.description
        : (exhibitor.descriptionLong || '').substr(0, 200),
      images: [this.imageUrlService.getLogo(exhibitor)],
      orgaName: exhibitor.expo.name,
      orgaLogo: this.imageUrlService.getLogo(expo),
      startDate: new Date(exhibitor.expo.dateStart)
    };

    ld.description = this.clearDescription(ld.description);

    this.setMetaTags(ld.url, ld.title, ld.description, ld.images[0]);
    this._updateExhibitorLd.next(ld);

    this.setTitle(ld.title);

    this.trackingService.trackPageView(exhibitor.expo, exhibitor, ld.title, `/${path}`);

    // if (environment.matomoActive) {
    //   _paq.push(['setCustomUrl', '/' + path]);
    //   _paq.push(['setDocumentTitle', ld.title]);
    //   _paq.push(['trackPageView']);
    // }
  }
}
