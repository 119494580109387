import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {GdprPermissions} from '../../../objects/gdpr-permissions';

@Component({
  selector: 'app-gdpr-permissions',
  templateUrl: './gdpr-permissions.component.html',
  styleUrls: ['./gdpr-permissions.component.scss']
})
export class GdprPermissionsComponent implements OnInit {
  @Input() permissions: GdprPermissions;

  allVideos: boolean;
  allAnalytics: boolean;
  showInternalDetails: boolean;
  showVideoDetails: boolean;
  showAnalyticsDetails: boolean;
  showDetails: boolean;

  constructor(
    private activeModal: NgbActiveModal
    , private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.checkAll();
  }

  checkAll() {
    this.allVideos = this.permissions.zoomPermitted && this.permissions.cloudflarePermitted
      && this.permissions.vimeoPermitted && this.permissions.youtubePermitted && this.permissions.webexPermitted;

    this.allAnalytics = this.permissions.analyticsPermitted;
  }

  public decline() {
    this.permissions.cloudflarePermitted = false;
    this.permissions.vimeoPermitted = false;
    this.permissions.youtubePermitted = false;
    this.permissions.analyticsPermitted = false;
    this.permissions.zoomPermitted = false;
    this.permissions.agoraPermitted = false;
    this.permissions.webexPermitted = false;
    this.checkAll();
    this.activeModal.close(true);
  }

  public accept() {
    this.permissions.permissionChecked = true;
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  confirmAll() {
    this.permissions.cloudflarePermitted = true;
    this.permissions.vimeoPermitted = true;
    this.permissions.youtubePermitted = true;
    this.permissions.analyticsPermitted = true;
    this.permissions.agoraPermitted = true;
    this.permissions.zoomPermitted = true;
    this.permissions.webexPermitted = true;
    this.checkAll();
    this.accept();
  }

  checkAllVideos($event: boolean) {
    this.permissions.cloudflarePermitted = $event;
    this.permissions.vimeoPermitted = $event;
    this.permissions.youtubePermitted = $event;
    this.permissions.zoomPermitted = $event;
    this.permissions.agoraPermitted = $event;
    this.permissions.webexPermitted = $event;
    this.checkAll();
  }

  toggleVideoDetails($event: MouseEvent) {
    $event.preventDefault();
    this.showVideoDetails = !this.showVideoDetails;
  }

  toggleAnalyticsDetails($event: MouseEvent) {
    $event.preventDefault();
    this.showAnalyticsDetails = !this.showAnalyticsDetails;
  }

  checkAllAnalytics($event: boolean) {
    this.permissions.analyticsPermitted = $event;
    this.checkAll();
  }

  toggleInternalDetails($event: MouseEvent) {
    $event.preventDefault();
    this.showInternalDetails = !this.showInternalDetails;
  }

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }
}
