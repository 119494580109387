<div style="z-index: 1000001">
  <div class="modal-header">
    <h4 class="modal-title" translate="gdpr.title"></h4>
    <a class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </a>
  </div>
  <div class="modal-body">
    <p translate="gdpr.description"></p>
    <div style="font-size: 0.75rem">
      <p translate="gdpr.label"></p>
      <button type="button" class="btn btn-success float-right" (click)="confirmAll()"
              translate="gdpr.confirmAll" *ngIf="showDetails"></button>
      <button type="button" class="btn btn-outline-secondary btn-xs float-right mr-3" (click)="toggleDetails()"
              translate="gdpr.showDetails" *ngIf="!showDetails"></button>
      <app-legal-links></app-legal-links>
    </div>
    <hr *ngIf="showDetails">
    <table class="gdpr-permissions" *ngIf="showDetails">
      <thead>
      <th translate="gdpr.service"></th>
      <th translate="gdpr.allow"></th>
      </thead>
      <tbody>
      <tr>
        <td>
          <span translate="gdpr.internalService"></span>
          <button class="btn btn-xs btn-outline-primary" style="margin-left: 1em;"
                  (click)="toggleInternalDetails($event)">
            <i class="fa fa-caret-down"></i> <span translate="gdpr.details"></span>
          </button>

          <br/>
          <span class="privacy-description" translate="gdpr.allInternal"></span>
        </td>
        <td>
          <ui-switch checked="true" disabled="true"></ui-switch>
        </td>
      </tr>
      <ng-container *ngIf="this.showInternalDetails">
        <tr>
          <td>
            <div class="ml-4">
              <span translate="gdpr.localHeader"></span><br/>
              <span class="privacy-description" translate="gdpr.localDetails"></span>
            </div>
          </td>
          <td>
            <ui-switch checked="true" disabled="true"></ui-switch>
          </td>
        </tr>
        <tr>
          <td>
            <div class="ml-4">
              Matomo<br/>
              <span class="privacy-description" translate="gdpr.matomo"></span>
            </div>
          </td>
          <td>
            <ui-switch checked="true" disabled="true"></ui-switch>
          </td>
        </tr>
        <tr>
          <td>
            <div class="ml-4">
              Cloudflare CDN<br/>
              <span class="privacy-description" translate="gdpr.cloudflareCDN"></span>
            </div>
          </td>
          <td>
            <ui-switch checked="true" disabled="true"></ui-switch>
          </td>
        </tr>
        <tr>
          <td>
            <div class="ml-4">
              MailJet<br/>
              <span class="privacy-description" translate="gdpr.mailjet"></span>
            </div>
          </td>
          <td>
            <ui-switch checked="true" disabled="true"></ui-switch>
          </td>
        </tr>
      </ng-container>
      <tr>
        <td>
          Videos
          <button class="btn btn-xs btn-outline-primary" style="margin-left: 1em;" (click)="toggleVideoDetails($event)">
            <i class="fa fa-caret-down"></i> <span translate="gdpr.details"></span>
          </button>

          <br/>
          <span class="privacy-description" translate="gdpr.allVideos"></span>
        </td>
        <td>
          <ui-switch [(ngModel)]="allVideos" (change)="checkAllVideos($event)"></ui-switch>
        </td>
      </tr>
      <ng-container *ngIf="this.showVideoDetails">
        <tr>
          <td>
            <div class="ml-4">
              Zoom<br/>
              <span class="privacy-description" translate="gdpr.zoom"></span>
            </div>
          </td>
          <td>
            <ui-switch [(ngModel)]="permissions.zoomPermitted" (change)="checkAll()"></ui-switch>
          </td>
        </tr>
        <tr>
          <td>
            <div class="ml-4">
              Cisco WebEx<br/>
              <span class="privacy-description" translate="gdpr.webex"></span>
            </div>
          </td>
          <td>
            <ui-switch [(ngModel)]="permissions.webexPermitted" (change)="checkAll()"></ui-switch>
          </td>
        </tr>
        <tr>
          <td>
            <div class="ml-4">
              Youtube<br/>
              <span class="privacy-description" translate="gdpr.youtube"></span>
            </div>
          </td>
          <td>
            <ui-switch [(ngModel)]="permissions.youtubePermitted" (change)="checkAll()"></ui-switch>
          </td>
        </tr>
        <tr>
          <td>
            <div class="ml-4">
              Vimeo<br/>
              <span class="privacy-description" translate="gdpr.vimeo"></span>
            </div>
          </td>
          <td>
            <ui-switch [(ngModel)]="permissions.vimeoPermitted"></ui-switch>
          </td>
        </tr>
        <tr>
          <td>
            <div class="ml-4">
              Cloudflare Streams<br/>
              <span class="privacy-description" translate="gdpr.cloudflare" (change)="checkAll()"></span>
            </div>
          </td>
          <td>
            <ui-switch [(ngModel)]="permissions.cloudflarePermitted"></ui-switch>
          </td>
        </tr>
        <tr>
          <td>
            <div class="ml-4">
              Agora.IO<br/>
              <span class="privacy-description" translate="gdpr.agora"></span>
            </div>
          </td>
          <td>
            <ui-switch [(ngModel)]="permissions.agoraPermitted" (change)="checkAll()"></ui-switch>
          </td>
        </tr>
      </ng-container>
      <tr>
        <td>
          Analytics
          <button class="btn btn-xs btn-outline-primary" style="margin-left: 1em;"
                  (click)="toggleAnalyticsDetails($event)">
            <i class="fa fa-caret-down"></i> <span translate="gdpr.details"></span>
          </button>

          <br/>
          <span class="privacy-description" translate="gdpr.allAnalytics"></span>
        </td>
        <td>
          <ui-switch [(ngModel)]="allAnalytics" (change)="checkAllAnalytics($event)"></ui-switch>
        </td>
      </tr>
      <ng-container *ngIf="this.showAnalyticsDetails">
        <tr>
          <td>
            <div class="ml-4">
              Google Analytics<br/>
              <span class="privacy-description" translate="gdpr.analytics"></span>
            </div>
          </td>
          <td>
            <ui-switch [(ngModel)]="permissions.analyticsPermitted" (change)="checkAll()"></ui-switch>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <div class="d-flex row w-100 justify-content-between">
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="decline()"
              translate="gdpr.denyAll"></button>
      <button type="button" class="btn btn-success" (click)="confirmAll()"
              translate="gdpr.confirmAll"></button>
      <button type="button" class="btn btn-secondary" (click)="accept()" *ngIf="showDetails"
              translate="gdpr.save"></button>
    </div>
  </div>
</div>
