/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface CalendarEntryBoothViewDtoAllOf { 
    exhibitorShortKey?: string;
}

/**
 * Namespace for property- and property-value-enumerations of CalendarEntryBoothViewDtoAllOf.
 */
export namespace CalendarEntryBoothViewDtoAllOf {
    /**
     * All properties of CalendarEntryBoothViewDtoAllOf.
     */
    export enum Properties {
        exhibitorShortKey = 'exhibitorShortKey'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of CalendarEntryBoothViewDtoAllOf.
    */
    export const ModelValidators: {[K in keyof CalendarEntryBoothViewDtoAllOf]: [string, ValidatorFn][]} = {
        exhibitorShortKey: [
        ],
    };

    /**
    * The FormControlFactory for CalendarEntryBoothViewDtoAllOf.
    */
    export class FormControlFactory extends BaseFormControlFactory<CalendarEntryBoothViewDtoAllOf> {

        /**
         * Constructor.
         *
         * @param model An existing model for CalendarEntryBoothViewDtoAllOf.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: CalendarEntryBoothViewDtoAllOf = {
            exhibitorShortKey: null,
          }
        ) {
            super(model, CalendarEntryBoothViewDtoAllOf.ModelValidators);
        }
    }

}


