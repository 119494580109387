<div class="modal-header">
  <h4 class="modal-title" [translate]="'registerChat.header'"></h4>
  <a class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </a>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-xs-12">
      <div style="padding: 1em 2em;">
        <span [translate]="'registerChat.intro'"></span>
        <hr>
        <form [formGroup]="valForm" class="form-validate mb-lg" role="form" name="expoForm" novalidate=""
              (submit)="submitForm($event)">
          <div class="form-group has-feedback">
            <label for="name">{{ 'registerChat.name' | translate}}</label>
            <input class="form-control" id="name" type="text" name="name"
                   [placeholder]="'registerChat.entername' | translate"
                   autocomplete="off"
                   formControlName="name" required=""/>
            <span class="form-control-feedback text-muted"></span>
            <span class="text-danger"
                  *ngIf="valForm.controls['name'].hasError('required') && (valForm.controls['name'].dirty || valForm.controls['name'].touched)">{{ 'form.required' | translate }}</span>
            <span class="text-danger"
                  *ngIf="valForm.controls['name'].hasError('minlength') && (valForm.controls['name'].dirty || valForm.controls['name'].touched)">{{ 'form.minlength' | translate:{
              'actual': valForm.controls['name'].getError('minlength').actualLength,
              'required': valForm.controls['name'].getError('minlength').requiredLength
            } }}</span>
            <span class="text-danger"
                  *ngIf="valForm.controls['name'].hasError('maxlength') && (valForm.controls['name'].dirty || valForm.controls['name'].touched)">{{ 'form.maxlength' | translate:{
              'actual': valForm.controls['name'].getError('maxlength').actualLength,
              'required': valForm.controls['name'].getError('maxlength').requiredLength
            } }}</span>
          </div>
        </form>
        <hr>
        <span [translate]="'registerChat.description'"></span><br/>
        <app-legal-links></app-legal-links>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark pull-left"
          (click)="activeModal.close()"
          (mousedown)="$event.preventDefault()"
          [translate]="'registerChat.deny'"
  ></button>

  <button type="button" class="btn btn-success" (click)="submitForm($event)" [disabled]="!valForm.valid"
          [translate]="'registerChat.allow'"></button>
</div>
