import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {SignInData} from '../services/sign-in-data';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    const idToken = localStorage.getItem('id_token');

    if (idToken) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization',
          'Bearer ' + idToken)
      });

      return next.handle(cloned);
    } else {
      const signIn = sessionStorage.getItem('signindata');
      if (signIn) {
        const signInData: SignInData = JSON.parse(signIn);
        const cloned = req.clone({
          headers: req.headers.set('Authorization',
            'Bearer ' + signInData.token)
        });

        return next.handle(cloned);
      }
      else {
        return next.handle(req);
      }
    }
  }
}
