/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface TrackingSettings { 
    googleAnalyticsId?: string;
    matomoId?: string;
    externalMatomoServer?: string;
    externalMatomoId?: string;
}

/**
 * Namespace for property- and property-value-enumerations of TrackingSettings.
 */
export namespace TrackingSettings {
    /**
     * All properties of TrackingSettings.
     */
    export enum Properties {
        googleAnalyticsId = 'googleAnalyticsId',
        matomoId = 'matomoId',
        externalMatomoServer = 'externalMatomoServer',
        externalMatomoId = 'externalMatomoId'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of TrackingSettings.
    */
    export const ModelValidators: {[K in keyof TrackingSettings]: [string, ValidatorFn][]} = {
        googleAnalyticsId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        matomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        externalMatomoServer: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        externalMatomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
    };

    /**
    * The FormControlFactory for TrackingSettings.
    */
    export class FormControlFactory extends BaseFormControlFactory<TrackingSettings> {

        /**
         * Constructor.
         *
         * @param model An existing model for TrackingSettings.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: TrackingSettings = {
            googleAnalyticsId: null,
            matomoId: null,
            externalMatomoServer: null,
            externalMatomoId: null,
          }
        ) {
            super(model, TrackingSettings.ModelValidators);
        }
    }

}


