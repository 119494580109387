import {Injectable} from '@angular/core';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {ConfirmationDialogComponent} from './confirmation-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ConfirmationDialogService {

  constructor(private modalService: NgbModal, private translateService: TranslateService) {
  }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, {size: dialogSize, backdrop: 'static'});
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  public confirmTranslate(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm' | 'md' | 'lg' = 'sm',
    parameters?: {},
    warning: boolean = false,
  ): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, {size: dialogSize, backdrop: 'static'});
    modalRef.componentInstance.title = this.translateService.instant(title, parameters);
    modalRef.componentInstance.message = this.translateService.instant(message, parameters);
    modalRef.componentInstance.btnOkText = this.translateService.instant(btnOkText, parameters);
    modalRef.componentInstance.btnCancelText = this.translateService.instant(btnCancelText, parameters);
    modalRef.componentInstance.warning = warning;

    return modalRef.result;
  }

}
