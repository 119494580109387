import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UserChatMessage, UserChatService, UserChatSession} from '../../../services/user-chat.service';
import {TranslateService} from '@ngx-translate/core';
import {SignInService} from '../../../services/sign-in.service';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {VideoCallComponent} from '../video-call/video-call.component';

@Component({
  selector: 'app-user-chat-session',
  templateUrl: './user-chat-session.component.html',
  styleUrls: ['./user-chat-session.component.scss']
})
export class UserChatSessionComponent implements OnInit, AfterViewInit {
  @Input() sessionId: string;
  @Output() unreadChanged: EventEmitter<any> = new EventEmitter<any>();

  message: string;

  @ViewChild('chatContentFrame', {static: false}) chatLog: ElementRef;
  session: UserChatSession;

  newMessageDebounce = new Subject();
  openMessageDebounce = new Subject();

  unreadMessages = 0;

  callModal: NgbModalRef;

  constructor(
    public userChatService: UserChatService
    , private signInService: SignInService
    , private translateService: TranslateService
    , private dialogService: ConfirmationDialogService
    , private ngbModalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.session = this.userChatService.sessions[this.sessionId];

    this.session.newMessage.subscribe(value => {
      this.addLine(value);
      if (!this.session.open) {
        // debugger;
        // this.session.unreadMessages++;
        this.newMessageDebounce.next();
      } else {
        this.openMessageDebounce.next();
      }
    });

    this.newMessageDebounce.pipe(debounceTime(500))
      .pipe(source => {
        source.subscribe(value => {
          this.unreadMessages = this.session.unreadMessages;
          this.unreadChanged.emit();
        });
        return source;
      });

    this.openMessageDebounce.pipe(debounceTime(600))
      .pipe(source => {
        source.subscribe(value => {
          this.userChatService.persistRead(this.session);
        });
        return source;
      });
  }

  ngAfterViewInit() {
    if (this.session.messages.length > 0) {
      this.session.messages.forEach(value => {
        // if (!this.session.open) {
        //   debugger;
        //   this.session.unreadMessages++;
        // }
        this.addLine(value);

      });
      this.newMessageDebounce.next();
      this.openMessageDebounce.next();
    }
  }

  addLine(message: UserChatMessage) {
    if (message.message === '*_OFFERVIDEOCALL_*') {
      this.dialogService.confirmTranslate('videoCall.title', 'videoCall.message'
        , 'videoCall.accept', 'videoCall.reject', 'sm'
        , {name: message.senderName})
        .then(value => {
          if (value) {
            this.userChatService.acceptCall(this.sessionId);
          } else {
            this.userChatService.rejectCall(this.sessionId);
          }
        });
      return;
    } else if (message.message.startsWith('*_OPENCALL_*|')) {
      if (!this.callModal) {
        const callId = message.message.replace(/\*_OPENCALL_\*\|/, '');
        this.callModal = this.ngbModalService.open(VideoCallComponent, {
          backdrop: 'static',
          keyboard: false,
          size: 'lg'
        });
        const instance = this.callModal.componentInstance as VideoCallComponent;
        instance.callId = callId;
        instance.name = message.senderName;
        instance.senderName = this.session.name;
        instance.token = this.signInService.data;
        this.callModal.result
          .then(value => {
            this.callModal = null;
            this.userChatService.sendMessage(this.sessionId, '*_HANGUPCALL_*');
          }, reason => {
            this.callModal = null;
            this.userChatService.sendMessage(this.sessionId, '*_HANGUPCALL_*');
          })
          .catch(reason => {
            this.callModal = null;
            this.userChatService.sendMessage(this.sessionId, '*_HANGUPCALL_*');
          });
        return;
      }
    } else if (message.message === '*_HANGUPCALL_*') {
      if (this.callModal) {
        (this.callModal.componentInstance as VideoCallComponent).hangupCall();
      }
      return;
    }
    const msg = document.createElement('div');
    if (message.timestamp) {
      const timeString = ('0' + message.timestamp.getHours()).slice(-2) + ':'
        + ('0' + message.timestamp.getMinutes()).slice(-2) + ':'
        + ('0' + message.timestamp.getSeconds()).slice(-2);
      msg.innerHTML += `<span class="sender-time">${timeString}</span> `;
    }
    if (message.senderName && message.senderName !== '') {
      message.senderName = message.senderName.replace(/\$\$SELF\$\$/g, 'You');
      msg.innerHTML += `<span class="sender-name">${message.senderName}</span>: `;
    }

    if (message.senderName !== this.signInService.data.name) {
      msg.classList.add('chatOthers');
    }

    msg.innerHTML += `<span class="message">${message.message}</span>`;
    this.chatLog.nativeElement.appendChild(msg);
    this.chatLog.nativeElement.scrollTop = this.chatLog.nativeElement.scrollHeight;
  }

  sendButtonClick($event: MouseEvent) {
    $event.preventDefault();
    this.sendMessage(this.message);
  }

  sendMessage(message: string) {
    const msg = (message ?? '').trim();
    this.message = '';
    if (msg && msg !== '') {
      this.userChatService.sendMessage(this.sessionId, msg);
    }
  }

  getInputPlaceholder(): string {
    return this.translateService.instant('booth.chat.inputPlaceholder');
  }

  getSendButton(): string {
    return this.translateService.instant('booth.chat.sendButton');
  }

  close($event: MouseEvent) {
    $event.preventDefault();
    this.dialogService.confirmTranslate('userChat.closeTitle', 'userChat.closeMessage', 'userChat.closeOk', 'userChat.closeCancel')
      .then(value => {
        if (value) {
          this.userChatService.closeSession(this.sessionId);
        }
      });
  }

  callSendMessage() {
    const msg = this.message;
    this.message = '';
    setTimeout(() => {
      this.sendMessage(msg);
    }, 0);
  }

  hide($event: MouseEvent) {
    $event.preventDefault();
    this.session.unreadMessages = 0;
    this.userChatService.hideSession(this.sessionId);
  }

  toggle($event: MouseEvent) {
    $event.preventDefault();
    this.session.open = !this.session.open;
    if (this.session.open) {
      this.session.unreadMessages = 0;
      this.newMessageDebounce.next();
    }
  }
}
