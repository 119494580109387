import {Component, Input, OnInit} from '@angular/core';
import {TrackRedirectData} from '../../../services/track-redirect-data';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-expo-entry',
  templateUrl: './expo-entry.component.html',
  styleUrls: ['./expo-entry.component.scss']
})
export class ExpoEntryComponent implements OnInit {
  @Input() preset: TrackRedirectData;
  valForm: FormGroup;
  errorMessage: '';

  user: TrackRedirectData = new TrackRedirectData();
  privacyLink: string;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private translateService: TranslateService) {
  }

  ngOnInit() {
    this.translateService.get('gdpr.linkPrivacy')
      .subscribe(value => {
        this.privacyLink = value;
      });

    if (this.preset) {
      this.user.name = this.preset.name;
      this.user.email = this.preset.email;
      this.user.permit = this.preset.permit;
    }
    this.valForm = this.fb.group({
      'name': [this.user.name, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(100)])]
      , 'email': [this.user.email, Validators.compose([Validators.required, Validators.email, Validators.minLength(6), Validators.maxLength(100)])]
      , 'permit': [this.user.permit, Validators.compose([Validators.requiredTrue])]
    });
  }

  submitForm($ev) {
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      if (c) {
        this.valForm.controls[c].markAsTouched();
      }
    }
    if (this.valForm.valid) {
      const result: TrackRedirectData = this.valForm.value as TrackRedirectData;
      this.activeModal.close({success: true, data: result});
    }
  }

  cancelLogin($event: MouseEvent) {
    $event.preventDefault();
    this.activeModal.dismiss();
  }
}
