<div class="home-page">
  <div class="hero-blk hero-blk-intro">
    <div class="container">
      <div class="hero-blk-wrap hero-blk-wrap-desc text-frame">
        <div class="hero-font hero-font-intro">
          <h2 class="head">VirtualExpo</h2>
          <p [translate]="'global.slogan1'" class="lead"></p>
          <div [innerHTML]="headerContent" class="content"></div>
        </div>
        <div class="cta-blk" *ngIf="is3dEnabled()">
          <div style="display: inline-block;">
            <a [routerLink]="'portal'" class="btn btn-sm btn-primary btn-custom-primary">
              <span [translate]="'global.visit3d'"></span>
              <i style="margin: 0 1em 0 2em;" class="fa fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="welcome_area2 gradient-background-overlay section_padding_100_0"
         [ngStyle]="{'background-image': headerImage}"
         style="overflow: inherit;"
         idng ser="home">
  <app-exhibitions2 *ngIf="showExpos"></app-exhibitions2>
</section>
