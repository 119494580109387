/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export type SidebarMode = 0 | 1 | 2 | 11 | 12 | 13 | 21 | 22 | 23 | 100;

export const SidebarMode = {
    NUMBER_0: 0 as SidebarMode,
    NUMBER_1: 1 as SidebarMode,
    NUMBER_2: 2 as SidebarMode,
    NUMBER_11: 11 as SidebarMode,
    NUMBER_12: 12 as SidebarMode,
    NUMBER_13: 13 as SidebarMode,
    NUMBER_21: 21 as SidebarMode,
    NUMBER_22: 22 as SidebarMode,
    NUMBER_23: 23 as SidebarMode,
    NUMBER_100: 100 as SidebarMode
};

