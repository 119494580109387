import {EventEmitter, Injectable, ViewChild} from '@angular/core';
import {ExpoExhibitorListItemDto} from '../virtual-expo-api';
import {SignInService} from './sign-in.service';
import {TrackingService} from './tracking.service';
import {HubConnection, HubConnectionBuilder, LogLevel} from '@microsoft/signalr';
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from './data.service';
import {ExhibitorExtended} from '../objects/exhibitor-extended';
import {StorageService} from './storage.service';
import {strict} from 'assert';
import {Subject} from 'rxjs';
import {LoadingSpinnerService} from './loading-spinner.service';

@Injectable({
  providedIn: 'root'
})
export class UserChatService {
  public sessions: { [channelId: string]: UserChatSession } = {};
  public sessionList: Array<string> = new Array<string>();

  public unreadMessagesChanged = new Subject();

  private hubConnection: HubConnection;
  private hubConnectionToken: string;

  isConnected: boolean;
  sessionClosed: EventEmitter<string> = new EventEmitter<string>();
  sessionOpened: EventEmitter<string> = new EventEmitter<string>();
  unreadMessages: number;

  constructor(
    private signInService: SignInService
    , private trackingService: TrackingService
    , private translateService: TranslateService
    , private dataService: DataService
    , private spinnerService: LoadingSpinnerService
    , private storageService: StorageService
  ) {

    this.signInService.OnLogin.subscribe(() => {
      this.connectHub(this.signInService.getTokenString())
        .then(connectionAlreadyOpened => {
          this.reloadSessions(connectionAlreadyOpened);
        });
    });
    this.signInService.OnLogout.subscribe(() => {
      this.storageService.clear('chatList');
      this.sessionList = new Array<string>();
      this.sessions = {};
      this.hubConnection.stop()
        .then(value => {
          this.isConnected = false;
          this.hubConnection = null;
        });
    });
  }

  connectHub(token: string): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      if (this.hubConnection && this.hubConnectionToken === token) {
        resolve(true);
        return;
      }
      this.hubConnectionToken = token;

      if (this.hubConnection) {
        this.hubConnection.stop()
          .then(value => {
            this.isConnected = false;
            this.hubConnection = null;
            return this.connectHubInner(token);
          });
      } else {
        return this.connectHubInner(token);
      }
    });
  }

  connectHubInner(token: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(environment.chatChannelHub, {accessTokenFactory: () => token})
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: retryContext => {
            return Math.random() * 10000 + 10000;
          }
        })
        .configureLogging(environment.production ? LogLevel.Critical : LogLevel.Information)
        .build();

      this.hubConnection.onreconnecting(error => {
        // console.log('reconnecting user chat');
        if (error) {
          console.log(error);
        }
      });

      this.hubConnection.onclose(error => {
        // console.log('closing user chat');
        if (error) {
          console.log(error);
        }
      });

      if (!this.isConnected) {
        this.hubConnection.on('chatChannelMessages', (messages: Array<UserChatMessage>) => {
          messages.forEach(message => {
            if (this.sessions[message.partnerId]) {
              message.timestamp = new Date(message.timestamp);
              this.sessions[message.partnerId].addMessage(message);
            }
          });
        });

        this.hubConnection.on('chatChannelMessage', (message: UserChatMessage) => {
          if (this.sessions[message.partnerId]) {
            message.timestamp = new Date(message.timestamp);
            this.sessions[message.partnerId].addMessage(message);
          }
        });
      }
      this.isConnected = true;

      this.hubConnection
        .start()
        .then(value => {
          resolve(false);
        });
    });
  }

  connectExpo(expo: { accessMode?: number, randomChatNames?: boolean }): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (expo.accessMode !== 0) {
        if (this.signInService.data) {
          this.connectHub(this.signInService.data.token)
            .then(connectionAlreadyOpened => {
              if (!connectionAlreadyOpened) {
                this.reloadSessions(connectionAlreadyOpened);
              }
              resolve();
            });
        }
      } else {
        this.signInService.getAnonToken(expo)
          .then((value) => {
            this.connectHub(value.token)
              .then(connectionAlreadyOpened => {
                if (!connectionAlreadyOpened) {
                  this.reloadSessions(connectionAlreadyOpened);
                }
                resolve();
              });
          }, reason => {
            reject();
          });
      }
    });
  }

  reloadSessions(connectionAlreadyOpened: boolean) {
    if (connectionAlreadyOpened) {
      return;
    }
    let sessions: Array<UserChatList> = this.storageService.get('chatList');
    if (!sessions) {
      sessions = new Array<UserChatList>();
    }
    if (sessions) {
      sessions.forEach((session: UserChatList) => {
        const exhi = {
          id: session.id,
          name: session.name,
          boothConfiguration: {
            privateChatGreeting: session.greeting
          }
        };
        this.connectToChat(exhi, true, session.readMessages);
      });
    }
  }

  connectExhibitor(exhibitor: ExhibitorExtended): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.spinnerService.show('userChat');
      const index = this.sessionList.indexOf(exhibitor.id);
      if (index >= 0) {
        reject();
      }
      if (exhibitor.expo.accessMode !== 0) {
        this.signInService.getToken()
          .then((value) => {
            this.connectHub(value.token)
              .then(value1 => {
                this.connectToChat(exhibitor, false, 0);
                resolve();
              });
          }, reason => {
            this.spinnerService.hide('userChat');
            reject();
          });
      } else {
        this.signInService.getAnonToken(exhibitor.expo)
          .then((value) => {
            this.connectHub(value.token)
              .then(value1 => {
                this.connectToChat(exhibitor, false, 0);
                resolve();
              });
          }, reason => {
            this.spinnerService.hide('userChat');
            reject();
          });
      }
    });
  }

  private getLabel(exhibitor: { name?: string, label?: string, shortLabel?: string }): string {
    if (exhibitor.shortLabel && exhibitor.shortLabel.trim() !== '') {
      return exhibitor.shortLabel;
    }
    if (exhibitor.label && exhibitor.label.trim() !== '') {
      return exhibitor.label;
    }
    return exhibitor.name;
  }

  private connectToChat(exhibitor:
                          {
                            id?: string, name?: string, label?: string, shortLabel?: string, boothConfiguration?: { privateChatGreeting?: string }
                          }
    , reload: boolean, previousCount: number) {
    if (!this.sessionList[exhibitor.id]) {
      this.dataService.webGetChatId(exhibitor.id)
        .then(value => {
          if (value !== '') {
            const session = new UserChatSession();
            session.id = exhibitor.id;
            session.name = this.getLabel(exhibitor);
            session.channel = value;
            session.messages = new Array<UserChatMessage>();
            session.open = !reload;
            session.readMessages = previousCount;
            session.unreadMessages = -previousCount;
            if (exhibitor.boothConfiguration && exhibitor.boothConfiguration.privateChatGreeting
              && exhibitor.boothConfiguration.privateChatGreeting.trim() !== '') {
              const welcome: UserChatMessage = {
                channelId: value,
                partnerId: exhibitor.id,
                timestamp: new Date(),
                senderName: exhibitor.name,
                message: exhibitor.boothConfiguration.privateChatGreeting
              };
              session.greeting = exhibitor.boothConfiguration.privateChatGreeting;
              session.addMessage(welcome);
            }
            this.sessions[exhibitor.id] = session;
            this.sessionList.push(exhibitor.id);

            if (!reload) {
              this.persistSession(session);
            }


            this.hubConnection.invoke('JoinChannel', session.channel, session.id);
            this.sessionOpened.emit(exhibitor.id);
            if (!reload) {
              this.spinnerService.hide('userChat');
            }
          }
        });
    }
  }

  sendMessage(sessionId: string, message: string) {
    const session = this.sessions[sessionId];
    if (session) {
      const msg: UserChatMessage = {
        message: message,
        timestamp: new Date(),
        channelId: session.channel,
        partnerId: session.id,
        senderName: this.signInService.data.name
      };
      this.sessions[session.id].addMessage(msg);
      this.hubConnection.invoke('SendMessage', session.channel, session.id, message);
    }
  }

  closeSession(sessionId: string) {
    const index = this.sessionList.indexOf(sessionId);
    if (index >= 0) {
      this.sessionList.splice(index, 1);
      const session = this.sessions[sessionId];
      if (session) {
        this.hubConnection.invoke('CloseChatChannel', session.channel, session.id);
      }
      delete (this.sessions[sessionId]);
      this.releaseSession(session);
      this.sessionClosed.emit(sessionId);
      this.recountUnread();
    }
  }

  isSessionConnected(sessionId: string): boolean {
    return this.sessionList.indexOf(sessionId) >= 0;
  }

  hideSession(sessionId: string) {
    this.persistSession(this.sessions[sessionId]);
    this.recountUnread();
  }

  private persistSession(session: UserChatSession) {
    const data: UserChatList = {
      id: session.id,
      name: session.name,
      channel: session.channel,
      greeting: session.greeting,
      readMessages: session.readMessages
    };
    let sessions: Array<UserChatList> = this.storageService.get('chatList');
    if (!sessions) {
      sessions = new Array<UserChatList>();
    }
    for (let i = 0; i < sessions.length; i++) {
      if (sessions[i].id === data.id) {
        break;
      }
    }
    sessions.push(data);
    this.storageService.store('chatList', sessions);
  }

  private releaseSession(session: UserChatSession) {
    let sessions: Array<UserChatList> = this.storageService.get('chatList');
    if (!sessions) {
      sessions = new Array<UserChatList>();
    }
    for (let i = 0; i < sessions.length; i++) {
      if (sessions[i].id === session.id) {
        sessions.splice(i, 1);
        this.storageService.store('chatList', sessions);
        return;
      }
    }
  }

  recountUnread() {
    let count = 0;
    Object.keys(this.sessions).forEach(key => {
      if (this.sessions[key].unreadMessages > 0) {
        count++;
      }
    });
    if (count !== this.unreadMessages) {
      this.unreadMessages = count;
      this.unreadMessagesChanged.next();
    }
  }

  persistRead(data: UserChatSession) {
    let sessions: Array<UserChatList> = this.storageService.get('chatList');
    if (!sessions) {
      sessions = new Array<UserChatList>();
    }
    for (let i = 0; i < sessions.length; i++) {
      if (sessions[i].id === data.id) {
        sessions[i].readMessages = data.messages.length;
      }
    }
    this.storageService.store('chatList', sessions);
  }

  acceptCall(sessionId: string) {
    const session = this.sessions[sessionId];
    if (session) {
      this.hubConnection.invoke('AcceptVideoCall', session.channel, session.id);
    }
  }

  rejectCall(sessionId: string) {
    const session = this.sessions[sessionId];
    if (session) {
      this.hubConnection.invoke('DenyVideoCall', session.channel, session.id);
    }
  }
}

export class UserChatList {
  id: string;
  name: string;
  channel: string;
  greeting: string;
  readMessages: number;
}

export class UserChatSession extends UserChatList {
  messages: Array<UserChatMessage> = new Array<UserChatMessage>();
  open = false;
  unreadMessages = 0;
  newMessage: EventEmitter<UserChatMessage> = new EventEmitter<UserChatMessage>();

  addMessage(message: UserChatMessage) {
    if (message.message === '*_OFFERVIDEOCALL_*') {

    } else if (message.message.startsWith('*_OPENCALL_*|')) {

    } else {
      if (!this.open) {
        this.unreadMessages++;
      }
      this.messages.push(message);
    }
    this.newMessage.emit(message);
  }
}

export class UserChatMessage {
  timestamp: Date;
  channelId: string;
  partnerId: string;
  senderName: string;
  message: string;
}
