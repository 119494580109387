import {EventEmitter, Injectable} from '@angular/core';
import {HubConnection, HubConnectionBuilder, LogLevel} from '@microsoft/signalr';
import {environment} from '../../environments/environment';
import {
  CalendarEntryBoothDto,
  CalendarEntryStageDto,
  LivestreamSettingsDto,
  StageDto,
  StageOverviewDto
} from '../virtual-expo-api';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ExpoStatusDto} from '../objects/expo-status-dto';
import {ExpoStatus} from '../objects/expo-status';
import {take} from 'rxjs/operators';
import {WindowService} from './window.service';
import {LinkHandlerService} from './link-handler.service';
import {ConfirmationDialogService} from '../components/components/confirmation-dialog/confirmation-dialog.service';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DataService} from './data.service';
import {ExpoOverviewExtended} from '../objects/expo-overview-extended';

const dayjs = require('dayjs');

@Injectable({
  providedIn: 'root'
})
export class SystemNotificationService {
  updateExpo: EventEmitter<string> = new EventEmitter<string>();

  updateStageCalendar: EventEmitter<CalendarEntryStageDto> = new EventEmitter<CalendarEntryStageDto>();
  updateExhibitorCalendar: EventEmitter<CalendarEntryBoothDto> = new EventEmitter<CalendarEntryBoothDto>();
  statusLoaded: EventEmitter<ExpoStatus> = new EventEmitter<ExpoStatus>();
  updateState: EventEmitter<void> = new EventEmitter<void>();
  updateChatState: EventEmitter<{ id: string, state: boolean }> = new EventEmitter<{ id: string, state: boolean }>();
  updateVideoState: EventEmitter<{ id: string, state: boolean }> = new EventEmitter<{ id: string, state: boolean }>();

  startLive: EventEmitter<{ streamType: number, streamId: string }> = new EventEmitter<{ streamType: number; streamId: string }>();
  clear: EventEmitter<string> = new EventEmitter<string>();
  clearExhibitor: EventEmitter<string> = new EventEmitter<string>();

  cleanupSubscriptions: EventEmitter<any> = new EventEmitter<any>();

  private hubConnection: HubConnection;

  connecting = false;
  currentExpo: string;

  waiter: Promise<void>;

  expoStatus: { [expoId: string]: ExpoStatus } = {};
  private eventsConnected: boolean;
  activeExpo: ExpoOverviewExtended;

  constructor(private toasterService: ToastrService
    , private translateService: TranslateService
    , private _windowService: WindowService
    , private linkHandlerService: LinkHandlerService
    , private confirmationDialogService: ConfirmationDialogService
    , private router: Router
    , private deviceDetector: DeviceDetectorService
  ) {
  }

  initHub(): Promise<void> {
    if (!this.waiter) {
      this.waiter = new Promise<void>((resolve, reject) => {
        if (this.deviceDetector.userAgent.indexOf('HeadlessChrome') > 0) {
          return this.waiter;
        }
        if (!this.hubConnection) {
          this.hubConnection = new HubConnectionBuilder()
            .withUrl(environment.systemNotificationHub)
            .withAutomaticReconnect({
              nextRetryDelayInMilliseconds: retryContext => {
                return Math.random() * 10000 + 10000;
              }
            })
            .configureLogging(environment.production ? LogLevel.Critical : LogLevel.Information)
            .build();

          this.hubConnection.onreconnecting(error => {
            // console.log('reconnecting system notifications');
            if (error) {
              console.log(error);
            }
          });

          this.hubConnection.onclose(error => {
            // console.log('closing system notifications');
            if (error) {
              console.log(error);
            }
          });

          this.waiter = this.hubConnection
            .start()
            .then(() => {
              // console.log('Connection started!');
              // this.hubConnection.invoke('GetSessions');
              this.hookup().then(value => {
                resolve();
              });
            })
            .catch(err => {
              // console.log('Error while establishing connection :(');
              reject();
            });
        } else {
          resolve();
        }
      });
    }
    return this.waiter;
  }

  hookup() {
    return new Promise<void>((resolve, reject) => {
      if (!this.eventsConnected) {
        this.eventsConnected = true;
        this.hubConnection.on('stageUpdate', (args) => {
          const cal = args as CalendarEntryStageDto;
          this.updateStageCalendar.emit(cal);
        });

        this.hubConnection.on('stageNotice', args => {
          const cal = args as CalendarEntryStageDto;
          let title = this.translateService.instant('stageNotification.titleSoon');
          let message = this.translateService.instant('stageNotification.messageSoon');
          const dt = dayjs(cal.start).toDate();
          const dtNow = dayjs().toDate();
          if (dt > dtNow) {
            title = this.translateService.instant('stageNotification.titleCurrent');
            message = this.translateService.instant('stageNotification.messageCurrent');
          }
          message = message.replace(/\{label\}/g, cal.label);

          const timeformat = this.translateService.instant('calendar.dateFormat');
          const ts = dayjs(cal.start).format(timeformat);
          message = message.replace(/\{time\}/g, ts);

          this.toasterService.show(message, title)
            .onTap
            .pipe(take(1))
            .subscribe(() => {
              this.openStage(cal);
            });
        });

        this.hubConnection.on('stageNoticeCustom', (cal: CalendarEntryStageDto, message: string) => {
          this.toasterService.show(message, '')
            .onTap
            .pipe(take(1))
            .subscribe(() => {
              this.openStage(cal);
            });
        });

        this.hubConnection.on('stageClear', (stageId: string) => {
          this.clear.emit(stageId);
        });

        this.hubConnection.on('expoUpdate', (expoId: string) => {
          this.updateExpo.emit(expoId);
        });

        this.hubConnection.on('exhibitorUpdate', (args) => {
          const cal = args as CalendarEntryBoothDto;
          this.updateExhibitorCalendar.emit(cal);
        });

        this.hubConnection.on('exhibitorNotice', args => {
          const cal = args as CalendarEntryBoothDto;
          let title = this.translateService.instant('exhibitorNotification.titleSoon');
          let message = this.translateService.instant('exhibitorNotification.messageSoon');
          const dt = dayjs(cal.start).toDate();
          const dtNow = dayjs().toDate();
          if (dt > dtNow) {
            title = this.translateService.instant('exhibitorNotification.titleCurrent');
            message = this.translateService.instant('exhibitorNotification.messageCurrent');
          }
          message = message.replace(/\{label\}/g, cal.label);
          message = message.replace(/\{name\}/g, cal.exhibitorName);

          const timeformat = this.translateService.instant('calendar.dateFormat');
          const ts = dayjs(cal.start).format(timeformat);
          message = message.replace(/\{time\}/g, ts);

          this.toasterService.show(message, title)
            .onTap
            .pipe(take(1))
            .subscribe(() => {
              this.openExhibitor(cal);
            });
        });

        this.hubConnection.on('exhibitorNoticeCustom', (cal: CalendarEntryBoothDto, message: string) => {
          this.toasterService.show(message, '')
            .onTap
            .pipe(take(1))
            .subscribe(() => {
              this.openExhibitor(cal);
            });
        });

        this.hubConnection.on('exhibitorClear', (exhibitorId: string) => {
          this.clearExhibitor.emit(exhibitorId);
        });


        this.hubConnection.on('closeChat', (args: { expoId: string, exhibitorId: string }) => {
          if (!this.expoStatus || !this.expoStatus[args.expoId] || !this.expoStatus[args.expoId].exhibitors[args.exhibitorId]) {
          } else {
            this.expoStatus[args.expoId].exhibitors[args.exhibitorId].chatOnline = false;
          }
          this.updateState.emit();

          this.updateChatState.emit({id: args.exhibitorId, state: false});
        });

        this.hubConnection.on('openChat', (args: { expoId: string, exhibitorId: string }) => {
          if (!this.expoStatus || !this.expoStatus[args.expoId] || !this.expoStatus[args.expoId].exhibitors[args.exhibitorId]) {
          } else {
            this.expoStatus[args.expoId].exhibitors[args.exhibitorId].chatOnline = true;
          }
          this.updateState.emit();

          this.updateChatState.emit({id: args.exhibitorId, state: true});
        });

        this.hubConnection.on('closeVideo', (args: { expoId: string, exhibitorId: string }) => {
          if (!this.expoStatus || !this.expoStatus[args.expoId] || !this.expoStatus[args.expoId].exhibitors[args.exhibitorId]) {
          } else {
            this.expoStatus[args.expoId].exhibitors[args.exhibitorId].videoOnline = false;
          }
          this.updateState.emit();

          this.updateVideoState.emit({id: args.exhibitorId, state: false});
        });

        this.hubConnection.on('openVideo', (args: { expoId: string, exhibitorId: string }) => {
          if (!this.expoStatus || !this.expoStatus[args.expoId] || !this.expoStatus[args.expoId].exhibitors[args.exhibitorId]) {
          } else {
            this.expoStatus[args.expoId].exhibitors[args.exhibitorId].videoOnline = true;
          }
          this.updateState.emit();

          this.updateVideoState.emit({id: args.exhibitorId, state: true});
        });

        this.hubConnection.on('expoStatus', args => {
          const dto = args as ExpoStatusDto;
          const status: ExpoStatus = new ExpoStatus();
          dto.exhibitors.forEach(value => status.exhibitors[value.id] = value);
          dto.stages.forEach(value => status.stages[value.id] = value);
          this.expoStatus[dto.expo] = status;
          this.updateState.emit();
          this.statusLoaded.emit(status);
        });

        this.hubConnection.on('startLiveStream', (args: LivestreamSettingsDto) => {
          if (!this.expoStatus || !this.expoStatus[args.expoId] || !this.expoStatus[args.expoId].exhibitors[args.exhibitorId]) {
          } else {
            this.expoStatus[args.expoId].exhibitors[args.exhibitorId].liveStreamActive = true;
            this.expoStatus[args.expoId].exhibitors[args.exhibitorId].liveStreamId = args.streamId;
            this.expoStatus[args.expoId].exhibitors[args.exhibitorId].liveStreamType = args.streamType;

            this.startLive.emit({streamType: args.streamType, streamId: args.streamId});
          }
          this.updateState.emit();
        });

        this.hubConnection.on('stopLiveStream', (args: LivestreamSettingsDto) => {
          if (!this.expoStatus || !this.expoStatus[args.expoId] || !this.expoStatus[args.expoId].exhibitors[args.exhibitorId]) {
          } else {
            this.expoStatus[args.expoId].exhibitors[args.exhibitorId].liveStreamActive = false;
          }
          this.updateState.emit();
        });
      }
      resolve();
    });
  }

  openStage(cal: CalendarEntryStageDto) {
    const stage = this.expoStatus[this.currentExpo].stages[cal.stageId];
    if (stage) {
      const url = this.linkHandlerService.getStageUrl(stage.expo, stage);
      // const url = `/exhibition/${this.linkHandlerService.prepareItem(stage.expo)}/stage/${this.linkHandlerService.prepareItem(stage)}`;
      // const url = `/stage/${this.linkHandlerService.prepare(stage.expo.name, stage.expo.shortKey)}/${this.linkHandlerService.prepare(stage.name, stage.shortKey)}`;
      if (this._windowService.pathName() !== url) {
        this.confirmationDialogService.confirm('Zum Vortrag', 'Möchten Sie jetzt zum Vortrag springen', 'Springen', 'Später', 'sm')
          .then(value => {
            if (value) {
              this.router.navigateByUrl(url);
            }
          });
      }
    }
  }

  openExhibitor(cal: CalendarEntryBoothDto) {
    const expo = this.activeExpo;
    const exhibitor = this.expoStatus[this.currentExpo].exhibitors[cal.exhibitorId];
    if (exhibitor) {
      const url = this.linkHandlerService.getExhibitorUrl(expo, exhibitor);
      // const url = `/exhibition/${this.linkHandlerService.prepareItem(expo)}/${this.linkHandlerService.prepareItem(exhibitor)}`;
      // const url = `/stage/${this.linkHandlerService.prepare(stage.expo.name, stage.expo.shortKey)}/${this.linkHandlerService.prepare(stage.name, stage.shortKey)}`;
      if (this._windowService.pathName() !== url) {
        this.confirmationDialogService.confirm('Zum Vortrag', 'Möchten Sie jetzt zum Vortrag springen', 'Springen', 'Später', 'sm')
          .then(value => {
            if (value) {
              this.router.navigateByUrl(url);
            }
          });
      }
    }
  }

  joinExpo(expoId) {
    if (this.hubConnection) {
      if (this.currentExpo === expoId) {
        return;
      }
      if (this.currentExpo && this.currentExpo !== '') {
        this.hubConnection.invoke('LeaveExpo', this.currentExpo)
          .then(value => {
            this.currentExpo = expoId;
            this.hubConnection.invoke('EnterExpo', expoId);
          });
      } else {
        this.currentExpo = expoId;
        this.hubConnection.invoke('EnterExpo', expoId);
      }
    }
  }

  leaveExpo(expoId) {
    if (this.hubConnection) {
      this.hubConnection.invoke('LeaveExpo', expoId);
    }
  }

  getChatState(expoId: string, exhibitorId: string): boolean {
    const state = this.expoStatus && this.expoStatus[expoId] && this.expoStatus[expoId].exhibitors[exhibitorId]
      && this.expoStatus[expoId].exhibitors[exhibitorId].chatOnline;
    return state;
  }

  getVideoState(expoId: string, exhibitorId: string): boolean {
    const state = this.expoStatus && this.expoStatus[expoId] && this.expoStatus[expoId].exhibitors[exhibitorId]
      && this.expoStatus[expoId].exhibitors[exhibitorId].videoOnline;
    return state;
  }

  getLiveState(expoId: string, exhibitorId: string): boolean {
    const state = this.expoStatus && this.expoStatus[expoId] && this.expoStatus[expoId].exhibitors[exhibitorId]
      && this.expoStatus[expoId].exhibitors[exhibitorId].liveStreamActive;
    return state;
  }

  getLiveSettings(expoId: string, exhibitorId: string): { streamType: number, streamId: string } {
    if (this.getLiveState(expoId, exhibitorId)) {
      const exhibitorStatus = this.expoStatus[expoId].exhibitors[exhibitorId];
      return {streamType: exhibitorStatus.liveStreamType, streamId: exhibitorStatus.liveStreamId};
    }
    return null;
  }

  getStages(expoId: string): Array<StageOverviewDto> {
    if (!this.expoStatus || !this.expoStatus[expoId]) {
      return new Array<StageOverviewDto>();
    }
    const tmp = new Array<StageOverviewDto>();
    Object.keys(this.expoStatus[expoId].stages).forEach(value => {
      tmp.push(this.expoStatus[expoId].stages[value]);
    });
    return tmp.sort((a, b) => a.sequence - b.sequence);
  }

  public cleanup() {
    this.cleanupSubscriptions.emit();
  }
}
