/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { InventoryItemType } from './inventoryItemType';
import { ExhibitorReferenceDto } from './exhibitorReferenceDto';
import { StageConfigurationDto } from './stageConfigurationDto';
import { StageMode } from './stageMode';
import { ExpoExhibitorOverviewDto } from './expoExhibitorOverviewDto';
import { ExpoReferenceDto } from './expoReferenceDto';


export interface StageDto { 
    id?: string;
    shortKey?: string;
    expoId: string;
    expo?: ExpoReferenceDto;
    name: string;
    description?: string;
    logoSquare?: string;
    logoWide?: string;
    sequence?: number;
    current?: number;
    background?: string;
    stageMode?: StageMode;
    active?: boolean;
    exhibitorId?: string;
    exhibitors?: Array<ExhibitorReferenceDto>;
    exhibitor?: ExpoExhibitorOverviewDto;
    vodType?: InventoryItemType;
    vodSource?: string;
    hideOnLanding?: boolean;
    rowVersion?: number;
    stageConfiguration?: StageConfigurationDto;
}

/**
 * Namespace for property- and property-value-enumerations of StageDto.
 */
export namespace StageDto {
    /**
     * All properties of StageDto.
     */
    export enum Properties {
        id = 'id',
        shortKey = 'shortKey',
        expoId = 'expoId',
        expo = 'expo',
        name = 'name',
        description = 'description',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        sequence = 'sequence',
        current = 'current',
        background = 'background',
        stageMode = 'stageMode',
        active = 'active',
        exhibitorId = 'exhibitorId',
        exhibitors = 'exhibitors',
        exhibitor = 'exhibitor',
        vodType = 'vodType',
        vodSource = 'vodSource',
        hideOnLanding = 'hideOnLanding',
        rowVersion = 'rowVersion',
        stageConfiguration = 'stageConfiguration'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of StageDto.
    */
    export const ModelValidators: {[K in keyof StageDto]: [string, ValidatorFn][]} = {
        id: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        shortKey: [
        ],
        expoId: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        expo: [
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        description: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        sequence: [
        ],
        current: [
        ],
        background: [
        ],
        stageMode: [
        ],
        active: [
        ],
        exhibitorId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        exhibitors: [
        ],
        exhibitor: [
        ],
        vodType: [
        ],
        vodSource: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        hideOnLanding: [
        ],
        rowVersion: [
        ],
        stageConfiguration: [
        ],
    };

    /**
    * The FormControlFactory for StageDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<StageDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for StageDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: StageDto = {
            id: null,
            shortKey: null,
            expoId: null,
            expo: null,
            name: null,
            description: null,
            logoSquare: null,
            logoWide: null,
            sequence: null,
            current: null,
            background: null,
            stageMode: null,
            active: null,
            exhibitorId: null,
            exhibitors: null,
            exhibitor: null,
            vodType: null,
            vodSource: null,
            hideOnLanding: null,
            rowVersion: null,
            stageConfiguration: null,
          }
        ) {
            super(model, StageDto.ModelValidators);
        }
    }

}


