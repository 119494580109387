import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MarkdownModule, MarkedOptions, MarkedRenderer} from 'ngx-markdown';
import {DeviceDetectorService} from 'ngx-device-detector';
import {LinkFacebookComponent} from '../../components/components/link-facebook/link-facebook.component';
import {LinkTwitterComponent} from '../../components/components/link-twitter/link-twitter.component';
import {LinkXingComponent} from '../../components/components/link-xing/link-xing.component';
import {LinkLinkedinComponent} from '../../components/components/link-linkedin/link-linkedin.component';
import {LinkPhoneComponent} from '../../components/components/link-phone/link-phone.component';
import {LinkEmailComponent} from '../../components/components/link-email/link-email.component';
import {TranslateModule} from '@ngx-translate/core';
import {ToastrModule} from 'ngx-toastr';
import {LinkSocialComponent} from '../../components/components/link-social/link-social.component';
import {
  ConfirmationDialogComponent
} from '../../components/components/confirmation-dialog/confirmation-dialog.component';
import {ConfirmationDialogService} from '../../components/components/confirmation-dialog/confirmation-dialog.service';
import {BoothIconComponent} from '../../components/components/booth-icon/booth-icon.component';
import {StageIconComponent} from '../../components/components/stage-icon/stage-icon.component';
import {RouterModule} from '@angular/router';
import {GdprPermissionsComponent} from '../../components/components/gdpr-permissions/gdpr-permissions.component';
import {UiSwitchModule} from 'ngx-ui-switch';
import {GdprVideoBlockerComponent} from '../../components/components/gdpr-video-blocker/gdpr-video-blocker.component';
import {
  ControlValidationResultsComponent
} from '../../components/components/control-validation-results/control-validation-results.component';
import {PopupMessageComponent} from '../../components/components/popup-message/popup-message.component';
import {UserChatComponent} from '../../components/components/user-chat/user-chat.component';
import {UserChatSessionComponent} from '../../components/components/user-chat-session/user-chat-session.component';
import {GroupIconComponent} from '../../components/components/group-icon/group-icon.component';
import {ExpoIconComponent} from '../../components/components/expo-icon/expo-icon.component';
import {ExpoCalendarComponent} from '../../components/expo-calendar/expo-calendar.component';
import {ModalIFrameComponent} from '../../components/modal-iframe/modal-iframe.component';
import {ExpoEntryComponent} from '../../components/components/expo-entry/expo-entry.component';
import {UpcomingCalendarComponent} from '../../components/components/upcoming-calendar/upcoming-calendar.component';
import {VideoCallComponent} from '../../components/components/video-call/video-call.component';
import {LegalLinksComponent} from '../../components/components/legal-links/legal-links.component';

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.link = (href, title1, text) => {
    const url = `<a href='${href}' title='${title1}' target='_blank'>${text}</a>`;
    return url;
  };

  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
}

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    ExpoIconComponent,
    GdprPermissionsComponent,
    GdprVideoBlockerComponent,
    LegalLinksComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),
    ToastrModule.forRoot(),
    TranslateModule.forChild(),
    RouterModule,
    UiSwitchModule
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    MarkdownModule,
    TranslateModule,
    GdprPermissionsComponent,
    GdprVideoBlockerComponent,
    ExpoIconComponent,
    LegalLinksComponent
  ],
  providers: [
    ConfirmationDialogService
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
